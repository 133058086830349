// frontend/src/pages/Supplies.js
import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPrint, faUser } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl } from 'react-bootstrap';
import AddPayment from '../components/AddPayment';
import Loading from '../components/Loading';
import ModalConfirm from '../components/ModalConfirm';
import formatDate from '../components/FormatDate';
import PrintPaymentModal from '../components/PrintPaymentModal';
import { RoleContext } from '../components/RoleContext';
import { toast } from 'react-toastify';

const PartnerPage = () => {
    const { currentRole } = useContext(RoleContext);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]); // Danh sách phiếu chi
    const [payment, setPayment] = useState([]);
    const [partners, setPartners] = useState([]);
    const [editData, setEditData] = useState(null); // Dữ liệu để sửa
    const [formConfig, setFormConfig] = useState([]); // Cấu hình form tùy chỉnh
    const [showModal, setShowModal] = useState(false); // Modal thêm phiếu chi
    const [showPrintPaymentModal, setShowPrintPaymentModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Trạng thái hiển thị Modal xác nhận xóa
    const [selectedPartnersId, setSelectedPartnersId] = useState(null); // Vật tư cần xóa
    const [totalPaymentsForYear, setTotalPaymentsForYear] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    const userId = jwtDecode(token)?.userId;
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });
    // Hàm lấy permissions
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.get(`${apiUrl}/employees/permissions`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { currentRole, userId },
                });
                setPermissions(response.data);
            } catch (error) {
                console.error("Lỗi khi lấy quyền:", error);
            }
        };

        fetchPermissions();
    }, [currentRole, apiUrl, token, userId]);

    // Lấy cấu hình form
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const response = await axios.get(`${apiUrl}/payment-form-config/${currentRole}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setFormConfig(response.data);
            } catch (error) {
                console.error("Lỗi khi tải cấu hình form:", error);
                toast.error("Không thể tải cấu hình form!");
            }
        };
        fetchFormConfig();
    }, [apiUrl, token, userId, currentRole]);

    // Lấy danh sách phiếu chi
    const fetchPayments = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/payments`, {
                params: { currentRole, createdBy: userId, search, },
                headers: { Authorization: `Bearer ${token}` },
            });
            setPayments(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi tải danh sách phiếu chi:", error);
            toast.error("Không thể tải danh sách phiếu chi!");
        }
    }, [apiUrl, token, userId, currentRole, search]);
    useEffect(() => {
        if (currentRole) {
            fetchPayments();
        }
    }, [fetchPayments, currentRole]);

    const fetchCustomers = useCallback(async () => {
        try {
            setLoading(true);

            const response = await axios.get(`${apiUrl}/partners`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    currentRole,    // Chủ sở hữu dữ liệu
                    createdBy: userId, // Người thực hiện hành động
                },
            });

            setPartners(response.data);
        } catch (error) {
            console.error("Lỗi khi tải danh sách bệnh nhân:", error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, token, currentRole, userId]);
    useEffect(() => {
        if (currentRole) {
            fetchCustomers();
        }
    }, [fetchCustomers, currentRole]);

    const partnerMap = new Map(
        partners.map((partner) => [
            partner._id,
            partner.customFields.tenDoiTac,
        ])
    );

    // Hiển thị Modal thêm mới hoặc sửa
    const handleShowModal = (data = null) => {
        setEditData(data); // Nếu `data` có giá trị, chuyển sang chế độ sửa
        setShowModal(true);
    };
    // Đóng Modal
    const handleCloseModal = () => {
        setEditData(null); // Reset dữ liệu sửa
        setShowModal(false);
        fetchPayments(); // Làm mới danh sách sau khi thêm/sửa
    };

    const fetchTotalPaymentsYears = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/payments`,
                {
                    params: { createdBy: userId, currentRole },
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const currentYear = new Date().getFullYear();
            const totalPayments = response.data.filter(payment => {
                const paymentYear = new Date(payment.createdAt).getFullYear(); // Sử dụng createdAt thay vì date
                return paymentYear === currentYear;
            });
            setTotalPaymentsForYear(totalPayments.length);
        } catch (error) {
            console.error('Error fetching total receipts:', error);
            setTotalPaymentsForYear(0);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchTotalPaymentsYears();
    }, [fetchTotalPaymentsYears]);

    const handlePrintPayment = (payment) => {
        const printDate = new Date(payment.createdAt); // Sử dụng createdAt thay vì date

        const day = printDate.getDate();
        const month = printDate.getMonth() + 1;
        const year = printDate.getFullYear();

        const updatedPayment = {
            ...payment,
            printDate,
            printDay: day,
            printMonth: month,
            printYear: year,
            totalPaymentsForYear: totalPaymentsForYear, // Nếu totalPaymentsForYear được định nghĩa trước
        };
        setSelectedPayment(updatedPayment);
        setShowPrintPaymentModal(true);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${apiUrl}/payments/${selectedPartnersId}`, {
                data: {
                    createdBy: userId,
                    currentRole: currentRole,
                },
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success("Xóa phiếu chi thành công!");
            setShowConfirmModal(false);
            // Gọi lại API để cập nhật danh sách
            fetchPayments();
        } catch (error) {
            console.error("Lỗi khi xóa phiếu chi:", error);
            toast.error("Không thể xóa phiếu chi!");
        }
    };

    //Mở modal xóa
    const confirmDelete = (id) => {
        setSelectedPartnersId(id);
        setShowConfirmModal(true);
    };
    const handleSearch = (e) => {
        setSearch(e.target.value); // Cập nhật giá trị tìm kiếm
    };

    return (
        <div className='m-2'>
            <h2><FontAwesomeIcon icon={faUser} /> Danh Sách Phiếu Chi</h2>
            <div className='d-flex mb-3'>
                <Button variant='primary' className='m-1' title='Thêm Phiếu chi mới' onClick={() => handleShowModal()}>
                    <FontAwesomeIcon icon={faPlus} /> Thêm Phiếu Chi
                </Button>
                <FormControl
                    type="text"
                    placeholder="Tìm kiếm dịch vụ..."
                    value={search}
                    onChange={handleSearch} // Gọi hàm handleSearch khi nhập liệu
                    className="ms-3"
                    style={{ flexGrow: 1 }}
                />
            </div>
            {loading ? (<Loading />) : (
                <Table striped bordered hover responsive>
                    <thead className='text-center'>
                        <tr>
                            <th>{payments.length}</th>
                            <th>Mã Phiếu Chi</th>
                            {formConfig.map((field) => (
                                <th key={field.fieldName}>{field.fieldLabel}</th>
                            ))}
                            <th>Hành Động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.length > 0 && payments.map((payment, index) => (
                            <tr key={payment._id}>
                                <td>{index + 1}</td>
                                <td>{payment.paymentCode.slice(0, 10)}</td>
                                {formConfig.map((field) => (
                                    <td key={field.fieldName}
                                        style={
                                            field.fieldName === "tenDoiTac"
                                                ? { fontWeight: "bold" } // In đậm trường họ tên
                                                : {}
                                        }>
                                        {field.fieldName === "giaBan" || field.fieldName === "giaNhap"
                                            ? new Intl.NumberFormat("vi-VN", {
                                                style: "currency",
                                                currency: "VND",
                                            }).format(payment.customFields?.[field.fieldName] || 0)
                                            : field.fieldName === "createdAt"
                                                ? formatDate(payment[field.fieldName] || new Date()) // Hiển thị ngày hiện tại nếu không có giá trị
                                                : payment.customFields?.[field.fieldName] || ""}

                                    </td>
                                ))}
                                <td className='text-center'>
                                    <Button variant="info" size="sm" onClick={() => handlePrintPayment(payment)} className="m-1">
                                        <FontAwesomeIcon icon={faPrint} /> In
                                    </Button>
                                    <Button variant="warning" size="sm" className="me-2" onClick={() => handleShowModal(payment)}>
                                        Sửa
                                    </Button>
                                    <Button variant="danger" size="sm" onClick={() => confirmDelete(payment._id)}>
                                        Xóa
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>)}
            <AddPayment
                show={showModal}
                currentRole={currentRole}
                permissions={permissions}
                handleClose={handleCloseModal}
                fetchPayments={fetchPayments}
                editData={editData} // Gửi dữ liệu để sửa (nếu có)
            />
            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDelete}
            />
            <PrintPaymentModal
                show={showPrintPaymentModal}
                handleClose={() => setShowPrintPaymentModal(false)}
                payment={selectedPayment}
            />
        </div>
    );
};

export default PartnerPage;
