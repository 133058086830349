import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import { Button, FormControl, Table } from 'react-bootstrap';
import PageWrapper from '../components/PageWrapper';
import ConsultationModal from '../components/ConsultationModal';
import ModalConfirm from '../components/ModalConfirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCommentMedical, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RoleContext } from '../components/RoleContext';
import formatDate from '../components/FormatDate';

const Consultations = () => {
    const { currentRole } = useContext(RoleContext);
    const [consultations, setConsultations] = useState([]);
    const [consultationsWithReceipts, setConsultationsWithReceipts] = useState(new Set());
    const [customers, setCustomers] = useState(new Map());
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [formConfig, setFormConfig] = useState([]);
    const [showConsultationModal, setShowConsultationModal] = useState(false);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedConsultationId, setSelectedConsultationId] = useState(null);
    const [temporaryVisibleColumns, setTemporaryVisibleColumns] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    let userId;

    if (token) {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error("Token không hợp lệ:", error);
        }
    }
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });

    // Hàm lấy permissions
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.get(`${apiUrl}/employees/permissions`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { currentRole, userId },
                });
                setPermissions(response.data);
            } catch (error) {
                console.error("Lỗi khi lấy quyền:", error);
            }
        };

        fetchPermissions();
    }, [currentRole, apiUrl, token, userId]);

    // Tải cấu hình form
    const fetchFormConfig = async () => {
        try {
            const response = await axios.get(`${apiUrl}/consultation-form-config/${currentRole}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const config = response.data || [];
            setFormConfig(config);

            // Tải options cho trường apiSelect
            const updatedConfig = [...config];
            for (let i = 0; i < config.length; i++) {
                const field = config[i];
                if (field.fieldType === "apiSelect" && field.apiEndpoint) {
                    const resolvedEndpoint = field.apiEndpoint
                        .replace("${apiUrl}", apiUrl)
                        .replace("${userId}", userId)
                        .replace("${currentRole}", currentRole);

                    const apiResponse = await axios.get(resolvedEndpoint, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    // Mapping các options từ API
                    const options = apiResponse.data || [];
                    updatedConfig[i].options = options.map((item) => ({
                        value: item._id,
                        label: item.name,
                    }));
                }
            }
            setFormConfig(updatedConfig);
        } catch (error) {
            toast.error("Lỗi khi tải cấu hình form!");
            console.error("Error fetching formConfig:", error);
        }
    };
    useEffect(() => {

        fetchFormConfig();
    }, [apiUrl, userId, token, currentRole]);

    // Lấy danh sách khách hàng
    const fetchCustomers = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/customers`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    currentRole,    // Chủ sở hữu dữ liệu
                    createdBy: userId, // Người thực hiện hành động
                },
            });
            const customerMap = new Map();
            response.data.forEach(customer => {
                customerMap.set(customer._id, customer.customFields?.hoVaTen || 'Không rõ');
            });
            setCustomers(customerMap);
        } catch (error) {
            console.error('Lỗi khi tải danh sách khách hàng:', error);
        }
    }, [apiUrl, token]);
    useEffect(() => {

        fetchCustomers();
    }, [apiUrl, userId, token, currentRole]);

    // Lấy danh sách phiếu thu
    const fetchReceipts = useCallback(async () => {
        try {
            const receiptsResponse = await axios.get(`${apiUrl}/receipts`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { currentRole, createdBy: userId },
            });

            if (receiptsResponse.data.success) {
                const consultationIds = receiptsResponse.data.receipts
                    .map((receipt) => receipt.consultationId)
                    .filter((id) => id); // Lọc các giá trị không null/undefined

                setConsultationsWithReceipts(new Set(consultationIds));
            }
        } catch (error) {
            console.error("Lỗi khi lấy danh sách phiếu thu:", error);
        }
    }, [apiUrl, userId, token]);

    useEffect(() => {
        fetchReceipts();
    }, [fetchReceipts]);


    // Lấy danh sách tư vấn
    const fetchConsultations = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/consultations`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    currentRole,    // Chủ sở hữu dữ liệu
                    createdBy: userId, // Người thực hiện hành động
                },
            });
            setConsultations(response.data || []);
        } catch (error) {
            toast.error('Lỗi khi tải danh sách tư vấn!');
            console.error('Fetch consultations error:', error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId, token, currentRole]);
    useEffect(() => {
        fetchConsultations();
    }, [fetchConsultations]);

    // Xử lý thay đổi kích thước màn hình
    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener("resize", updateIsMobile);
        return () => window.removeEventListener("resize", updateIsMobile);
    }, []);

    // Toggle hiển thị cột
    const toggleTemporaryVisibility = (fieldName) => {
        setTemporaryVisibleColumns((prev) => ({
            ...prev,
            [fieldName]: !prev[fieldName],
        }));
    };

    const handleEditConsultation = (consultations) => {
        setSelectedConsultation(consultations);
        setShowConsultationModal(true);
    };

    const confirmDelete = (consultationId) => {
        setSelectedConsultationId(consultationId);
        setShowConfirmModal(true);
    };


    const handleDeleteConsultation = async () => {
        try {
            const response = await axios.delete(`${apiUrl}/consultations/${selectedConsultationId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    data: {
                        createdBy: userId,      // ID người thực hiện hành động
                        currentRole, // Vai trò hiện tại của người dùng
                    },
                });
            toast.success(response.data.message || "Xóa tư vấn thành công.");
            fetchConsultations(); // Tải lại danh sách tư vấn
        } catch (error) {
            console.error("Lỗi khi xóa tư vấn:", error);
            toast.error(error.response?.data?.message || "Không thể xóa tư vấn.");
        } finally {
            setShowConfirmModal(false); // Đóng modal xác nhận
        }
    };

    const filteredConsultations = consultations.filter((consultation) => {
        const customerName = customers.get(consultation.customFields?.hoVaTen) || 'Không rõ';
        return customerName.toLowerCase().includes(search.toLowerCase());
    });

    return (
        <PageWrapper
            pageTitle="Danh sách Tư Vấn"
            pageIcon={<FontAwesomeIcon icon={faComments} />}
        >
            <div className="d-flex mb-3">
                <Button variant="primary" onClick={() => setShowConsultationModal(true)} className="me-2">
                    <FontAwesomeIcon icon={faCommentMedical} /> Thêm Tư Vấn
                </Button>
                <FormControl
                    type="text"
                    placeholder="Tìm kiếm tư vấn..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            {loading ? (
                <div>Đang tải...</div>
            ) : filteredConsultations.length > 0 ? (
                <Table striped bordered hover>
                    <thead className="text-center">
                        <tr>
                            <th>STT</th>
                            <th>Khách Hàng</th>
                            {formConfig
                                .filter((field) => field.fieldName !== "hoVaTen") // Loại bỏ "Khách Hàng" khỏi các cột
                                .map((field) => (
                                    <th key={field.fieldName}>
                                        {field.fieldLabel}
                                        {field.isTogglable && (
                                            <FontAwesomeIcon
                                                icon={temporaryVisibleColumns[field.fieldName] ? faEye : faEyeSlash}
                                                onClick={() => toggleTemporaryVisibility(field.fieldName)}
                                                style={{ cursor: "pointer", marginLeft: "5px" }}
                                            />
                                        )}
                                    </th>
                                ))}
                            <th>Hành Động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            // Nhóm tư vấn theo khách hàng
                            const groupedConsultations = filteredConsultations.reduce((acc, consultation) => {
                                const customerId = consultation.customFields?.hoVaTen;
                                if (!acc[customerId]) acc[customerId] = [];
                                acc[customerId].push(consultation);
                                return acc;
                            }, {});

                            let globalIndex = 0; // STT toàn bảng

                            return Object.entries(groupedConsultations).flatMap(([customerId, consultations]) => {
                                const customerName = customers.get(customerId) || "Không rõ";
                                const rowSpan = consultations.length;

                                return consultations.map((consultation, index) => {
                                    const isFirstRow = index === 0;

                                    return (
                                        <tr key={consultation._id}>
                                            {/* STT (chỉ xuất hiện ở dòng đầu tiên của nhóm) */}
                                            {isFirstRow && (
                                                <td
                                                    rowSpan={rowSpan}
                                                    className="text-center"
                                                    style={{ verticalAlign: "middle" }}
                                                >
                                                    {++globalIndex}
                                                </td>
                                            )}

                                            {/* Khách Hàng (chỉ xuất hiện ở dòng đầu tiên của nhóm) */}
                                            {isFirstRow && (
                                                <td
                                                    rowSpan={rowSpan}
                                                    style={{
                                                        verticalAlign: "middle",
                                                        fontWeight: "bold",
                                                        color:
                                                            consultations.some(
                                                                (c) => c.customFields?.lieuTrinh > 0
                                                            )
                                                                ? "blue"
                                                                : "black",
                                                    }}
                                                >
                                                    {customerName}
                                                </td>
                                            )}

                                            {/* Các cột tùy chỉnh */}
                                            {formConfig
                                                .filter((field) => field.fieldName !== "hoVaTen") // Loại bỏ "Khách Hàng" khỏi các cột
                                                .map((field) =>
                                                    temporaryVisibleColumns[field.fieldName] !== false ? (
                                                        <td key={`${consultation._id}-${field.fieldName}`}>
                                                            {(() => {
                                                                if (field.fieldName === "createdAt") {
                                                                    // Format ngày từ MongoDB
                                                                    return consultation[field.fieldName]
                                                                        ? formatDate(consultation[field.fieldName])
                                                                        : "Chưa xác định";
                                                                }
                                                                if (field.fieldName === "totalPrice") {
                                                                    return (
                                                                        <span style={{ color: "green", fontWeight: "bold" }}>
                                                                            {new Intl.NumberFormat("vi-VN", {
                                                                                style: "currency",
                                                                                currency: "VND",
                                                                            }).format(
                                                                                consultation.customFields?.[field.fieldName] || 0
                                                                            )}
                                                                        </span>
                                                                    );
                                                                }

                                                                if (field.fieldName === "lieuTrinh") {
                                                                    return consultation.customFields?.[field.fieldName] || 0;
                                                                }

                                                                if (field.fieldName === "ngayThucHien") {
                                                                    return consultation.customFields?.[field.fieldName]
                                                                        ? formatDate(consultation.customFields?.[field.fieldName])
                                                                        : "Chưa chọn";
                                                                }

                                                                if (field.fieldName === "tenDichVu") {
                                                                    return Array.isArray(
                                                                        consultation.customFields?.[field.fieldName]
                                                                    ) ? (
                                                                        <ol>
                                                                            {consultation.customFields[
                                                                                field.fieldName
                                                                            ].map((item, idx) => (
                                                                                <li key={idx}>
                                                                                    {item.label || item.name || item}
                                                                                </li>
                                                                            ))}
                                                                        </ol>
                                                                    ) : (
                                                                        "Không rõ"
                                                                    );
                                                                }

                                                                return consultation.customFields?.[field.fieldName] || "Không rõ";
                                                            })()}
                                                        </td>
                                                    ) : (
                                                        <td key={`${consultation._id}-${field.fieldName}`}>&nbsp;</td>
                                                    )
                                                )}

                                            {/* Hành Động */}
                                            <td className="text-center">
                                                <Button
                                                    variant="warning"
                                                    size="sm"
                                                    onClick={() => handleEditConsultation(consultation)}
                                                    disabled={consultationsWithReceipts.has(consultation._id)}
                                                >
                                                    Sửa
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => confirmDelete(consultation._id)}
                                                    disabled={consultationsWithReceipts.has(consultation._id)}
                                                    className="m-1"
                                                >
                                                    Xóa
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                });
                            });
                        })()}
                    </tbody>
                </Table>

            ) : (
                <div>Chưa có tư vấn nào.</div>
            )}
            <ConsultationModal
                handleClose={() => {
                    setSelectedConsultation(null);
                    setShowConsultationModal(false);
                }}
                permissions={permissions}
                show={showConsultationModal}
                fetchConsultations={fetchConsultations} // Giữ nguyên cách gọi hàm reload của bạn
                selectedConsultation={selectedConsultation} // Dữ liệu tư vấn đang chỉnh sửa
                isEditMode={!!selectedConsultation} // Trạng thái thêm/sửa
                currentRole={currentRole} // Vai trò hiện tại
            />


            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)} // Đóng modal
                handleConfirm={handleDeleteConsultation}       // Gọi logic xóa
            />

        </PageWrapper>
    );
};

export default Consultations;
