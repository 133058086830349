import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

const CustomToast = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ToastContainer
            position={isMobile ? "bottom-center" : "bottom-right"}
            autoClose={isMobile ? 2000 : 3000}
            hideProgressBar={!isMobile} // Ẩn thanh tiến trình trên Mobile
            closeOnClick
            pauseOnHover={!isMobile} // Mobile không tạm dừng khi hover
            draggable={!isMobile} // Mobile không kéo toast
            pauseOnFocusLoss
        />
    );
};

export default CustomToast;
