import React, { useState, useEffect } from 'react';
import ProfileHeader from '../components/Profile/ProfileHeader';
import ProfileInfo from '../components/Profile/ProfileInfo';
import ProfileTabs from '../components/Profile/ProfileTabs';
import PostsSection from '../components/Profile/PostsSection';
import TimelineSection from '../components/Profile/TimelineSection';
import Loading from '../components/Loading';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Profile = () => {

    const { slug } = useParams(); // Lấy slug từ URL
    const [userData, setUserData] = useState(null);
    const [activeTab, setActiveTab] = useState('posts');
    const [loading, setLoading] = useState(false);
    const [isOwner, setIsOwner] = useState(false); // Kiểm tra xem có phải chủ tài khoản không
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                // Lấy thông tin người dùng dựa trên slug
                const response = await axios.get(`${apiUrl}/user/slug/${slug}`);
                const user = response.data;
                setUserData(user);

                // Kiểm tra quyền sở hữu tài khoản
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const decoded = jwtDecode(token);
                        setIsOwner(decoded.userId === user._id); // Kiểm tra userId
                    } catch (error) {
                        console.error('Token không hợp lệ:', error);
                    }
                }
            } catch (error) {
                console.error("Lỗi khi lấy dữ liệu người dùng:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [slug, apiUrl]);

    if (loading) return <Loading />;

    if (!userData) {
        return <center><p>Không tìm thấy người dùng</p></center>;
    }

    return (
        <div>
            <ProfileHeader
                slug={slug}
                profilePicture={userData?.profilePicture || 'default_profile_picture_url'}
                coverPhoto={userData?.coverPicture || 'default_cover_photo_url'}
                name={userData?.name || 'Tên không có sẵn'}
                verified={userData?.verified || false}
                showCamera={isOwner} // Hiển thị các nút chỉnh sửa nếu là chủ tài khoản
            />
            <ProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab === 'posts' && <PostsSection posts={userData?.posts || []} />}
            {activeTab === 'info' && (
                <ProfileInfo
                    title={userData?.title || 'Chưa cập nhật'}
                    company={userData?.companyName || 'Chưa cập nhật'}
                    location={userData?.companyAddress || 'Chưa cập nhật'}
                    phone={userData?.phone || 'Chưa cập nhật'}
                />
            )}
            {activeTab === 'timeline' && <TimelineSection events={userData?.timeline || []} />}
        </div>
    );
};

export default Profile;
