import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFontAwesomeIcon } from "../data/getFontAwesomeIcon";

const Navbar = () => {
    const [menuItems, setMenuItems] = useState([]);
    const token = localStorage.getItem("token");
    const apiUrl = process.env.REACT_APP_API_URL;
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    useEffect(() => {
        const fetchNavbarConfig = async () => {
            try {
                const response = await axios.get(`${apiUrl}/navbar-config/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setMenuItems(response.data);
            } catch (error) {
                console.error("Lỗi khi lấy cấu hình Navbar:", error);
            }
        };

        fetchNavbarConfig();
    }, []);

    return (
        <Nav>
            {menuItems.map(
                (item) =>
                    item.visible && (
                        <Nav.Link key={item.key} href={item.href}>
                            <FontAwesomeIcon icon={getFontAwesomeIcon(item.icon)} size="lg" />
                            <br />
                            {item.label}
                        </Nav.Link>
                    )
            )}
        </Nav>
    );
};

export default Navbar;
