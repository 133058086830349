import React, { useState } from "react";
import { Form, Button, Card, Container, InputGroup } from "react-bootstrap";
import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";

const SendInvitation = () => {
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("User");
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.error("Vui lòng nhập email.");
            return;
        }

        try {
            await axios.post(
                `${apiUrl}/employees/invite`,
                { email, role },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast.success("Đã gửi lời mời thành công!");
            setEmail(""); // Reset email
        } catch (error) {
            console.error("Lỗi khi gửi lời mời:", error.response?.data || error.message);
            toast.error(error.response?.data?.message || "Không thể gửi lời mời.");
        }
    };

    return (
        <Container>
            <Card className="m-2">
                <Card.Header className="bg-secondary text-white text-center">
                    <h5>Gửi lời mời</h5>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <InputGroup.Text>Email nhân viên</InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Nhập email"
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <InputGroup.Text>Vai trò</InputGroup.Text>
                                <Form.Select value={role} onChange={(e) => setRole(e.target.value)}>
                                    <option value="Manager">Quản lý</option>
                                    <option value="Doctor">Bác sĩ</option>
                                    <option value="Nurses">Y tá</option>
                                    <option value="User">Nhân viên</option>
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Button variant="primary" type="submit">
                                Gửi lời mời
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default SendInvitation;
