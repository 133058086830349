import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import CustomerFormConfigManager from "../pages/CustomerFormConfigManager";
import SupplyFormConfigManager from "../pages/SupplyFormConfigManager";
import ServiceFormConfigManager from "../pages/ServiceFormConfigManager";
import MedicineFormConfigManager from "../pages/MedicineFormConfigManager";
import ConsultationFormConfigManager from "../pages/ConsultationFormConfigManager";
import PartnerFormConfigManager from '../pages/PartnerFormConfigManager';
import PaymentFormConfigManager from "./PaymentFormConfigManager";
import EmployeeList from "../components/EmployeeList";
import ActivityLog from '../pages/ActivityLogPage';
import NavbarConfigPage from "../pages/NavbarConfigPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faLightbulb } from "@fortawesome/free-solid-svg-icons";

const Config = () => {
    const defaultTab1 = "formCustomer"; // Tab mặc định
    const [activeTab1, setActiveTab1] = useState(
        localStorage.getItem("activeTab1") || defaultTab1 // Lấy tab từ localStorage hoặc mặc định
    );

    const handleTabSelect = (tab) => {
        setActiveTab1(tab);
        localStorage.setItem("activeTab1", tab);
    };
    return (
        <div className="container-fluid">
            <h3>
                <FontAwesomeIcon icon={faCogs} className="m-1" />
                Quản Lý Cấu Hình
            </h3>
            <FontAwesomeIcon icon={faLightbulb} className="me-2 text-warning" /> Nếu không chắc chắn về việc thiết lập, hãy liên hệ hỗ trợ. Thiết lập không đúng có thể ảnh hưởng tới việc hoạt động của ứng dụng.<hr />
            <Tabs defaultActiveKey="formCustomer"
                activeKey={activeTab1}
                onSelect={handleTabSelect}
                className="mb-3"
            >
                <Tab eventKey="formCustomer" title="Bệnh Nhân">
                    <CustomerFormConfigManager />
                </Tab>
                <Tab eventKey="formConsultation" title="Tư Vấn">
                    <ConsultationFormConfigManager />
                </Tab>
                <Tab eventKey="formService" title="Dịch Vụ">
                    <ServiceFormConfigManager />
                </Tab>
                <Tab eventKey="formSupply" title="Vật Tư">
                    <SupplyFormConfigManager />
                </Tab>
                <Tab eventKey="formMedicine" title="Thuốc">
                    <MedicineFormConfigManager />
                </Tab>
                <Tab eventKey="navBar" title="Menu Chính">
                    <NavbarConfigPage />
                </Tab>
                <Tab eventKey="employeeList" title="Nhân Viên">
                    <EmployeeList />
                </Tab>
                <Tab eventKey="partner" title="Đối tác">
                    <PartnerFormConfigManager />
                </Tab>
                <Tab eventKey="payment" title="Phiếu chi">
                    <PaymentFormConfigManager />
                </Tab>
                <Tab eventKey="activeLogs" title="Nhật Ký">
                    <ActivityLog />
                </Tab>
            </Tabs>
        </div>
    );
};

export default Config;
