import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons';

const Loading = ({ message = 'Đang tải dữ liệu...', icon = faHeartPulse }) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh' }}>
            <FontAwesomeIcon icon={icon} size="3x" className="text-danger grow-animation" />
            <h5 className="mt-3 text-primary">{message}</h5>
        </div>
    );
};

export default Loading;
