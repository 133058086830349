import React, { useEffect, useState, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const PrintReceiptModal = ({ show, handleClose, debt }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  // Kiểm tra token trước khi giải mã
  let userId;
  if (token && typeof token === 'string') {
    try {
      const decoded = jwtDecode(token);
      userId = decoded.userId;
    } catch (error) {
      console.error('Token không hợp lệ:', error);
    }
  } else {
    console.warn('Token không hợp lệ hoặc không tồn tại.');
  }

  const [companyInfo, setCompanyInfo] = useState({ name: '', logo: '', address: '', phone: '' });

  // Lấy thông tin công ty từ API
  const fetchUser = useCallback(async () => {
    if (userId) {
      try {
        const response = await axios.get(`${apiUrl}/user/${userId}`); // Endpoint để lấy thông tin người dùng
        setCompanyInfo({
          name: response.data.user.companyName,
          logo: response.data.user.logo,
          address: response.data.user.companyAddress,
          phone: response.data.user.companyPhone
        });
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng:', error);
      }
    }
  }, [apiUrl, userId]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (!debt) return null; // Trả về null nếu không có dữ liệu

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
            <html>
                <head>
                    <title>In Phiếu Thu</title>
                    <style>
                        @media print {
                            @page {
                                size: A4;
                                margin: 5mm;
                            }
                        }
                        body {
                            font-family: Arial, sans-serif;
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                        }
                        .receipt {
                            padding: 5px;
                        }
                        .header {
                            text-align: center;
                            margin-bottom: 5px;
                        }
                        .content {
                            margin: 5px 0;
                        }
                        .logo {
                            max-width: 100px; /* Kích thước tối đa của logo */
                        }
                    </style>
                </head>
                <body>
                    <div class="receipt">
                    <table style="width:100%">
<tr>
<td rowspan="4" style="width: 100px">${companyInfo.logo ? `<img src="${companyInfo.logo}" class="logo" alt="Logo"/>` : ''}</td>
<td></td>
<td></td>
</tr>
  <tr>
    <td> ${companyInfo.name}</td>
    <td></td>
    <td></td>
    <td style="text-align: right">Số: ${new Date().getFullYear()}/${String(debt.totalDebtReceiptsForYear).padStart(4, '0')}</td>
  </tr>
    <tr>
    <td>Địa chỉ: ${companyInfo.address}</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td>Điện thoại: ${companyInfo.phone}</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
</table>
<center>
<h1>PHIẾU THU</h1>
<b>${debt.debtReceiptCode}</b><br/><br/>
<i>Ngày ${debt.printDay} tháng ${debt.printMonth} năm ${debt.printYear}</i>
</center><br/>

<table style="width:100%">
  <tr>
    <td style="width:25%">Người Nộp Tiền:</td>
    <td>${debt.customerName}</td>
  </tr>
  <tr>
    <td>Địa chỉ:</td>
    <td>${''}</td>
  </tr>
  <tr>
    <td>Nội dung: </td>
    <td>${''}</td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td>Số tiền: </td>
    <td>${debt.debtAmount.toLocaleString('vi-VN')} VND</td>
  </tr>
  <tr>
    <td><i>Số tiền bằng chữ:</i> </td>
    <td><i>${debt.amountInWords}</i></td>
  </tr>
</table>
<br/>
<table style="width:100%">
  <tr>
  <td style="text-align: center">NGƯỜI LẬP PHIẾU</td>
  <td style="text-align: center">NGƯỜI NỘP TIỀN</td>
  </tr>
  </table>
                    </div>
<br /><br /><br /><br /><br /><br /><br />
<div class="receipt">
                    <table style="width:100%">
<tr>
<td rowspan="4" style="width: 100px">${companyInfo.logo ? `<img src="${companyInfo.logo}" class="logo" alt="Logo"/>` : ''}</td>
<td></td>
<td></td>
</tr>
  <tr>
    <td> ${companyInfo.name}</td>
    <td></td>
    <td></td>
    <td style="text-align: right">Số: ${new Date().getFullYear()}/${String(debt.totalDebtReceiptsForYear).padStart(4, '0')}</td>
  </tr>
    <tr>
    <td>Địa chỉ: ${companyInfo.address}</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td>Điện thoại: ${companyInfo.phone}</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
</table>
<center>
<h1>PHIẾU THU</h1>
<b>${debt.debtReceiptCode}</b><br/><br/>
<i>Ngày ${debt.printDay} tháng ${debt.printMonth} năm ${debt.printYear}</i>
</center><br/>

<table style="width:100%">
  <tr>
    <td style="width:25%">Người Nộp Tiền:</td>
    <td>${debt.customerName}</td>
  </tr>
  <tr>
    <td>Địa chỉ:</td>
    <td>${''}</td>
  </tr>
  <tr>
    <td>Nội dung: </td>
    <td>${''}</td>
  </tr>
  <tr>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td>Số tiền: </td>
    <td>${debt.debtAmount.toLocaleString('vi-VN')} VND</td>
  </tr>
  <tr>
    <td><i>Số tiền bằng chữ:</i> </td>
    <td><i>${debt.amountInWords}</i></td>
  </tr>
</table>
<br/>
<table style="width:100%">
  <tr>
  <td style="text-align: center">NGƯỜI LẬP PHIẾU</td>
  <td style="text-align: center">NGƯỜI NỘP TIỀN</td>
  </tr>
  </table>
                    </div>
                </body>
            </html>
        `);
    printWindow.document.close();
    handleClose();
    setTimeout(() => {
      printWindow.focus(); // Chuyển đến cửa sổ in
      printWindow.print(); // Thực hiện lệnh in
      printWindow.close(); // Đóng cửa sổ in
    }, 500);

  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faPrint} /> In Phiếu Thu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Số Phiếu Thu: {debt.debtReceiptCode}</p>
        <p>Khách Hàng: <b>{debt.customerName}</b></p>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center'>
        <Button variant="secondary" onClick={handleClose}>Đóng</Button>
        <Button variant="primary" onClick={handlePrint}>Xem Phiếu Thu</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintReceiptModal;
