import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const PrintSupplyUsageModal = ({ show, handleClose, supplyUsage, currentRole }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  let userId;
  if (token && typeof token === 'string') {
    try {
      const decoded = jwtDecode(token);
      userId = decoded.userId;
    } catch (error) {
      console.error('Token không hợp lệ:', error);
    }
  }

  const [companyInfo, setCompanyInfo] = useState({ name: '', nameCPN: '', logo: '', address: '', phone: '' });

  const fetchUser = useCallback(async () => {
    if (userId) {
      try {
        const response = await axios.get(`${apiUrl}/user/${userId}`);
        setCompanyInfo({
          name: response.data.user.name,
          nameCPN: response.data.user.companyName,
          logo: response.data.user.logo,
          address: response.data.user.companyAddress,
          phone: response.data.user.companyPhone,
        });
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng:', error);
      }
    }
  }, [apiUrl, userId]);

  useEffect(() => {
    if (supplyUsage) {
      fetchUser();
    }
  }, [supplyUsage, fetchUser]);

  const supplyUsageHTML = supplyUsage?.usageDetails.map((sup, index) => `
  <p><b>${index + 1}. ${sup.supplyId.customFields.tenVatTu}</b> - Số lượng: ${sup.soLuong || 'Không rõ'} ${sup.supplyId.customFields.donVi}<br/>
  <i>${sup.note}</i></p>
`).join('');

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
<head>
  <title>In Vật Tư Sử Dụng</title>
  <style>
    @media print {
        @page { 
            size: A5; 
            margin: 5mm; 
        }
        body {
            font-family: Arial, sans-serif;
            font-size: 12px; /* Giảm kích thước chữ để tiết kiệm không gian */
            margin: 0;
            padding: 0;
            width: 100%;
        }
        .supply-usage {
            padding: 5px;
            width: 100%;
            position: relative;
            min-height: 100%;
            box-sizing: border-box;
        }
        .header {
            text-align: center;
            margin-bottom: 5px;
        }
        .logo {
            max-width: 40px; /* Giảm kích thước logo */
        }
        .content {
            margin: 5px 0;
        }
        footer {
            position: absolute;
            bottom: 5mm; /* Đặt sát lề dưới */
            width: 100%;
            font-size: 12px; /* Giảm kích thước chữ ở footer */
        }
        .footer-content {
            display: flex; 
            justify-content: space-between; 
            width: 100%;
        }
    }
</style>
</head>
<body>
    <div class="supply-usage">
        <table style="width:100%">
            <tr>
                <td rowspan="4" style="width: 40px">
                    ${companyInfo.logo ? `<img src="${companyInfo.logo}" class="logo" alt="Logo"/>` : ''}
                </td>
                <td style="font-size: 12px;">${companyInfo.nameCPN}</td>
                <td style="text-align: right; margin-right: 20px;"></td>
            </tr>
            <tr><td style="font-size: 12px;">Địa chỉ: ${companyInfo.address}</td><td></td></tr>
            <tr><td style="font-size: 12px;">Điện thoại: ${companyInfo.phone}</td><td></td></tr>
        </table>
        <center>
            <h1>PHIẾU VẬT TƯ</h1>
        </center>
        <p><b>Họ Tên:</b> ${supplyUsage.customerId.customFields?.hoVaTen.toUpperCase()} - <b>Tuổi:</b> ${new Date().getFullYear() - supplyUsage.customerId.customFields.namSinh}- <b>Giới:</b> ${supplyUsage.customerId.customFields.gioiTinh}</p>
        <p><b>Địa chỉ:</b> ${supplyUsage.customerId.customFields?.diaChi || "Không có"} - <b>Số ĐT:</b> ${supplyUsage.customerId.customFields?.soDienThoai || "Không có"}</p>
        <hr/>
        ${supplyUsageHTML}
        <hr/>
        <footer>
    <hr />
    <!-- Phần cuối trang với 2 cột -->
    <div class="doctor-notes" style="display: flex; justify-content: space-between; width: 100%; padding-top: 5px;">
        <div style="flex: 1; padding-left: 20px;">
            <b>Lời dặn:</b>
            <p>
                ${supplyUsage.instructions || "- Sử dụng theo hướng dẫn.<br/>- Kiểm tra kỹ vật tư trước khi sử dụng.<br/>- Liên hệ ngay khi có vấn đề."}
            </p>
        </div>
        <div style="flex: 1; text-align: center;">
            <p style="margin: 0; padding-top: 0px;">
                <i>Ngày ${new Date().getDate()} Tháng ${new Date().getMonth() + 1} Năm ${new Date().getFullYear()}</i><br/>
                <b>Người Phụ Trách</b>
            </p>
            <p style="margin-top: 40px;"><b>${companyInfo.name}</b></p>
        </div>
    </div>
</footer>
</body>
</html>`);
    printWindow.document.close();
    handleClose();
    setTimeout(() => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faPrint} /> Xem Vật Tư Sử Dụng</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Khách Hàng: <b>{supplyUsage?.customerId.customFields.hoVaTen}</b></p>
        <p>Tuổi: <b>{supplyUsage?.customerId.customFields.namSinh} ({new Date().getFullYear() - supplyUsage?.customerId.customFields.namSinh})</b> Giới: <b>{supplyUsage?.customerId.customFields.gioiTinh}</b></p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="secondary" onClick={handleClose}>Đóng</Button>
        <Button variant="primary" onClick={handlePrint}>In Phiếu</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintSupplyUsageModal;
