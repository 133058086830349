import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CustomerList from './pages/CustomerList';
import Consultations from './pages/Consultations';
import Appointments from './pages/Appointments';
import Courses from './pages/Courses';
import ImageGallery from './pages/ImageGallery';
import ServiceList from './pages/ServiceList';
import Supplies from './pages/Supplies';
import SupplyUsage from './components/SupplyUsage';
import SupplyReceiptList from './components/SupplyReceiptList';
import Medicines from './pages/Medicine';
import MedicineReceiptList from './components/MedicineReceiptList';
import Prescription from './components/Prescription';
import Receipts from './pages/Receipts';
import Debts from './pages/Debts';
import Login from './pages/Login';
import Register from './pages/Register';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import NotFound from './components/NotFound';
import Support from './components/Support';
import VersionsPage from './pages/VersionsPage';
import AccountSettings from './pages/AccountSettings';
import PartnerPage from './pages/PartnerPage';
import Payments from './pages/Payments';
import BCLS from './pages/BCLS';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CustomToast from './components/CustomToast';
import Config from './pages/Config';
import Invitations from './pages/Invitations';
import SendInvitation from './pages/SendInvitation';
import { jwtDecode } from 'jwt-decode';
import Notifications from "./components/Notifications";
import axios from 'axios';
import { RoleProvider } from './components/RoleContext';
import { toast } from 'react-toastify';

const App = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}logout`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } catch (error) {
      console.error("Lỗi khi đăng xuất:", error);
    }

    // Xóa tất cả dữ liệu liên quan
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies();

    setIsLoggedIn(false);
    window.location.href = "/login";
  };


  const token = localStorage.getItem("token");
  let userId = null;

  if (token) {
    try {
      const decoded = jwtDecode(token);
      userId = decoded.userId;
    } catch (error) {
      console.error("Token không hợp lệ:", error.message);
      // Xử lý token không hợp lệ
      localStorage.removeItem("token");
    }
  }
  const [userRole, setUserRole] = useState(null); // Vai trò của người dùng


  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const currentUser = response.data.find(user => user._id === userId);
        const userLevel = currentUser?.level || 'Không tìm thấy cấp bậc';
        // console.log('userLevel: ', userLevel);
        setUserRole(userLevel); // Lưu `role` từ API hiện có
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng:', error);
        toast.error('Không thể tải thông tin người dùng');
      }
    };
    fetchUserRole();
  }, [token]);
  <div>
    <header className="d-flex justify-content-between align-items-center px-4 py-3 bg-light">
      <h1>Quản lý nhân viên</h1>
      <div className="d-flex align-items-center">
        {/* Thêm Notifications vào Header */}
        <Notifications />
      </div>
    </header>
    {/* Nội dung khác */}
  </div>

  return (
    <RoleProvider>
      <Router>
        <div className={isLoggedIn ? 'profile' : 'public-view'} onContextMenu={(e) => e.preventDefault()}>
          {isLoggedIn && (
            <CustomNavbar handleLogout={handleLogout} />
          )}
          <div className='app-container'>
            <div className='flex-grow-1'>
              <Routes>
                <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                <Route path="/register" element={isLoggedIn ? <Navigate to="/" /> : <Register />} />
                <Route path="/customers" element={isLoggedIn ? <CustomerList /> : <Navigate to="/login" />} />
                <Route path="/consultations" element={isLoggedIn ? <Consultations /> : <Navigate to="/login" />} />
                <Route path="/appointments" element={isLoggedIn ? <Appointments /> : <Navigate to="/login" />} />
                <Route path="/courses" element={isLoggedIn ? <Courses /> : <Navigate to="/login" />} />
                <Route path="/images" element={isLoggedIn ? <ImageGallery /> : <Navigate to="/login" />} />
                <Route path="/services" element={isLoggedIn ? <ServiceList /> : <Navigate to="/login" />} />
                <Route path="/supplies" element={isLoggedIn ? <Supplies /> : <Navigate to="/login" />} />
                <Route path="/supplyreceipts" element={isLoggedIn ? <SupplyReceiptList /> : <Navigate to="/login" />} />
                <Route path="/supplyusage" element={isLoggedIn ? <SupplyUsage /> : <Navigate to="/login" />} />
                <Route path="/medicines" element={isLoggedIn ? <Medicines /> : <Navigate to="/login" />} />
                <Route path="/medicinereceipts" element={isLoggedIn ? <MedicineReceiptList /> : <Navigate to="/login" />} />
                <Route path="/prescription" element={isLoggedIn ? <Prescription /> : <Navigate to="/login" />} />
                <Route path="/receipts" element={isLoggedIn ? <Receipts /> : <Navigate to="/login" />} />
                <Route path="/debts" element={isLoggedIn ? <Debts /> : <Navigate to="/login" />} />
                <Route path="/settings" element={isLoggedIn ? <Settings /> : <Navigate to="/login" />} />
                <Route path="/settings/account" element={isLoggedIn ? <AccountSettings /> : <Navigate to="/login" />} />
                <Route path='/:slug' element={<Profile />} />
                <Route path="/" element={isLoggedIn ? <CustomerList /> : <Navigate to="/login" />} />
                <Route path='*' element={<NotFound />} />
                <Route path='/versions' element={isLoggedIn ? <VersionsPage /> : <Navigate to="/login" />} />
                <Route path='/cf' element={isLoggedIn ? <Config /> : <Navigate to="/login" />} />
                <Route path="/send-invitation" element={isLoggedIn ? <SendInvitation /> : <Navigate to="/login" />} />
                <Route path="/invitations" element={isLoggedIn ? <Invitations /> : <Navigate to="/login" />} />
                <Route path="/partners" element={isLoggedIn ? <PartnerPage /> : <Navigate to="/login" />} />
                <Route path="/payments" element={isLoggedIn ? <Payments /> : <Navigate to="/login" />} />
                <Route path="/bcls" element={<BCLS />} />
              </Routes>
            </div>
          </div>
          <footer className="bg-light text-center text-lg-start mt-auto footer-app" fixed="bottom">
            <div className="text-center p-1">
              © {new Date().getFullYear()} <b>OHS.Plus</b>. All rights reserved.
            </div>
            {!isMobile ?
              <div className="d-flex justify-content-between m-2">
                <div>
                  <Link as={Link} to="/versions"> <FontAwesomeIcon icon={faInfoCircle} /></Link>
                </div>
                <div className="ml-auto support-link">
                  <Link as={Link} to='tel:+84985660198'>
                    <Support />
                  </Link>
                </div>
              </div> : ''}
          </footer>
          <CustomToast />
        </div>
      </Router>
    </RoleProvider>
  );
};

export default App;
