import React, { useState, useEffect } from 'react';

const PageWrapper = ({ children, pageTitle, pageIcon }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {/* Tiêu đề */}
            <div className={`page-title ${isMobile ? 'mobile-title' : ''}`}>
                <h2>
                    {pageIcon && <span style={{ marginRight: '8px' }}>{pageIcon}</span>}
                    {pageTitle}
                </h2>
            </div>
            {/* Nội dung */}
            <div className="page-content">
                {children}
            </div>
        </div>
    );
};

export default PageWrapper;
