import React, { useState, useEffect, useContext } from 'react';
import { Navbar, Nav, Dropdown, Offcanvas } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome, faComments, faFileInvoiceDollar, faCalendarDay,
    faTasks, faImage, faHandHoldingHeart, faBoxOpen, faPills,
    faMoneyBill, faCog, faUserCog, faSignOutAlt, faBars,
    faBell, faCheckCircle, faCogs
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import logo from '../assets/images/ohs.plus.png';
import { RoleContext } from '../components/RoleContext';
import Navbarmenu from './Navbar';

const CustomNavbar = ({ handleLogout }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const { currentRole, setCurrentRole } = useContext(RoleContext);

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }

    const toggleMenu = () => setShowMenu(!showMenu);

    const fetchNotifications = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${apiUrl}/employees/notifications`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { userId },
            });
            setNotifications(response.data || []);
        } catch (error) {
            console.error("Lỗi khi lấy thông báo:", error);
            toast.error("Không thể lấy thông báo.");
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await axios.get(`${apiUrl}/employees/role`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { userId },
                });

                const roles = response.data;
                setRoles(roles);

                // Tự động chọn ID nếu chỉ có 1 vai trò
                if (roles.length === 1) {
                    const defaultRoleId = roles[0].ownerId; // ID người làm việc duy nhất
                    setCurrentRole(defaultRoleId); // Đặt ID vào state
                    localStorage.setItem("currentRole", defaultRoleId); // Lưu ID vào localStorage
                }
            } catch (error) {
                console.error("Lỗi khi lấy vai trò:", error);
            }
        };

        fetchRoles();
    }, [apiUrl, token, userId]);

    const handleRoleChange = (newRoleId) => {
        setCurrentRole(newRoleId); // Đặt ID mới vào state
        localStorage.setItem("currentRole", newRoleId); // Lưu ID vào localStorage
        window.location.reload(); // Tải lại để áp dụng thay đổi
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
        window.addEventListener('resize', handleResize);

        // Lấy thông báo ngay khi component mount
        fetchNotifications();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNotificationClick = async (invitationId, action) => {
        try {
            const response = await axios.post(
                `${apiUrl}/employees/notifications/${invitationId}/respond`,
                { action },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast.success(response.data.message);
            fetchNotifications(); // Tải lại danh sách thông báo sau khi xử lý
        } catch (error) {
            console.error("Lỗi khi xử lý thông báo:", error);
            toast.error("Không thể xử lý thông báo.");
        }
    };


    return (
        <>
            {/* Navbar trên PC */}
            {!isMobile && (
                <Navbar bg="light" expand="xl" fixed="top" className="custom-navbar">
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            width="68"
                            height="68"
                            className="d-inline-block align-top ms-3"
                            alt="OHS.Plus"
                        />
                    </Navbar.Brand>
                    <Navbarmenu />
                    <div className="d-flex align-items-center ms-auto">
                        <Dropdown align="end" className="m-1">
                            <Dropdown.Toggle variant="warning" id="dropdown-notifications">
                                <FontAwesomeIcon icon={faBell} /> Thông báo
                                {notifications.length > 0 && (
                                    <span className="badge bg-danger ms-2">{notifications.length}</span>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {isLoading ? (
                                    <Dropdown.Item>Đang tải...</Dropdown.Item>
                                ) : notifications.length === 0 ? (
                                    <Dropdown.Item>Không có thông báo mới.</Dropdown.Item>
                                ) : (
                                    notifications.map((notification) => (
                                        <Dropdown.Item key={notification._id}>
                                            <p className="mb-0">
                                                Vai trò: {notification.role}
                                            </p>
                                            <p className="mb-0">
                                                Bạn được mời bởi: {notification.ownerId?.name || 'Không rõ'}
                                            </p>
                                            <div className="mt-2 d-flex justify-content-between">
                                                <button
                                                    className="btn btn-sm btn-success"
                                                    onClick={() => handleNotificationClick(notification._id, 'accept')}
                                                >
                                                    Chấp nhận
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => handleNotificationClick(notification._id, 'reject')}
                                                >
                                                    Từ chối
                                                </button>
                                            </div>
                                        </Dropdown.Item>
                                    ))
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown align="end" className="m-1">
                            <Dropdown.Toggle variant="secondary" id="dropdown-settings">
                                <FontAwesomeIcon icon={faCog} /> Cài đặt
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/settings/account">
                                    <FontAwesomeIcon icon={faUserCog} /> Cài đặt cá nhân
                                </Dropdown.Item>
                                <Dropdown.Item href="/cf">
                                    <FontAwesomeIcon icon={faCogs} /> Cài đặt cấu hình
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                {roles.map((role) => (
                                    <Dropdown.Item
                                        key={role.ownerId}
                                        onClick={() => handleRoleChange(role.ownerId)}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        {role.ownerName} ({role.role})
                                        {currentRole === role.ownerId && <FontAwesomeIcon icon={faCheckCircle} className='ms-2 text-success' />}
                                    </Dropdown.Item>
                                ))}
                                <Dropdown.Item onClick={handleLogout}>
                                    <FontAwesomeIcon icon={faSignOutAlt} /> Đăng xuất
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Navbar>
            )}

            {/* Menu trên Mobile */}
            {isMobile && (
                <>
                    <div className="d-flex fixed-bottom justify-content-start p-2">
                        <button
                            className="menu-btn"
                            onClick={toggleMenu}
                        >
                            <FontAwesomeIcon icon={faBars} size="lg" />
                        </button>
                    </div>
                    <Offcanvas show={showMenu} onHide={toggleMenu} placement="start">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="app-menu">
                                <a href="/" className="menu-item">
                                    <FontAwesomeIcon icon={faHome} size="2x" />
                                    <span>Trang chủ</span>
                                </a>
                                <a href="/consultations" className="menu-item">
                                    <FontAwesomeIcon icon={faComments} size="2x" />
                                    <span>Tư Vấn/Khám</span>
                                </a>
                                <a href="/receipts" className="menu-item">
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />
                                    <span>Phiếu Thu</span>
                                </a>
                                <a href="/appointments" className="menu-item">
                                    <FontAwesomeIcon icon={faCalendarDay} size="2x" />
                                    <span>Lịch Hẹn</span>
                                </a>
                                <a href="/courses" className="menu-item">
                                    <FontAwesomeIcon icon={faTasks} size="2x" />
                                    <span>Liệu Trình</span>
                                </a>
                                <a href="/images" className="menu-item">
                                    <FontAwesomeIcon icon={faImage} size="2x" />
                                    <span>Hình Ảnh</span>
                                </a>
                                <a href="/services" className="menu-item">
                                    <FontAwesomeIcon icon={faHandHoldingHeart} size="2x" />
                                    <span>Dịch Vụ</span>
                                </a>
                                <a href="/supplies" className="menu-item">
                                    <FontAwesomeIcon icon={faBoxOpen} size="2x" />
                                    <span>Vật Tư/Sản phẩm</span>
                                </a>
                                <a href="/medicines" className="menu-item">
                                    <FontAwesomeIcon icon={faPills} size="2x" />
                                    <span>Thuốc/Kê toa</span>
                                </a>
                                <a href="/debts" className="menu-item">
                                    <FontAwesomeIcon icon={faMoneyBill} size="2x" />
                                    <span>Quản Lý Nợ</span>
                                </a>
                                <a href="/settings/account" className="menu-item">
                                    <FontAwesomeIcon icon={faCog} size="2x" />
                                    <span>Cài Đặt</span>
                                </a>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </>
            )}
        </>
    );
};

export default CustomNavbar;
