import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faPrint, faTrash, faSave, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import ModalConfirm from './ModalConfirm';
import PrintSupplyUsageModal from './PrintSupplyUsageModal'; // Import PrintSupplyUsageModal
import formatDate from './FormatDate';
import { RoleContext } from './RoleContext';

const SupplyUsageList = ({ reload, refreshSupplyUsageList, refreshTemplateList }) => {
    const [restoreStock, setRestoreStock] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { currentRole } = useContext(RoleContext);
    const [supplyUsages, setSupplyUsages] = useState([]);
    const [customerMap, setCustomerMap] = useState({});
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [selectedUsage, setSelectedUsage] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false); // Trạng thái cho Print Modal
    const [templateName, setTemplateName] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedUsageToDelete, setSelectedUsageToDelete] = useState(null);
    const inputRef = useRef(null);

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const fetchData = useCallback(async () => {
        try {
            const [supplyUsageRes, customerRes] = await Promise.all([
                axios.get(`${apiUrl}/supplyUsage`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            createdBy: userId,
                            currentRole,
                        },
                    }
                ),
                axios.get(`${apiUrl}/customers`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            createdBy: userId,
                            currentRole,
                            format: "simple",
                        },
                    }
                )
            ]);

            const sortedSupplyUsages = supplyUsageRes.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setSupplyUsages(sortedSupplyUsages);
            const map = {};
            customerRes.data.forEach(customer => {
                map[customer.value] = customer.label;
            });
            setCustomerMap(map);
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchData();
    }, [reload, fetchData]);

    useEffect(() => {
        if (showTemplateModal) {
            inputRef.current?.focus();
        }
    }, [showTemplateModal]);

    const handleClose = () => {
        setShowConfirmModal(false);
        setShowTemplateModal(false);
        setShowPrintModal(false); // Đóng Print Modal
    };

    const openTemplateModal = (usage) => {
        setSelectedUsage(usage);
        setShowTemplateModal(true);
    };

    const openPrintModal = (usage) => {
        setSelectedUsage(usage);
        console.log('usage: ', usage);
        setShowPrintModal(true);
    };

    const handleSaveTemplate = async () => {
        if (!templateName) {
            toast.error('Vui lòng nhập tên cho mẫu.');
            return;
        }

        if (!selectedUsage || !selectedUsage.usageDetails || selectedUsage.usageDetails.length === 0) {
            toast.error('Không có dữ liệu vật tư nào để lưu.');
            return;
        }

        try {
            await axios.post(`${apiUrl}/supply-templates`, {
                name: templateName,
                createdBy: userId,
                usageList: selectedUsage.usageDetails // Đảm bảo gửi `usageDetails`
            });

            toast.success('Lưu mẫu vật tư thành công!');

            if (refreshTemplateList) refreshTemplateList();
            if (typeof refreshSupplyUsageList === 'function') refreshSupplyUsageList();

            setShowTemplateModal(false);
        } catch (error) {
            console.error('Lỗi khi lưu mẫu:', error);
            toast.error('Lỗi khi lưu mẫu!');
        }
    };


    const handleDelete = async () => {
        if (!selectedUsageToDelete) {
            console.error('Không tìm thấy ID phiếu để xóa!');
            return;
        }

        try {
            // Lấy thông tin chi tiết phiếu sử dụng trước khi xóa
            const usageResponse = await axios.get(`${apiUrl}/supplyUsage/${selectedUsageToDelete}`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    currentRole,
                    createdBy: userId,
                },
            });

            const usageDetails = usageResponse.data.usageDetails; // Chi tiết vật tư đã sử dụng

            if (restoreStock) {
                // Hoàn lại kho nếu restoreStock = true
                const stockUpdates = usageDetails.map(async (item) => {
                    const supplyResponse = await axios.get(`${apiUrl}/supplies/${item.supplyId._id}`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole,    // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    });

                    const currentQuantity = Number(supplyResponse.data.customFields.soLuong);
                    const updatedQuantity = currentQuantity + Number(item.soLuong);

                    // Gửi yêu cầu cập nhật số lượng
                    await axios.put(
                        `${apiUrl}/supplies/${item.supplyId._id}`,
                        { customFields: { soLuong: updatedQuantity }, createdBy: userId, currentRole: currentRole },
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                });

                await Promise.all(stockUpdates); // Đợi hoàn tất tất cả các cập nhật kho
                toast.success('Hoàn số lượng vật tư thành công!');
            }

            // Xóa phiếu sử dụng vật tư
            await axios.delete(`${apiUrl}/supplyUsage/${selectedUsageToDelete}`, {
                data: { restoreStock, createdBy: userId, currentRole: currentRole }, // Gửi tham số restoreStock qua API
                headers: { Authorization: `Bearer ${token}` },
            });

            toast.success('Xóa phiếu sử dụng vật tư thành công!');

            // Cập nhật lại danh sách phiếu sử dụng
            setSupplyUsages((prev) => prev.filter((usage) => usage._id !== selectedUsageToDelete));
        } catch (error) {
            console.error('Lỗi khi xóa phiếu sử dụng vật tư:', error);
            toast.error('Lỗi khi xóa phiếu sử dụng vật tư!');
        }
    };

    return (
        <div className="mt-5">
            <h3><FontAwesomeIcon icon={faClipboard} /> Danh Sách Sử Dụng Vật Tư</h3>
            {supplyUsages.length === 0 ? (
                <p className="text-center">Chưa sử dụng vật tư nào</p>
            ) : (
                <Table striped bordered hover>
                    <thead className="text-center">
                        <tr>
                            <th>STT</th>
                            <th>Khách Hàng</th>
                            <th>Dịch Vụ</th>
                            <th>Ngày Sử Dụng</th>
                            <th>Lời dặn</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {supplyUsages.map((usage, index) => (
                            <tr key={usage._id}>
                                <td>{index + 1}</td>
                                <td><b>{customerMap[usage.customerId._id] || 'Không xác định'}</b></td>
                                <td>{usage.usageServices || 'Không xác định'}</td>
                                <td>{formatDate(new Date(usage.createdAt))}</td>
                                <td>{usage.instructions || 'Không có'}</td>
                                <td className="text-center">
                                    <Button className="m-1" size="sm" variant="info" onClick={() => openPrintModal(usage)}>
                                        <FontAwesomeIcon icon={faPrint} /> In
                                    </Button>
                                    <Button className="m-1" size="sm" variant="danger"
                                        onClick={() => {
                                            setSelectedUsageToDelete(usage._id);
                                            setRestoreStock(true); // Xóa và hoàn kho
                                            setShowConfirmModal(true); // Hiển thị modal
                                        }}>
                                        <FontAwesomeIcon icon={faTrashRestore} /> Xóa & Hoàn kho
                                    </Button>
                                    <Button
                                        className="m-1"
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => {
                                            setSelectedUsageToDelete(usage._id);
                                            setRestoreStock(false); // Chỉ xóa, không hoàn kho
                                            setShowConfirmModal(true); // Hiển thị modal
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} /> Xóa phiếu
                                    </Button>
                                    <Button className="m-1" size="sm" variant="primary" onClick={() => openTemplateModal(usage)}>
                                        <FontAwesomeIcon icon={faSave} /> Lưu mẫu
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Modal show={showTemplateModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faSave} /> Lưu Mẫu Vật Tư</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="templateName">
                        <Form.Label>Nhập tên mẫu vật tư</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Tên mẫu"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            ref={inputRef}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Ngăn chặn hành vi mặc định của Enter
                                    handleSaveTemplate(); // Gọi hàm lưu
                                }
                            }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Hủy</Button>
                    <Button variant="primary" onClick={handleSaveTemplate}>Lưu</Button>
                </Modal.Footer>
            </Modal>

            <PrintSupplyUsageModal
                show={showPrintModal}
                handleClose={handleClose}
                supplyUsage={selectedUsage}
                currentRole={currentRole}
            />

            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDelete}
            />
        </div>
    );
};

export default SupplyUsageList;
