import React from 'react';
import { components } from 'react-select';

// Tùy chỉnh thành phần Option để in đậm tên thuốc
const MedicineCustomOption = (props) => {
    return (
        <components.Option {...props}>
            <div>
                <strong>{props.data.tenThuoc}</strong> | Còn: {props.data.soLuong < 20 ? <span className='text-danger'><strong>{props.data.soLuong}</strong></span> : props.data.soLuong} {props.data.donViTinh} | Giá (1 {props.data.donViTinh}): {props.data?.giaBan.toLocaleString('vi-VN')}đ
            </div>
        </components.Option>
    );
};

export default MedicineCustomOption;
