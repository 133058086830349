import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Badge } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import formatDate from './FormatDate';

const AppoitmentsList = ({ apiUrl, userId, onFresh, refreshList }) => {
    const [appointments, setAppointments] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const translateStatus = (status) => {
        switch (status) {
            case 'Pending':
                return 'Đang chờ';
            case 'Completed':
                return 'Hoàn thành';
            case 'Canceled':
                return 'Đã hủy';
            default:
                return 'Không xác định'; // Nếu có trạng thái nào khác
        }
    };
    const fetchAppointments = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/appointments?createdBy=${userId}`);
            const sortedAppointments = response.data.sort((a, b) => new Date(b.start) - new Date(a.start));
            setAppointments(sortedAppointments);
        } catch (error) {
            console.error("Lỗi khi lấy danh sách cuộc hẹn:", error);
            toast.error("Không thể lấy danh sách cuộc hẹn!");
        }
    }, [apiUrl, userId]);
    useEffect(() => {

        fetchAppointments();
    }, [fetchAppointments]);

    useEffect(() => {
        if (refreshList) {
            refreshList(fetchAppointments);
        }
    }, [refreshList, fetchAppointments]);


    const handleDelete = async (appointmentId) => {
        try {
            await axios.delete(`${apiUrl}/appointments/${appointmentId}?createdBy=${userId}`);
            setAppointments(appointments.filter(appointment => appointment._id !== appointmentId));
            toast.success("Xóa cuộc hẹn thành công!");
            onFresh();
        } catch (error) {
            console.error("Lỗi khi xóa cuộc hẹn:", error);
            toast.error("Không thể xóa cuộc hẹn!");
        }
    };

    const canDelete = (appointment) => {
        const now = new Date();
        return appointment.status !== "Pending" || new Date(appointment.start) < now;
    };

    return (
        <div className="mt-4">
            <hr />
            <h3>Danh Sách Tất Cả Lịch Hẹn</h3>
            {isMobile ? (
                <div className="row">
                    {appointments.length > 0 ? (
                        appointments.map((appointment, index) => {
                            const customer = appointment.customerId;
                            return (
                                <div className="col-md-6 col-sm-12 mb-4" key={appointment._id}>
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                <Badge bg="secondary">{index + 1}</Badge> {customer?.customFields.hoVaTen || 'N/A'} - {customer?.birthday ? new Date(customer.birthday).getFullYear() : 'N/A'}
                                            </h5>
                                            <div className="d-flex">
                                                {/* Cột bên trái: Hình đại diện */}
                                                <div className="me-3 d-flex justify-content-center align-items-center">
                                                    <img
                                                        className="customer-avatar"
                                                        src={
                                                            customer?.gender === "Nam"
                                                                ? 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEivYejoR2LHSmj1k4rPX5GAzkudAMk1bry1vGcUIMKT6KGqVdZHCXei00LlHmy9sVd-KgyBGoBnjUMEadamNiYPCw9ujoBgsZ7d42o2ICLNeiFb_-EZVJ4JJXj9VaIpNDew1kj8OTQzjp682jz71pNprN_YaK5nFQxZ5CKDVsdpkObFahGt-KLNsIIU0vP1/s16000/noProfile.png'
                                                                : 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj4zZog9334BSk5TeAduz4vggNF4RH0O7Frj0Rz46ze56CcfU3GMVrAB1Ly5Dz79AN2tbHhlFnfZ8ZvWqWJhj7TAW4vmEySIb-BKG8_Ez0PiQe6taDRTP8P4wrVOQZmjLQix8t1DIec6vA0_11jTM-xB4DER2C2lzHFO54Zqt9cpUfDOMQkN1TquUTCNFN9/s200/default-avatar-female.jpg'
                                                        }
                                                        alt={customer?.name}
                                                        style={{
                                                            width: "100px",
                                                            height: "100px",
                                                            borderRadius: "50%",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                </div>
                                                {/* Cột bên phải: Thông tin */}
                                                <div>
                                                    <p className="card-text mb-1" style={{ fontSize: '0.9em' }}>
                                                        <b>Cuộc Hẹn:</b> <span className='text-dark'><strong>{appointment.title}</strong></span><br />
                                                        <b>Ngày hẹn:</b> <span className='text-success'><strong>{formatDate(appointment.start)}</strong></span><br />
                                                        <b>Trạng thái cuộc hẹn:</b> {translateStatus(appointment.status)} <br />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-between">
                                            {canDelete(appointment) ? (
                                                <Button
                                                    variant="danger"
                                                    onClick={() => handleDelete(appointment._id)}
                                                >
                                                    Xóa
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" disabled>
                                                    Xóa
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-center">Chưa có lịch hẹn nào</div>
                    )}
                </div>
            ) : (
                <Table striped bordered hover responsive>
                    <thead className='text-center'>
                        <tr>
                            <th>STT</th>
                            <th>Tên Khách Hàng</th>
                            <th>Loại Nhắc Hẹn</th>
                            <th>Ngày Hẹn</th>
                            <th>Trạng Thái</th>
                            <th>Hành Động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointments.length > 0 ? (
                            appointments.map((appointment, index) => {
                                const customer = appointment.customerId; // Assumes customer info is populated
                                return (
                                    <tr key={appointment._id}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td><strong>{customer?.customFields.hoVaTen || 'N/A'}</strong></td>
                                        <td>{appointment.title}</td>
                                        <td>{formatDate(appointment.start)}</td>
                                        <td>{translateStatus(appointment.status)}</td>
                                        <td className='text-center'>
                                            {canDelete(appointment) ? (
                                                <Button
                                                    variant="danger"
                                                    onClick={() => handleDelete(appointment._id)}
                                                >
                                                    Xóa
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" disabled>
                                                    Xóa
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">Chưa có lịch hẹn nào</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default AppoitmentsList;
