import React from 'react';
import { ListGroup } from 'react-bootstrap';

const ProfileInfo = ({ title, company, location, phone }) => {
    return (
        <ListGroup className="profile-info">
            <ListGroup.Item><strong>Chức danh:</strong> {title}</ListGroup.Item>
            <ListGroup.Item><strong>Công ty:</strong> {company}</ListGroup.Item>
            <ListGroup.Item><strong>Địa chỉ:</strong> {location}</ListGroup.Item>
            <ListGroup.Item><strong>Số điện thoại:</strong> {phone}</ListGroup.Item>
        </ListGroup>
    );
};

export default ProfileInfo;
