import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { to_vietnamese } from './numberToWords';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';

const PayDebtModal = ({ show, handleClose, receipt, onReFresh, currentRole }) => {
    const inputRef = useRef(null);
    const [paymentAmount, setPaymentAmount] = useState(''); // Số tiền thanh toán
    const [debtRemainingAmount, setDebtRemainingAmount] = useState(''); // Số tiền còn lại
    const [amountInWords, setAmountInWords] = useState(''); // Số tiền bằng chữ
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const userId = token ? jwtDecode(token).userId : null;

    useEffect(() => {
        if (receipt) {
            setDebtRemainingAmount(receipt.remainingAmount); // Khởi tạo số tiền còn lại khi modal mở
            setPaymentAmount(''); // Đặt lại số tiền thanh toán
        }
    }, [receipt]); // Chạy lại khi receipt thay đổi

    useEffect(() => {
        // Khi component hiển thị, tự động focus vào ô nhập
        if (inputRef.current) {
            inputRef.current.focus();
            setAmountInWords('');
        }
    }, [show]);

    const handlePay = async () => {
        const remainingAmount = receipt.remainingAmount; // Số tiền còn nợ
        const amountToPay = parseFloat(paymentAmount); // Chuyển đổi sang số

        if (amountToPay < remainingAmount) {
            // Nếu số tiền nhập vào nhỏ hơn số tiền đang nợ
            try {
                // Gọi API để tạo phiếu thu nợ mới
                await axios.post(`${apiUrl}/debtreceipts`, {
                    receiptId: receipt._id, // ID phiếu thu
                    customerName: receipt.customerName,
                    debtAmount: amountToPay,
                    amountInWords,
                    debtRemainingAmount: remainingAmount - amountToPay,
                    currentRole,    // Chủ sở hữu dữ liệu
                    createdBy: userId, // Người thực hiện hành động
                },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                await axios.put(`${apiUrl}/receipts/${receipt._id}`,
                    {
                        remainingAmount: remainingAmount - amountToPay,
                        currentRole,    // Chủ sở hữu dữ liệu
                        createdBy: userId, // Người thực hiện hành động
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                toast.success('Thanh toán nợ thành công và đã tạo phiếu thu nợ mới!');
                onReFresh();
            } catch (error) {
                toast.error('Lỗi khi thanh toán nợ!');
                console.error('Error creating new debt receipt:', error);
            }
        } else {
            // Nếu số tiền nhập vào lớn hơn hoặc bằng số tiền nợ
            toast.success('Đã hoàn thành thanh toán!');
            await axios.post(`${apiUrl}/debtreceipts`, {
                receiptId: receipt._id, // ID phiếu thu
                customerName: receipt.customerName,
                debtAmount: amountToPay,
                amountInWords,
                debtRemainingAmount: remainingAmount - amountToPay,
                currentRole,    // Chủ sở hữu dữ liệu
                createdBy: userId, // Người thực hiện hành động
            },
                {
                    headers: { Authorization: `Bearer ${token}` },
                });
            await axios.put(`${apiUrl}/receipts/${receipt._id}`, {
                remainingAmount: 0,
                currentRole,    // Chủ sở hữu dữ liệu
                createdBy: userId, // Người thực hiện hành động
            },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            onReFresh();
        }

        handleClose();
    };

    const handleKeyDown = (event) => {
        if (paymentAmount > 0 && event.key === 'Enter') {
            event.preventDefault(); // Ngăn việc submit form mặc định
            handlePay(); // Gọi hàm thanh toán
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faDollarSign} /> Thanh Toán Nợ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onKeyDown={handleKeyDown}>
                    <Form.Group controlId="formPaymentAmount">
                        <Form.Label>Số tiền thanh toán</Form.Label>
                        <Form.Control
                            ref={inputRef}
                            type="number"
                            placeholder="Nhập số tiền thanh toán"
                            value={paymentAmount}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                setPaymentAmount(value);
                                setAmountInWords(to_vietnamese(value)); // Cập nhật số tiền bằng chữ khi thay đổi
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formAmountInWords">
                        <Form.Label>Số tiền bằng chữ</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Số tiền bằng chữ"
                            value={amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1)}
                            disabled
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handlePay} disabled={!paymentAmount}>
                    Thanh Toán
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PayDebtModal;
