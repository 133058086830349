import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Table, Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import SendInvitation from "../pages/SendInvitation";
import Invitations from "../pages/Invitations";

const EmployeeList = () => {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [permissions, setPermissions] = useState({ canAdd: false, canEdit: false, canDelete: false });
    const [newRole, setNewRole] = useState("");
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [showPermissionsModal, setShowPermissionsModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    let currentUserId = null;

    if (token) {
        try {
            const decoded = jwtDecode(token);
            currentUserId = decoded.userId;
        } catch (error) {
            console.error("Lỗi khi giải mã token:", error);
        }
    }

    const fetchEmployees = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/employees`, {
                params: { ownerId: currentUserId },
                headers: { Authorization: `Bearer ${token}` },
            });
            setEmployees(response.data);
        } catch (error) {
            console.error("Lỗi khi lấy danh sách nhân viên:", error);
            toast.error("Không thể tải danh sách nhân viên.");
        }
    }, [apiUrl, token, currentUserId]);

    useEffect(() => {
        fetchEmployees();
    }, [fetchEmployees]);

    const handleOpenRoleModal = (employee) => {
        setSelectedEmployee(employee);
        setNewRole(employee.role);
        setShowRoleModal(true);
    };

    const handleSaveRole = async () => {
        try {
            await axios.put(
                `${apiUrl}/employees/${selectedEmployee._id}`,
                { role: newRole },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast.success("Cập nhật vai trò thành công!");
            setShowRoleModal(false);
            fetchEmployees();
        } catch (error) {
            console.error("Lỗi khi cập nhật vai trò:", error);
            toast.error("Không thể cập nhật vai trò.");
        }
    };

    const toggleEmployeeStatus = async (employeeId, currentStatus) => {
        try {
            const newStatus = currentStatus === "active" ? "inactive" : "active";
            await axios.patch(
                `${apiUrl}/employees/${employeeId}/status`,
                { status: newStatus },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast.success(`Đã chuyển trạng thái nhân viên thành ${newStatus}`);
            fetchEmployees();
        } catch (error) {
            console.error("Lỗi khi thay đổi trạng thái:", error);
            toast.error("Không thể thay đổi trạng thái.");
        }
    };

    const handleOpenPermissionsModal = (employee) => {
        setSelectedEmployee(employee);
        setPermissions(employee.permissions || { canAdd: false, canEdit: false, canDelete: false });
        setShowPermissionsModal(true);
    };

    const handleSavePermissions = async () => {
        try {
            await axios.put(`${apiUrl}/employees/${selectedEmployee._id}/permissions`, {
                permissions,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success("Cập nhật quyền thành công!");
            setShowPermissionsModal(false);
            fetchEmployees();
        } catch (error) {
            console.error("Lỗi khi lưu quyền:", error);
            toast.error("Không thể lưu quyền.");
        }
    };

    const permissionLabels = {
        canAdd: "Thêm",
        canEdit: "Sửa",
        canDelete: "Xóa",
    };

    return (
        <div className="container-fluid">
            <h3>Danh sách nhân viên</h3>
            <Table striped bordered hover>
                <thead className="text-center">
                    <tr>
                        <th>Tên</th>
                        <th>Email</th>
                        <th>Vai Trò</th>
                        <th>Quyền</th>
                        <th>Hành Động</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map((employee) => (
                        <tr key={employee._id}>
                            <td>{employee.userId?.name || "Không rõ"}</td>
                            <td>{employee.userId?.email || "Không rõ"}</td>
                            <td>{employee.role}</td>
                            <td>
                                <div className="d-flex align-items-center flex-wrap">
                                    {Object.entries(employee.permissions || {}).map(([key, value]) => (
                                        <span key={key}>{value ? <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" /> : <FontAwesomeIcon icon={faCircleXmark} className="text-danger ms-2" />} {permissionLabels[key] || key}</span>
                                    ))}</div>
                            </td>
                            <td className="text-center">
                                <Button
                                    variant="primary"
                                    onClick={() => handleOpenRoleModal(employee)}
                                    className="m-1"
                                >
                                    Cấp vai trò
                                </Button>
                                <Button
                                    variant={employee.status === "active" ? "success" : "secondary"}
                                    onClick={() => toggleEmployeeStatus(employee._id, employee.status)}
                                    className="m-1"
                                >
                                    {employee.status === "active" ? "Active" : "Inactive"}
                                </Button>
                                <Button
                                    variant="warning"
                                    className="m-1"
                                    onClick={() => handleOpenPermissionsModal(employee)}
                                >
                                    Phân quyền
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showRoleModal} onHide={() => setShowRoleModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cập Nhật Vai Trò</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="roleSelect">
                            <Form.Label>Vai Trò</Form.Label>
                            <Form.Select
                                value={newRole}
                                onChange={(e) => setNewRole(e.target.value)}
                            >
                                <option value="Manager">Manager</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Nurses">Nurses</option>
                                <option value="User">User</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRoleModal(false)}>
                        Hủy
                    </Button>
                    <Button variant="primary" onClick={handleSaveRole}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPermissionsModal} onHide={() => setShowPermissionsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Phân quyền nhân viên</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            label="Quyền Thêm"
                            checked={permissions.canAdd}
                            onChange={(e) => setPermissions({ ...permissions, canAdd: e.target.checked })}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Quyền Sửa"
                            checked={permissions.canEdit}
                            onChange={(e) => setPermissions({ ...permissions, canEdit: e.target.checked })}
                        />
                        <Form.Check
                            type="checkbox"
                            label="Quyền Xóa"
                            checked={permissions.canDelete}
                            onChange={(e) => setPermissions({ ...permissions, canDelete: e.target.checked })}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPermissionsModal(false)}>
                        Hủy
                    </Button>
                    <Button variant="primary" onClick={handleSavePermissions}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
            <hr />
            <div className="row fluid">
                <div className="col">
                    <Invitations /></div>
                <div className="col">
                    <SendInvitation /></div>
            </div>
        </div>
    );
};

export default EmployeeList;
