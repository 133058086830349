import React from 'react';
import { Card, Button } from 'react-bootstrap';

const PostsSection = ({ posts }) => {
    return (
        <div className="posts-section">
            {posts.map((post, index) => (
                <Card key={index} className="mb-3">
                    <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Card.Text>{post.content}</Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                        <Button variant="link">Thích</Button>
                        <Button variant="link">Bình luận</Button>
                        <Button variant="link">Chia sẻ</Button>
                    </Card.Footer>
                </Card>
            ))}
        </div>
    );
};

export default PostsSection;
