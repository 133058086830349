import React, { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const ChangePassword = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error('Mật khẩu mới và xác nhận không khớp');
            return;
        }
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const { userId } = jwtDecode(token);
                await axios.put(`${apiUrl}/user/${userId}/change-password`,
                    { oldPassword, newPassword },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                toast.success('Đổi mật khẩu thành công');
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } catch (error) {
                toast.error('Lỗi khi đổi mật khẩu');
            }
        }
    };

    return (
        <Form onSubmit={handleChangePassword} className='m-1'>
            <InputGroup className="mb-3">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faLock} />
                </InputGroup.Text>
                <Form.Control
                    type="password"
                    placeholder="Mật khẩu cũ"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    autoFocus
                    onKeyDown={(e) => e.key === 'Enter' && document.getElementById('newPassword').focus()}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faLock} />
                </InputGroup.Text>
                <Form.Control
                    type="password"
                    placeholder="Mật khẩu mới"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && document.getElementById('confirmPassword').focus()}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faLock} />
                </InputGroup.Text>
                <Form.Control
                    type="password"
                    placeholder="Xác nhận mật khẩu mới"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleChangePassword(e)}
                />
            </InputGroup>
            <Button variant="primary" type="submit">Đổi mật khẩu</Button>
        </Form>
    );
};

export default ChangePassword;
