import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faSave } from '@fortawesome/free-solid-svg-icons';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';

const CreateSupplyReceipt = ({ show, handleClose, fetchSupplies, currentRole }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [supplies, setSupplies] = useState([]);
    const [receiptSupplies, setReceiptSupplies] = useState([{ supplyId: '', soLuong: '', giaNhap: '', expirationDate: '' }]);
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const selectRef = useRef(null); // Ref cho ô chọn vật tư
    const inputRefs = useRef([]);
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    useEffect(() => {
        const fetchAllSupplies = async () => {
            try {
                const response = await axios.get(`${apiUrl}/supplies`, {
                    params: { createdBy: userId, currentRole },
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSupplies(response.data.map(supply => ({
                    value: supply._id,
                    label: `${supply.customFields.tenVatTu} | Số lượng: ${supply.customFields.soLuong} | Giá bán: ${supply.customFields.giaBan.toLocaleString("vi-VN")}đ`,
                })));
            } catch (error) {
                console.error('Lỗi khi lấy danh sách vật tư:', error);
            }
        };
        fetchAllSupplies();
    }, [show, apiUrl, currentRole]);

    // Focus vào ô Select đầu tiên khi modal mở lên
    useEffect(() => {
        if (show && selectRef.current) {
            selectRef.current.focus();
        }
    }, [show]);

    const handleAddSupply = () => {
        setReceiptSupplies([...receiptSupplies, { supplyId: '', soLuong: 0, giaNhap: 0, expirationDate: '' }]);
    };

    const handleRemoveSupply = (index) => {
        setReceiptSupplies(receiptSupplies.filter((_, i) => i !== index));
    };

    const handleSelectChange = (selectedOption, index) => {
        setReceiptSupplies(receiptSupplies.map((r, i) =>
            i === index ? { ...r, supplyId: selectedOption.value } : r
        ));
        // Chuyển focus sang ô tiếp theo sau khi chọn xong
        inputRefs.current[index * 4 + 1]?.focus();
    };
    const handleKeyDown = (event, index, field) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let nextIndex;

            if (field === 'soLuong') {
                nextIndex = index * 4 + 2; // Focus vào ô Giá Nhập
            } else if (field === 'giaNhap') {
                nextIndex = index * 4 + 3; // Focus vào ô Hạn Dùng
            } else if (field === 'expirationDate') {
                nextIndex = 'submitButton'; // Focus vào nút Tạo Phiếu Nhập
            }

            if (nextIndex === 'submitButton') {
                document.getElementById('submitButton')?.focus();
            } else if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Bước 1: Tạo phiếu nhập kho
            setLoading(true);
            const response = await axios.post(
                `${apiUrl}/supplyReceipts`,
                {
                    supplies: receiptSupplies,
                    note,
                    createdBy: userId,
                    currentRole,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Tạo phiếu nhập thành công!");

            // Bước 2: Cập nhật số lượng vật tư trong kho
            const stockUpdates = receiptSupplies.map(async (item) => {
                try {
                    // Lấy thông tin vật tư hiện tại
                    const supplyResponse = await axios.get(`${apiUrl}/supplies/${item.supplyId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole, // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    });

                    const currentQuantity = Number(supplyResponse.data.customFields.soLuong || 0);
                    const updatedQuantity = currentQuantity + Number(item.soLuong);

                    // Cập nhật số lượng
                    await axios.put(
                        `${apiUrl}/supplies/${item.supplyId}`,
                        {
                            customFields: { soLuong: updatedQuantity },
                            createdBy: userId,
                            currentRole: currentRole,
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );

                } catch (error) {
                    console.error(`Lỗi khi cập nhật vật tư ${item.supplyId}:`, error.response?.data || error.message);
                }
            });

            // Đợi tất cả các vật tư được cập nhật
            await Promise.all(stockUpdates);

            // Đóng modal, tải lại danh sách vật tư
            handleClose();
            fetchSupplies();
            toast.success('Cập nhật số lượng Vật tư thành công!');
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi tạo phiếu nhập:", error.response?.data || error.message);
            toast.error("Lỗi khi tạo phiếu nhập!");
        }
    };

    const isSubmitDisabled = () => {
        return receiptSupplies.some(item => !item.supplyId || item.soLuong <= 0 || item.giaNhap <= 0);
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faBoxOpen} /> Nhập Vật Tư</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {receiptSupplies.map((item, index) => (
                        <Row key={index} className="mb-3">
                            <Col className='col-10'>
                                <Form.Group>
                                    <InputGroup>
                                        <InputGroupText>Chọn Vật Tư</InputGroupText>
                                        <Select
                                            styles={{
                                                container: (base) => ({
                                                    ...base,
                                                    width: "80%", // Đặt độ rộng container 100%
                                                }),
                                            }}
                                            options={supplies}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                            placeholder="Tìm và chọn vật tư..."
                                            ref={el => inputRefs.current[index * 4] = el}
                                            autoFocus={index === 0}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col><hr />
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Số Lượng</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={item.soLuong}
                                        onChange={(e) =>
                                            setReceiptSupplies(receiptSupplies.map((r, i) =>
                                                i === index ? { ...r, soLuong: Number(e.target.value) } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 3 + 1] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'soLuong')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Giá Nhập</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={item.giaNhap}
                                        onChange={(e) =>
                                            setReceiptSupplies(receiptSupplies.map((r, i) =>
                                                i === index ? { ...r, giaNhap: Number(e.target.value) } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 3 + 2] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'giaNhap')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Hạn Dùng</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={item.expirationDate || ""}
                                        onChange={(e) =>
                                            setReceiptSupplies(receiptSupplies.map((r, i) =>
                                                i === index ? { ...r, expirationDate: e.target.value || "Không rõ" } : r
                                            ))
                                        }

                                        ref={el => inputRefs.current[index * 3 + 3] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'expirationDate')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="d-flex align-items-end col-2">
                                <Button variant="danger" onClick={() => handleRemoveSupply(index)}>
                                    X
                                </Button>
                            </Col>
                        </Row>
                    ))}

                    <Button variant="success" onClick={handleAddSupply} className='m-1'>
                        Thêm +
                    </Button>

                    <Form.Group className="mt-3">
                        <Form.Label>Ghi Chú</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </Form.Group>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <Button id="submitButton" variant="primary" type="submit" className="mt-3" disabled={loading || isSubmitDisabled()}>
                            <FontAwesomeIcon icon={faSave} className='me-1' /> {loading ? "Đang tạo phiếu..." : "Tạo Phiếu Nhập"}
                        </Button>
                    </Modal.Footer>

                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateSupplyReceipt;
