import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faLock, faLockOpen, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

const Login = ({ setIsLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        e.preventDefault();
        try {
            setLoading(true);
            const response = await axios.post(`${apiUrl}/login`, { email, password });
            if (response.data.token) {
                localStorage.setItem('token', response.data.token); // Lưu token vào localStorage
                setIsLoggedIn(true); // Cập nhật trạng thái đăng nhập
                toast.success('Đăng nhập thành công');
                setLoading(false);
                navigate('/'); // Điều hướng về trang chủ sau khi đăng nhập
            }
        } catch (error) {
            toast.error('Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin.');
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-form-container">
            <div className="login-form">
                <center><h2><FontAwesomeIcon icon={faUserShield} /> Đăng Nhập</h2></center>
                <Form onSubmit={handleLogin}>
                    <Form.Group controlId="formEmail">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <br />
                    <Form.Group controlId="formPassword">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <br />
                    <center>
                        <Button variant="primary" type="submit" disabled={loading}>
                            <FontAwesomeIcon className="me-2" icon={loading ? faLockOpen : faLock} /> {loading ? 'Đang đăng nhập...' : 'Đăng nhập'}
                        </Button>
                        <hr />
                        Bạn chưa có tài khoản?<br />
                        <a href='/register'>Đăng ký</a> và trải nghiệm ngay!
                    </center>
                </Form>
            </div>
        </div>
    );
};

export default Login;
