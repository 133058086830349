import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBirthdayCake, faPhone, faMapMarkerAlt, faHeart, faVenusMars, faCamera, faSave, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { Form, FormGroup, InputGroup, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const PersonalInfo = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [userData, setUserData] = useState({
        name: '',
        birthday: '',
        gender: '',
        phone: '',
        address: '',
        hobbies: '',
        profilePicture: '',
    });
    const [loading, setLoading] = useState(false); // Trạng thái chờ tải dữ liệu

    useEffect(() => {
        const fetchPersonalInfo = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const { userId } = jwtDecode(token);
                    const response = await axios.get(`${apiUrl}/user/${userId}`);

                    const data = response.data.user;
                    setUserData({
                        name: data.name || '',
                        birthday: data.birthday ? data.birthday.slice(0, 10) : '', // Định dạng ngày tháng
                        gender: data.gender || '',
                        phone: data.phone || '',
                        address: data.address || '',
                        hobbies: data.hobbies || '',
                        profilePicture: data.profilePicture || '',
                    });
                } catch (error) {
                    console.error('Error fetching personal info:', error);
                    toast.error('Không thể tải thông tin cá nhân');
                }
            }
        };
        fetchPersonalInfo();
    }, [apiUrl]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (token) {
            try {
                const { userId } = jwtDecode(token);
                await axios.put(`${apiUrl}/user/${userId}/personal-info`, userData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                toast.success('Thông tin cá nhân đã được cập nhật!');
            } catch (error) {
                toast.error('Lỗi khi lưu thông tin cá nhân!');
            }
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setUserData((prevData) => ({ ...prevData, profilePicture: reader.result }));
            reader.readAsDataURL(file);
        }
    };

    if (loading) {
        return (
            <div className="text-center mt-4">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Đang tải...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Form onSubmit={handleSave} className="m-1">
            <h4>Thông tin cá nhân</h4>
            <FontAwesomeIcon icon={faLightbulb} className="me-2 text-warning" /> Những thông tin này sẽ hiển thị trên trang cá nhân công khai, in trên toa thuốc, phiếu thu...<hr />
            <div className="profile-picture-container">
                {userData.profilePicture ? (
                    <img src={userData.profilePicture} alt="Profile" className="profile-picture" />
                ) : (
                    <div className="placeholder-picture">Hình đại diện</div>
                )}
                <input
                    type="file"
                    id="file-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                />
                <label htmlFor="file-upload" className="icon camera-icon">
                    <FontAwesomeIcon icon={faCamera} />
                </label>
            </div>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faUser} />Họ tên</InputGroup.Text>
                    <Form.Control
                        placeholder="Tên đầy đủ"
                        name="name"
                        value={userData.name}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <Form.Group controlId="formGender" className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faVenusMars} />Giới tính</InputGroup.Text>
                    <Form.Control
                        as="select"
                        name="gender"
                        value={userData.gender}
                        onChange={handleInputChange}
                    >
                        <option value="Nam">Nam</option>
                        <option value="Nữ">Nữ</option>
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faBirthdayCake} />Sinh nhật</InputGroup.Text>
                    <Form.Control
                        type="date"
                        name="birthday"
                        value={userData.birthday}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faPhone} />Điện thoại</InputGroup.Text>
                    <Form.Control
                        placeholder="Số điện thoại"
                        name="phone"
                        value={userData.phone}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faMapMarkerAlt} />Địa chỉ</InputGroup.Text>
                    <Form.Control
                        placeholder="Địa chỉ"
                        name="address"
                        value={userData.address}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon className="m-1" icon={faHeart} />Sở thích</InputGroup.Text>
                    <Form.Control
                        placeholder="Sở thích"
                        name="hobbies"
                        value={userData.hobbies}
                        onChange={handleInputChange}
                    />
                </InputGroup>
            </FormGroup>
            <Button type="submit">
                <FontAwesomeIcon className="me-2" icon={faSave} /> Lưu Thay Đổi
            </Button>
        </Form>
    );
};

export default PersonalInfo;
