import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import Loading from './Loading';
import { RoleContext } from './RoleContext';

const SupplyReceiptList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { currentRole } = useContext(RoleContext)
    const [receipts, setReceipts] = useState([]);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    useEffect(() => {

        const fetchReceipts = async () => {
            try {
                setLoading(true);
                const userResponse = await axios.get(`${apiUrl}/user`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        currentRole,
                        createdBy: userId,
                    },
                });

                // Bước 1: Lấy danh sách phiếu nhập (supplyReceipts)
                const receiptsResponse = await axios.get(`${apiUrl}/supplyReceipts`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        currentRole,
                        createdBy: userId,
                    },
                });

                // Bước 2: Lấy danh sách vật tư (supplies)
                const suppliesResponse = await axios.get(`${apiUrl}/supplies`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        currentRole,
                        createdBy: userId,
                    },
                });

                // Tạo một Map để tra cứu tên vật tư
                const suppliesMap = new Map(
                    suppliesResponse.data.map((supply) => [
                        supply._id, // Key: supplyId
                        supply.customFields.tenVatTu || "Tên vật tư không có",
                    ])
                );

                const userMap = new Map(
                    userResponse.data.map((user) => [
                        user._id,
                        user.name,
                    ])
                );
                // Bước 3: Gắn tên vật tư vào danh sách receipts
                const enrichedReceipts = receiptsResponse.data.map((receipt) => ({
                    ...receipt,
                    supplies: receipt.supplies.map((supply) => ({
                        ...supply,
                        tenVatTu: suppliesMap.get(supply.supplyId._id) || "Không tìm thấy",
                    })),
                    userName: userMap.get(receipt.createdBy) || "Không rõ",
                }));

                setReceipts(enrichedReceipts);
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }


        };

        fetchReceipts();
    }, [userId]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const openModal = (receipt) => {
        setSelectedReceipt(receipt);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="m-2">
            <h2><FontAwesomeIcon icon={faReceipt} /> Danh sách Phiếu Nhập Vật Tư</h2>
            {loading ? (<Loading />) : (
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Mã Phiếu</th>
                            <th>Ngày Nhập</th>
                            <th>Tổng Giá Trị</th>
                            <th>Ghi Chú</th>
                            <th>Người nhập</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receipts.map((receipt, index) => (
                            <tr key={receipt._id} onClick={() => openModal(receipt)} style={{ cursor: 'pointer' }}>
                                <td>{index + 1}</td>
                                <td>{receipt.receiptCode.slice(0, 11)}</td>
                                <td>{formatDate(receipt.createdAt)}</td>
                                <td>{receipt.totalPrice.toLocaleString()} VND</td>
                                <td>{receipt.note}</td>
                                <td>{receipt.userName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Modal */}
            <Modal
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                    content: {
                        position: 'relative',
                        inset: 'unset',
                        width: '1080px',
                        maxWidth: '90%',
                        padding: '20px',
                        borderRadius: '8px',
                    },
                }}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Chi tiết phiếu nhập"
                ariaHideApp={false}
            >
                <h2><FontAwesomeIcon icon={faReceipt} /> Chi tiết Phiếu Nhập</h2>
                {selectedReceipt && (
                    <div>
                        <p><strong>Mã Phiếu:</strong> <b>{selectedReceipt.receiptCode.slice(0, 11)}</b></p>
                        <p><strong>Ngày Nhập:</strong> {formatDate(selectedReceipt.createdAt)}</p>
                        <p><strong>Tổng Giá Trị:</strong> <b>{selectedReceipt.totalPrice.toLocaleString()}</b> VND</p>
                        <p><strong>Người nhập:</strong> {selectedReceipt.userName}</p>
                        <p><strong>Ghi Chú:</strong> {selectedReceipt.note}</p>
                        <hr />
                        <h4>Danh Sách Vật Tư</h4>
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tên Vật Tư</th>
                                    <th>Số Lượng</th>
                                    <th>Giá Nhập</th>
                                    <th>Hạn Dùng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedReceipt.supplies.map((supply, index) => (
                                    <tr key={supply.supplyId._id}>
                                        <td>{index + 1}</td>
                                        <td>{supply.tenVatTu || 'Chưa có tên'}</td>
                                        <td>{supply.soLuong}</td>
                                        <td>{supply.giaNhap.toLocaleString()} VND</td>
                                        <td>{supply.expirationDate ? formatDate(supply.expirationDate) : "Không rõ"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <Button variant="secondary" onClick={closeModal}>Đóng</Button>
            </Modal>
        </div>
    );
};

export default SupplyReceiptList;
