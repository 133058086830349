import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, InputGroup, ListGroup } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faHandHoldingMedical, faSave } from "@fortawesome/free-solid-svg-icons";

const ConsultationModal = ({
    show,
    handleClose,
    fetchConsultations,
    selectedConsultation, // Tư vấn được chọn để chỉnh sửa
    isEditMode,
    permissions,
    currentRole,
    initialCustomerId, // ID khách hàng từ DS Khách Hàng
}) => {
    const [consultationData, setConsultationData] = useState({ customFields: {} });
    const [formConfig, setFormConfig] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [triggerCalculation, setTriggerCalculation] = useState(false);
    const fieldRefs = useRef([]);
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    const userId = decoded.userId;
    const apiUrl = process.env.REACT_APP_API_URL;
    const hasPermission = (permission) => permissions?.[permission] || false;
    // Hàm lấy danh sách khách hàng
    const fetchCustomerOptions = async () => {
        try {
            const response = await axios.get(`${apiUrl}/customers`, {
                params: { createdBy: userId, currentRole, format: "simple" },
                headers: { Authorization: `Bearer ${token}` },
            });

            // Map dữ liệu trả về thành options
            return response.data.map((item) => ({
                value: item.value,
                label: item.label,
            }));
        } catch (error) {
            console.error("Lỗi khi lấy thông tin khách hàng:", error);
            return [];
        }
    };

    useEffect(() => {
        if (initialCustomerId) {
            // Thiết lập giá trị mặc định cho customFields.hoVaTen
            setConsultationData((prev) => ({
                ...prev,
                customFields: {
                    ...prev.customFields,
                    hoVaTen: initialCustomerId, // ID khách hàng từ DS Khách Hàng
                },
            }));
        }
    }, [initialCustomerId]);

    const fetchServiceOptions = async () => {
        try {
            const response = await axios.get(`${apiUrl}/services`, {
                params: { createdBy: userId, currentRole, format: "simple" },
                headers: { Authorization: `Bearer ${token}` },
            });

            // Map dữ liệu trả về thành options
            return response.data.map((item) => ({
                value: item.value,
                label: item.label,
            }));
        } catch (error) {
            console.error("Lỗi khi lấy thông tin dịch vụ:", error);
            return [];
        }
    };
    useEffect(() => {
        if (show) {
            fetchFormConfig();
            if (isEditMode && selectedConsultation) {
                setConsultationData({
                    customFields: selectedConsultation.customFields || {}, // Sao chép customFields
                });
            } else {
                resetForm();
            }
            // Auto-focus vào trường đầu tiên
            setTimeout(() => {
                if (fieldRefs.current[0]) {
                    fieldRefs.current[0].focus();
                }
            }, 200); // Đợi modal render xong
        }
    }, [show, isEditMode, selectedConsultation]);

    const fetchFormConfig = async () => {
        try {
            const response = await axios.get(`${apiUrl}/consultation-form-config/${currentRole}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const config = response.data || [];
            setFormConfig(config);

            // Kiểm tra nếu form có trường `hoVaTen`, thì bổ sung options
            if (config.some((field) => field.fieldName === "hoVaTen")) {
                const customerOptions = await fetchCustomerOptions();

                setFormConfig((prev) =>
                    prev.map((field) =>
                        field.fieldName === "hoVaTen"
                            ? { ...field, options: customerOptions }
                            : field
                    )
                );
            }
            if (config.some((field) => field.fieldName === "tenDichVu")) {
                const customerOptions = await fetchServiceOptions();

                setFormConfig((prev) =>
                    prev.map((field) =>
                        field.fieldName === "tenDichVu"
                            ? { ...field, options: customerOptions }
                            : field
                    )
                );
            }
        } catch (error) {
            console.error("Lỗi khi tải cấu hình form tư vấn:", error);
        }
    };

    useEffect(() => {
        fetchFormConfig();
    }, []);

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (fieldRefs.current[index + 1]) {
                fieldRefs.current[index + 1].focus();
            } else {
                handleSave();
            }
        }
    };

    const handleBlur = (field) => {
        if (field.isRequired && !consultationData.customFields[field.fieldName]) {
            setErrors((prev) => ({
                ...prev,
                [field.fieldName]: `${field.fieldLabel} là bắt buộc.`,
            }));
        } else {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[field.fieldName];
                return newErrors;
            });
        }
    };


    const handleCustomFieldChange = (fieldName, value) => {
        setConsultationData((prev) => {
            const updatedData = {
                ...prev,
                customFields: {
                    ...prev.customFields,
                    [fieldName]: value, // Ghi đè giá trị mới
                },
            };

            // Nếu trường thay đổi là "tenDichVu", tính lại totalPrice
            if (fieldName === "tenDichVu") {
                const totalPrice = value.reduce((total, service) => {
                    const price = parseFloat(
                        service.label.split("-")[1]?.replace(/[^0-9.-]+/g, "") || "0"
                    );
                    return total + price;
                }, 0);

                updatedData.customFields.totalPrice = totalPrice;
            }

            return updatedData;
        });
    };

    const handleCalculateField = (field, customFields) => {
        try {
            const formulaFunction = new Function(...field.dependencies, `return ${field.formula};`);
            const dependencyValues = field.dependencies.map((dep) => {
                const depValue = customFields[dep];
                if (!depValue) throw new Error(`Trường ${dep} chưa có giá trị.`);

                // Xử lý giá trị ngày
                if (field.fieldType === "date" || depValue.includes("-")) {
                    return new Date(depValue).getTime(); // Chuyển ngày về timestamp
                }
                return parseFloat(depValue); // Xử lý các giá trị số
            });

            return formulaFunction(...dependencyValues);
        } catch (error) {
            console.error(`Lỗi khi tính toán trường ${field.fieldName}:`, error);
            return "Lỗi công thức";
        }
    };

    useEffect(() => {
        if (!triggerCalculation) return; // Chỉ chạy khi trigger được bật

        formConfig.forEach((field) => {
            if (
                field.fieldType === "calculated" &&
                field.formula &&
                Array.isArray(field.dependencies)
            ) {
                try {
                    const dependencyValues = field.dependencies.map((dep) => {
                        const depValue = consultationData.customFields[dep];
                        if (!depValue) throw new Error(`Trường ${dep} chưa có giá trị.`);

                        // Kiểm tra và xử lý giá trị ngày
                        if (depValue.includes("-")) {
                            return new Date(depValue).getTime(); // Chuyển ngày về timestamp
                        }
                        return parseFloat(depValue);
                    });

                    const formulaFunction = new Function(
                        ...field.dependencies,
                        `return ${field.formula};`
                    );

                    const calculatedValue = formulaFunction(...dependencyValues);

                    setConsultationData((prev) => ({
                        ...prev,
                        customFields: {
                            ...prev.customFields,
                            [field.fieldName]: isNaN(calculatedValue)
                                ? "Lỗi công thức"
                                : calculatedValue,
                        },
                    }));
                } catch (error) {
                    console.error(`Lỗi khi tính toán trường ${field.fieldName}:`, error.message);
                    setConsultationData((prev) => ({
                        ...prev,
                        customFields: {
                            ...prev.customFields,
                            [field.fieldName]: "Chưa nhập đủ dữ liệu",
                        },
                    }));
                }
            }
        });

        setTriggerCalculation(false); // Reset trigger sau khi xử lý xong
    }, [triggerCalculation]);

    // Gọi hàm khi cần kích hoạt lại tính toán
    const handleDependencyChange = () => {
        setTriggerCalculation(true);
    };

    useEffect(() => {
        if (consultationData.customFields.tenDichVu) {
            const totalPrice = consultationData.customFields.tenDichVu.reduce((total, service) => {
                const price = parseFloat(
                    service.label.split("-")[1]?.replace(/[^0-9.-]+/g, "") || "0"
                );
                return total + price;
            }, 0);

            setConsultationData((prev) => ({
                ...prev,
                totalPrice, // Gán tổng tiền vào consultationData
            }));
        }
    }, [consultationData.customFields.tenDichVu]); // Chạy lại mỗi khi tenDichVu thay đổi

    const handleSave = async () => {
        if (!isEditMode && !hasPermission("canAdd")) {
            toast.error("Bạn không có quyền thêm tư vấn mới!");
            return;
        }
        if (!isEditMode && !hasPermission("canEdit")) {
            toast.error("Bạn không có quyền sửa tư vấn!");
            return;
        }
        // Validate dữ liệu
        const newErrors = {};
        formConfig.forEach((field) => {
            if (field.isRequired && !consultationData.customFields[field.fieldName]) {
                newErrors[field.fieldName] = `${field.fieldLabel} là bắt buộc.`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error("Vui lòng điền đầy đủ các trường bắt buộc.");
            return;
        }

        try {
            setLoading(true);
            const payload = {
                ...consultationData,
                createdBy: userId,
                currentRole: currentRole,
                totalPrice: consultationData.totalPrice, // Gửi tổng tiền
                customFields: {
                    ...consultationData.customFields,
                    lieuTrinh: Number(consultationData.customFields.lieuTrinh) || 0, // Đảm bảo lieuTrinh là số
                },
            };

            if (isEditMode) {
                await axios.put(
                    `${apiUrl}/consultations/${selectedConsultation._id}`,
                    payload,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                toast.success("Chỉnh sửa Tư Vấn thành công!");
            } else {
                await axios.post(`
                    ${apiUrl}/consultations`, payload,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                toast.success("Thêm Tư Vấn thành công!");
            }

            fetchConsultations();
            handleClose();
        } catch (error) {
            console.error("Error saving consultation:", error.response?.data || error.message);
            toast.error("Đã xảy ra lỗi. Vui lòng thử lại.");
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setConsultationData({ customFields: {} });
        setErrors({});
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? "Chỉnh Sửa Tư Vấn" : "Thêm Mới Tư Vấn"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Render các trường động */}
                    {formConfig.map((field, index) => (
                        <Form.Group key={field.fieldName} className="mb-3" style={{ width: "100%" }}>
                            <InputGroup>
                                <InputGroup.Text>{field.fieldLabel}</InputGroup.Text>
                                {field.fieldName === "totalPrice" ? (
                                    // Trường totalPrice chỉ đọc
                                    <Form.Control
                                        type="text"
                                        value={
                                            (consultationData.customFields.tenDichVu?.length > 0
                                                ? consultationData.customFields.tenDichVu.reduce((total, service) => {
                                                    const price = parseFloat(
                                                        service.label.split("-")[1]?.replace(/[^0-9.-]+/g, "") || "0"
                                                    );
                                                    return total + price;
                                                }, 0)
                                                : 0
                                            ).toLocaleString("vi-VN") + " VNĐ"
                                        }
                                        disabled
                                    />
                                ) : field.fieldType === "apiSelect" ? (
                                    field.fieldName === "tenDichVu" ? (
                                        // Xử lý trường dịch vụ
                                        <div style={{ width: '90%' }}>
                                            <h6 className="ms-2">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />Dịch Vụ Đã Chọn
                                            </h6>
                                            <ListGroup>
                                                {consultationData.customFields.tenDichVu?.map((service) => (
                                                    <ListGroup.Item
                                                        key={service.value}
                                                        className="d-flex justify-content-between align-items-center ms-2"
                                                    >
                                                        <Form.Check
                                                            className="ms-2"
                                                            onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                            isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                                            type="checkbox"
                                                            checked={true}
                                                            label={service.label}
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                if (!isChecked) {
                                                                    const updatedServices =
                                                                        consultationData.customFields.tenDichVu.filter(
                                                                            (s) => s.value !== service.value
                                                                        );
                                                                    handleCustomFieldChange(
                                                                        "tenDichVu",
                                                                        updatedServices
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>

                                            <h6 className="ms-2"><FontAwesomeIcon icon={faHandHoldingMedical} className="me-2" /> Danh Sách Dịch Vụ</h6>
                                            <Select
                                                className="ms-2"
                                                isMulti
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        width: "90%", // Đảm bảo chiều rộng container
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        minHeight: "38px", // Đặt chiều cao tối thiểu
                                                        overflow: "visible", // Đảm bảo nội dung không bị cắt
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999, // Giúp dropdown hiển thị trên các thành phần khác
                                                    }),
                                                    menuList: (provided) => ({
                                                        ...provided,
                                                        maxHeight: "200px", // Đặt chiều cao tối đa cho danh sách
                                                    }),
                                                    option: (provided) => ({
                                                        ...provided,
                                                        whiteSpace: "nowrap", // Không xuống dòng
                                                        overflow: "hidden", // Ẩn nội dung tràn
                                                        textOverflow: "ellipsis", // Hiển thị dấu "..." khi nội dung quá dài
                                                    }),
                                                }}
                                                options={field.options.filter((option) => {
                                                    const selectedValues = consultationData.customFields.tenDichVu || [];
                                                    if (!Array.isArray(selectedValues)) return true;
                                                    return !selectedValues.some((s) => s?.value === option.value);
                                                })}
                                                value={[]}
                                                onChange={(selected) => {
                                                    if (!selected) return; // Không làm gì nếu không có lựa chọn
                                                    const newSelection = selected[selected.length - 1]; // Lấy mục mới được chọn
                                                    handleCustomFieldChange("tenDichVu", [
                                                        ...(consultationData.customFields.tenDichVu || []),
                                                        newSelection,
                                                    ]);
                                                }}
                                                placeholder="Tìm kiếm và chọn dịch vụ"
                                                isClearable
                                                isSearchable
                                            />

                                        </div>
                                    ) : (
                                        // Các trường apiSelect khác (ví dụ: Chọn Khách Hàng)
                                        <Select
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: "80%",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: "38px",
                                                }),
                                            }}
                                            options={field.options} // Danh sách khách hàng
                                            value={
                                                consultationData.customFields.hoVaTen // Giá trị hiện tại { value, label }
                                                    ? field.options.find(
                                                        (opt) =>
                                                            opt.value === consultationData.customFields.hoVaTen
                                                    )
                                                    : null
                                            }
                                            onChange={(selected) =>
                                                handleCustomFieldChange(
                                                    field.fieldName,
                                                    selected?.value || ""
                                                )
                                            }
                                            ref={(el) => (fieldRefs.current[index] = el)}
                                            onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                            isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            placeholder={`Chọn ${field.fieldLabel}`}
                                            isClearable
                                            isSearchable
                                        />
                                    )
                                ) : field.fieldType === "number" ? (
                                    field.fieldName === "totalPrice" ? (
                                        <span style={{ color: "red", fontWeight: "bold" }}>
                                            {new Intl.NumberFormat("vi-VN", {
                                                style: "currency",
                                                currency: "VND",
                                            }).format(consultationData.customFields[field.fieldName] || 0)}
                                        </span>
                                    ) : (
                                        <Form.Control
                                            type="number"
                                            value={consultationData.customFields[field.fieldName] || ""}
                                            onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                            isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            onChange={(e) =>
                                                handleCustomFieldChange(field.fieldName, e.target.value)
                                            }
                                        />
                                    )
                                ) : null
                                }

                                {field.fieldType === "text" && (
                                    <Form.Control
                                        type="text"
                                        ref={(el) => (fieldRefs.current[index] = el)} // Gắn ref
                                        value={consultationData.customFields[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleCustomFieldChange(field.fieldName, e.target.value)
                                        }
                                        onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        onKeyDown={(e) => handleKeyDown(e, index)} // Nhảy focus
                                    />
                                )}
                                {field.fieldType === "textinline" && (
                                    <div className="d-flex flex-wrap">
                                        {field.fields.map((inlineField) => (
                                            <Form.Group key={inlineField.fieldName} className="mb-3 me-3">
                                                <Form.Label>{inlineField.fieldLabel}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={consultationData.customFields[inlineField.fieldName] || ""}
                                                    onChange={(e) =>
                                                        handleCustomFieldChange(inlineField.fieldName, e.target.value)
                                                    }
                                                    onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                    isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                                    placeholder={`Nhập ${inlineField.fieldLabel}`}
                                                />
                                            </Form.Group>
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "numberinline" && (
                                    <div className="d-flex flex-wrap">
                                        {field.fields.map((inlineField) => (
                                            <Form.Group key={inlineField.fieldName} className="mb-3 me-3">
                                                <Form.Label>{inlineField.fieldLabel}</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={consultationData.customFields[inlineField.fieldName] || ""}
                                                    onChange={(e) =>
                                                        handleCustomFieldChange(inlineField.fieldName, e.target.value)
                                                    }
                                                    onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                    isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                                    placeholder={`Nhập ${inlineField.fieldLabel}`}
                                                />
                                            </Form.Group>
                                        ))}
                                    </div>
                                )}

                                {field.fieldType === "date" && (
                                    <Form.Control
                                        type="date"
                                        ref={(el) => (fieldRefs.current[index] = el)}
                                        value={
                                            consultationData.customFields[field.fieldName]
                                                ? new Date(consultationData.customFields[field.fieldName]).toISOString().split("T")[0]
                                                : ""
                                        }
                                        onChange={(e) => handleCustomFieldChange(field.fieldName, e.target.value)}
                                        onBlur={() => {
                                            handleBlur(field);
                                            handleDependencyChange();
                                        }} // Kiểm tra khi mất focus
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                )}
                                {field.fieldType === "dateinline" && (
                                    <div className="d-flex flex-wrap">
                                        {field.fields.map((inlineField) => (
                                            <Form.Group key={inlineField.fieldName} className="mb-3 me-3">
                                                <Form.Label>{inlineField.fieldLabel}</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={consultationData.customFields[inlineField.fieldName] || ""}
                                                    onChange={(e) =>
                                                        handleCustomFieldChange(inlineField.fieldName, e.target.value)
                                                    }
                                                    placeholder={`Chọn ${inlineField.fieldLabel}`}
                                                />
                                            </Form.Group>
                                        ))}
                                    </div>
                                )}

                                {field.fieldType === "calculated" && (
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={consultationData.customFields[field.fieldName] || "Chưa có dữ liệu"} // Hiển thị kết quả tính toán hoặc thông báo nếu chưa đủ dữ liệu
                                        onFocus={(e) => handleCalculateField(field, e)} // Tính lại giá trị khi focus nếu cần
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi nếu có
                                        placeholder={`Tự động tính toán: ${field.fieldLabel}`} // Placeholder hướng dẫn
                                    />
                                )}

                                {field.fieldType === "select" && (
                                    <>
                                        {field.options.length <= 5 ? (
                                            <Form.Select
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        width: "80%",
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        minHeight: "38px",
                                                    }),
                                                }}
                                                ref={(el) => (fieldRefs.current[index] = el)}
                                                value={consultationData.customFields[field.fieldName] || ""}
                                                onChange={(e) =>
                                                    handleCustomFieldChange(field.fieldName, e.target.value)
                                                }
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            >
                                                <option value="">-- Chọn {field.fieldLabel} --</option>
                                                {field.options.map((option, idx) => (
                                                    <option key={idx} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        ) : (
                                            <Select
                                                options={field.options.map((opt) => ({
                                                    label: opt,
                                                    value: opt,
                                                }))}
                                                value={
                                                    consultationData.customFields[field.fieldName]
                                                        ? {
                                                            label: consultationData.customFields[field.fieldName],
                                                            value: consultationData.customFields[field.fieldName],
                                                        }
                                                        : null
                                                }
                                                onChange={(selected) =>
                                                    handleCustomFieldChange(
                                                        field.fieldName,
                                                        selected?.value || ""
                                                    )
                                                }
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                                placeholder={`Chọn ${field.fieldLabel}`}
                                                isClearable
                                                isSearchable
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        width: "80%",
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        minHeight: "38px",
                                                    }),
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                                {field.fieldType === "checkbox" && (
                                    <div>
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="checkbox"
                                                label={option}
                                                value={option}
                                                checked={
                                                    consultationData.customFields[field.fieldName]?.includes(option) ||
                                                    false
                                                }
                                                onChange={(e) => {
                                                    const selectedOptions =
                                                        consultationData.customFields[field.fieldName] || [];
                                                    const updatedOptions = e.target.checked
                                                        ? [...selectedOptions, option]
                                                        : selectedOptions.filter((opt) => opt !== option);

                                                    setConsultationData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: updatedOptions,
                                                        },
                                                    }));
                                                }}
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            />
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "checkboxinline" && (
                                    <div className="d-flex align-items-center flex-wrap">
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="checkbox"
                                                inline // Đặt inline để các checkbox nằm cùng dòng
                                                label={option}
                                                value={option}
                                                checked={
                                                    consultationData.customFields[field.fieldName]?.includes(option) || false
                                                }
                                                onChange={(e) => {
                                                    const selectedOptions = consultationData.customFields[field.fieldName] || [];
                                                    const updatedOptions = e.target.checked
                                                        ? [...selectedOptions, option]
                                                        : selectedOptions.filter((opt) => opt !== option);

                                                    setConsultationData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: updatedOptions,
                                                        },
                                                    }));
                                                }}
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                                className="me-3" // Thêm khoảng cách giữa các checkbox
                                            />
                                        ))}
                                    </div>
                                )}

                                {field.fieldType === "radioinline" && (
                                    <div className="d-flex align-items-center">
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="radio"
                                                inline
                                                label={option}
                                                name={field.fieldName}
                                                value={option}
                                                checked={consultationData.customFields[field.fieldName] === option}
                                                onChange={(e) => {
                                                    const selectedOption = e.target.value;
                                                    setConsultationData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: selectedOption,
                                                        },
                                                    }));
                                                }}
                                                className="ms-2"
                                            />
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "radio" && (
                                    <div>
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="radio"
                                                label={option}
                                                name={field.fieldName} // Đảm bảo tất cả radio cùng nhóm có cùng name
                                                value={option}
                                                checked={consultationData.customFields[field.fieldName] === option} // So sánh với giá trị đã chọn
                                                onChange={(e) => {
                                                    const selectedOption = e.target.value; // Lấy giá trị được chọn
                                                    setConsultationData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: selectedOption, // Gán giá trị radio đã chọn
                                                        },
                                                    }));
                                                }}
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi nếu có
                                            />
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "file" && (
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    setConsultationData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: reader.result, // Lưu file dưới dạng base64
                                                        },
                                                    }));
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                )}
                                {field.fieldType === "textarea" && (
                                    <Form.Control
                                        as="textarea" // Đặt là textarea
                                        ref={(el) => (fieldRefs.current[index] = el)} // Gắn ref
                                        rows={3} // Số dòng hiển thị mặc định
                                        value={consultationData.customFields[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleCustomFieldChange(field.fieldName, e.target.value)
                                        }
                                        onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        onKeyDown={(e) => handleKeyDown(e, index)} // Nhảy focus
                                    />
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {errors[field.fieldName]} {/* Hiển thị lỗi */}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    ))}
                </Form>

            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleSave}
                    disabled={loading}>
                    {isEditMode ? (<>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-1" />{loading ? ('Đang cập nhật...') : ('Cập nhật')}</>) : (<>
                            <FontAwesomeIcon icon={faSave} className="me-1" />{loading ? ('Đang lưu...') : ('Lưu')}</>)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConsultationModal;
