import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProfileTabs = ({ activeTab, setActiveTab }) => {
    const [isLoggedIn] = useState(!!localStorage.getItem('token'));
    return (
        <div className="profile-tabs">
            <div
                className={`tab ${activeTab === 'posts' ? 'active' : ''}`}
                onClick={() => setActiveTab('posts')}
            >
                Bài Viết
            </div>
            <div
                className={`tab ${activeTab === 'info' ? 'active' : ''}`}
                onClick={() => setActiveTab('info')}
            >
                Thông Tin
            </div>
            <div
                className={`tab ${activeTab === 'timeline' ? 'active' : ''}`}
                onClick={() => setActiveTab('timeline')}
            >
                Hoạt Động
            </div>
            {isLoggedIn ?
                <div
                    className={`tab link ${activeTab === 'settings' ? 'active' : ''}`}
                    onClick={() => setActiveTab('settings')}
                >
                    <Link as={Link} to={'/settings/account'}>Cài Đặt</Link>
                </div> : ''}
        </div>
    );
};

export default ProfileTabs;
