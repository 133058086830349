import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { to_vietnamese } from './numberToWords';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { RoleContext } from './RoleContext';

const AddReceiptModal = ({ show, handleClose, onReFresh }) => {
    const { currentRole } = useContext(RoleContext);
    const [consultations, setConsultations] = useState([]);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerName, setCustomerName] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [services, setServices] = useState('');
    const [amount, setAmount] = useState(0);
    const [amountInWords, setAmountInWords] = useState('');
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [promoteAmount, setPromoteAmount] = useState(0);
    const apiUrl = process.env.REACT_APP_API_URL;
    const inputRefs = useRef([]);

    useEffect(() => {
        if (show) {
            // Auto-focus vào trường đầu tiên
            setTimeout(() => {
                if (inputRefs.current[0]) {
                    inputRefs.current[0].focus();
                }
            }, 200); // Đợi modal render xong
        }
    }, [show]);

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const fetchConsultations = async () => {
        try {
            // Fetch tư vấn
            const consultationsResponse = await axios.get(`${apiUrl}/consultations?createdBy=${currentRole}`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    currentRole, // Chủ sở hữu dữ liệu
                    createdBy: userId, // Người thực hiện hành động
                },
            });

            // Fetch khách hàng
            const customersResponse = await axios.get(`${apiUrl}/customers`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    currentRole, // Chủ sở hữu dữ liệu
                    createdBy: userId, // Người thực hiện hành động
                },
            });

            // Tạo Map từ khách hàng
            const customerMap = new Map(
                customersResponse.data.map((customer) => [
                    customer._id, // ID khách hàng
                    customer.customFields, // Thông tin chi tiết khách hàng
                ])
            );

            // Kết hợp tư vấn với thông tin khách hàng
            const consultationOptions = consultationsResponse.data.map((consultation) => {
                const customerInfo = customerMap.get(consultation.customFields.hoVaTen) || {
                    hoVaTen: "Không xác định",
                    diaChi: "Không có địa chỉ",
                };

                return {
                    value: consultation._id,
                    label: `${customerInfo.hoVaTen} - ${new Date(
                        consultation.customFields.ngayThucHien
                    ).toLocaleDateString()} - ${consultation.customFields.totalPrice.toLocaleString()} VNĐ`,
                    data: {
                        ...consultation,
                        customerName: customerInfo.hoVaTen,
                        customerAddress: customerInfo.diaChi,
                    },
                };
            });

            setConsultations(consultationOptions);
        } catch (error) {
            console.error("Lỗi khi lấy danh sách tư vấn:", error);
        }
    };

    useEffect(() => {
        fetchConsultations();
    }, [apiUrl, userId, currentRole]);

    const handleConsultationChange = (selectedOption) => {
        if (selectedOption) {
            const consultation = selectedOption.data; // Dữ liệu tư vấn đã chọn (bao gồm thông tin khách hàng)
            setSelectedConsultation(consultation);

            // Cập nhật thông tin khách hàng và dịch vụ từ dữ liệu đã được chuẩn bị
            setCustomerName(consultation.customerName || "Không xác định");
            setCustomerAddress(consultation.customerAddress || "Không có địa chỉ");
            setServices(
                consultation.customFields.tenDichVu
                    .map((d) => d.label.split('-')[0].trim()) // Lấy nội dung trước dấu '-' và loại bỏ khoảng trắng thừa
                    .join(", ")
            );
            setTotalPrice(consultation.customFields.totalPrice || 0);
            setAmountInWords(to_vietnamese(consultation.customFields.totalPrice || 0));
        }
    };


    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus();
            } else {
                handleSubmit();
            }
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedConsultation) {
            toast.error('Vui lòng chọn tư vấn và khách hàng.');
            return;
        }
        try {
            await axios.post(
                `${apiUrl}/receipts`,
                {
                    consultationId: selectedConsultation?._id || null,
                    customerId: selectedConsultation.customFields?.hoVaTen || null,
                    customerName, // Lấy từ state
                    address: customerAddress, // Lấy từ state
                    services: services || "Không có dịch vụ",
                    amount: amount || totalPrice || 0,
                    amountInWords: amountInWords
                        ? amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1)
                        : "Không xác định",
                    notes: notes || "",
                    paymentMethod: paymentMethod || "Không xác định",
                    totalPrice: totalPrice || 0,
                    remainingAmount: remainingAmount || 0,
                    promoteAmount: promoteAmount || 0,
                    createdBy: userId,
                    currentRole
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            toast.success('Tạo phiếu thu thành công!');
            handleClose();
            resetForm();
            onReFresh();
        } catch (error) {
            toast.error('Lỗi khi tạo phiếu thu!');
            console.error('Lỗi khi tạo phiếu thu:', error);
        }
    };

    const resetForm = () => {
        setSelectedConsultation(null);
        setSelectedCustomer(null);
        setServices('');
        setAmount(0);
        setAmountInWords('');
        setNotes('');
        setPaymentMethod('');
        setTotalPrice(0);
        setRemainingAmount(0);
        setPromoteAmount(0);
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} /> Thêm Phiếu Thu
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Chọn Tư Vấn</InputGroup.Text>
                            <Select
                                options={consultations}
                                onChange={handleConsultationChange}
                                placeholder="Tìm kiếm tư vấn..."
                                ref={(el) => (inputRefs.current[0] = el)}
                                styles={{ container: (base) => ({ ...base, flex: 1 }) }}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Dịch Vụ</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={services}
                                onChange={(e) => setServices(e.target.value)} // Cập nhật state khi người dùng chỉnh sửa
                                placeholder="Nhập hoặc chỉnh sửa nội dung dịch vụ"
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Tổng Thanh Toán</InputGroup.Text>
                            <Form.Control type="number" value={totalPrice} disabled />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Số Tiền Khách Trả</InputGroup.Text>
                            <Form.Control
                                type="number"
                                value={amount}
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    setAmount(value);
                                    setRemainingAmount(totalPrice - value - promoteAmount);
                                    setAmountInWords(to_vietnamese(value));
                                }}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Số Tiền Giảm</InputGroup.Text>
                            <Form.Control
                                type="number"
                                value={promoteAmount}
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    setPromoteAmount(value);
                                    setRemainingAmount(totalPrice - amount - value);
                                }}
                                ref={(el) => (inputRefs.current[6] = el)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Số Tiền Còn Nợ</InputGroup.Text>
                            <Form.Control
                                type="number"
                                value={remainingAmount}
                                disabled
                                ref={(el) => (inputRefs.current[7] = el)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Bằng Chữ</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1)}
                                disabled
                                ref={(el) => (inputRefs.current[8] = el)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Ghi Chú</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                ref={(el) => (inputRefs.current[9] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 9)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup>
                            <InputGroup.Text>Hình Thức Thanh Toán</InputGroup.Text>
                            <Form.Select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                                <option value="Tiền mặt">Tiền mặt</option>
                                <option value="Chuyển khoản">Chuyển khoản</option>
                                <option value="Quẹt thẻ">Quẹt thẻ</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button id="submitButton" variant="primary" onClick={handleSubmit}>
                    Tạo Phiếu Thu
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddReceiptModal;
