import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ViewImageModal = ({ show, handleClose, album }) => {
    const handleDownload = (image) => {
        const link = document.createElement('a');
        link.href = `data:${image.contentType};base64,${image.data}`;
        link.download = `OHS.Plus-${image._id}.jpg`; // Tên file tải xuống
        link.click();
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{album?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {album?.images.map((image, index) => (
                        <div className="col-md-6 mb-4" key={index}>
                            <h5>{image.name}</h5>
                            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                <img
                                    src={`data:${image.contentType};base64,${image.data}`}
                                    alt={image.description}
                                    style={{
                                        width: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '8px'
                                    }}
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                                <a onContextMenu={(e) => e.preventDefault()}
                                    href="#" // Tránh hiển thị URL trong thanh trạng thái
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDownload(image); // Gọi hàm tải ảnh
                                    }}
                                    style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        right: '10px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        borderRadius: '80%',
                                        padding: '8px',
                                        color: 'white',
                                        textDecoration: 'none'
                                    }}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </a>
                            </div>

                            <div className='text-center'>{image.description}</div></div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default ViewImageModal;
