import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { faCheck, faCheckCircle, faTasks, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseConfirmModal from '../components/CourseConfirmModal';
import Loading from '../components/Loading';
import ModalConfirm from '../components/ModalConfirm';
import { toast } from 'react-toastify';
import { RoleContext } from '../components/RoleContext';

const Courses = () => {
    const [customers, setCustomers] = useState(new Map()); // Khai báo state cho customers
    const { currentRole } = useContext(RoleContext)
    const [courses, setCourses] = useState([]);
    const [confirmations, setConfirmations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [confirmationToDelete, setConfirmationToDelete] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    }

    const fetchConsultations = useCallback(async () => {
        try {
            setLoading(true);

            // Fetch danh sách tư vấn
            const response = await axios.get(`${apiUrl}/consultations`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    currentRole,    // Chủ sở hữu dữ liệu
                    createdBy: userId, // Người thực hiện hành động
                },
            });

            const customersResponse = await axios.get(`${apiUrl}/customers`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { currentRole, createdBy: userId },
            });

            // Tạo Map từ danh sách khách hàng
            const customerMap = new Map(
                customersResponse.data.map((customer) => [
                    customer._id.toString(), // Sử dụng `toString()` để tránh vấn đề định dạng
                    customer.customFields?.hoVaTen || "Không xác định",
                ])
            );
            setCustomers(customerMap);

            const consultations = response.data;

            if (Array.isArray(consultations)) {
                const filteredConsultations = consultations.filter((consultation) =>
                    consultation.customFields?.lieuTrinh > 0
                );

                const sortedConsultations = filteredConsultations.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );

                setCourses(sortedConsultations);
            } else {
                console.error("Consultations data is not an array:", consultations);
            }
        } catch (error) {
            console.error("Error fetching consultations:", error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId, token, currentRole]);


    const fetchConfirmations = useCallback(async () => {
        try {
            setLoading(true);

            // Fetch danh sách liệu trình
            const response = await axios.get(`${apiUrl}/courses-confirm?createdBy=${currentRole}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const confirmationsData = response.data;
            if (!Array.isArray(confirmationsData)) {
                setConfirmations([]);
                return;
            }

            // Fetch danh sách khách hàng
            const customersResponse = await axios.get(`${apiUrl}/customers`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { currentRole, createdBy: userId },
            });

            const customers = customersResponse.data;

            // Gắn tên khách hàng vào bản ghi liệu trình
            const confirmationsWithNames = confirmationsData.map((confirmation) => {
                const customer = customers.find(
                    (customer) => customer._id === confirmation.customerId.$oid || customer._id === confirmation.customerId
                );

                return {
                    ...confirmation,
                    customerName: customer ? customer.customFields.hoVaTen : "Không xác định",
                };
            });

            // Sắp xếp danh sách
            const sortedConfirmations = confirmationsWithNames.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );

            setConfirmations(sortedConfirmations);
        } catch (error) {
            console.error("Error fetching course confirmations:", error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId, token, currentRole]);


    useEffect(() => {
        fetchConsultations();
        fetchConfirmations();
    }, [fetchConsultations, fetchConfirmations]);

    const reFreshAll = () => {
        fetchConsultations();
        fetchConfirmations();
    }

    const handleShowConfirmModal = (consultation) => {
        setSelectedConsultation(consultation);
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
        setSelectedConsultation(null);
    };

    const handleDelete = (id) => {
        setConfirmationToDelete(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setConfirmationToDelete(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`${apiUrl}/courses-confirm/${confirmationToDelete}`);
            toast.success('Xóa bản ghi xác nhận thành công!');
            fetchConfirmations(); // Refresh danh sách xác nhận
        } catch (error) {
            console.error('Lỗi khi xóa bản ghi:', error);
            toast.error('Không thể xóa bản ghi.');
        } finally {
            handleCloseDeleteModal();
        }
    };

    return (
        <div className="m-2">
            <h3><FontAwesomeIcon icon={faTasks} /> Liệu Trình</h3>
            {loading ? (<Loading />) : (courses.length > 0 ? (
                <>
                    <Table striped bordered hover responsive>
                        <thead className="text-center">
                            <tr>
                                <th>STT</th>
                                <th>Mã Tư Vấn</th>
                                <th>Khách Hàng</th>
                                <th>Dịch Vụ</th>
                                <th>Số Lần Còn Lại</th>
                                <th>Ngày Thực Hiện</th>
                                <th>Hành Động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses.map((consultation, index) => (
                                <tr key={consultation._id}>
                                    <td>{index + 1}</td>
                                    <td>{consultation.consultationCode.slice(0, 10) || "Không rõ"}</td>
                                    <td>
                                        <strong>{customers.get(consultation.customFields?.hoVaTen) || "Không xác định"}</strong>
                                    </td>

                                    <td>
                                        {Array.isArray(consultation.customFields?.tenDichVu)
                                            ? consultation.customFields.tenDichVu.map((service, i) => (
                                                <div key={i}>{service.label.split("-")[0].trim() || "Không rõ"}</div>
                                            ))
                                            : "Không có dịch vụ"}
                                    </td>
                                    <td className="text-center">
                                        <b>{consultation.customFields?.lieuTrinh || 0}</b>
                                    </td>
                                    <td>
                                        {consultation.customFields?.ngayThucHien
                                            ? new Date(consultation.customFields.ngayThucHien).toLocaleDateString('vi-VN')
                                            : "Chưa chọn"}
                                    </td>
                                    <td className="text-center">
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            className="me-2"
                                            onClick={() => handleShowConfirmModal(consultation)}
                                        >
                                            <FontAwesomeIcon icon={faCheckCircle} /> Xác Nhận Thực Hiện Liệu Trình
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            ) : (
                <div className='text-center'>Không có ca thực hiện Liệu Trình nào</div>
            ))}
            {/* Bảng liệt kê các bản ghi xác nhận */}
            <h3><FontAwesomeIcon icon={faTasks} /> Bản Ghi Xác Nhận Thực Hiện Liệu Trình</h3>
            <Table striped bordered hover responsive>
                <thead className="text-center">
                    <tr>
                        <th>STT</th>
                        <th>Tên Khách Hàng</th>
                        <th>Ngày Thực Hiện</th>
                        <th>Số Lần Còn Lại</th>
                        <th>Ghi Chú</th>
                        <th>Hành Động</th>
                    </tr>
                </thead>
                <tbody>
                    {confirmations.length > 0 ? (
                        confirmations.map((confirmation, index) => (
                            <tr key={confirmation._id}>
                                <td>{index + 1}</td>
                                <td><b>{confirmation.customerId.customFields?.hoVaTen || "Không xác định"}</b></td>
                                <td>{new Date(confirmation.performedDate).toLocaleDateString('vi-VN')}</td>
                                <td>
                                    {confirmation.updatedLieuTrinh === 0 ? (
                                        <span>
                                            Đã hoàn thành <FontAwesomeIcon icon={faCheck} className='text-success' />
                                        </span>
                                    ) : (
                                        confirmation.updatedLieuTrinh
                                    )}
                                </td>
                                <td>{confirmation.notes || 'Không có'}</td>
                                <td className="text-center">
                                    <Button variant="danger" size="sm" onClick={() => handleDelete(confirmation._id)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">Chưa có bản ghi nào</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <CourseConfirmModal
                currentRole={currentRole}
                show={showConfirmModal}
                handleClose={handleCloseConfirmModal}
                consultation={selectedConsultation}
                onReFresh={reFreshAll} />

            <ModalConfirm
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleConfirm={handleConfirmDelete} />
        </div>
    );
};

export default Courses;
