import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import Select from 'react-select';
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPencil, faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { to_vietnamese } from './numberToWords';

const AddSupply = ({ show, handleClose, fetchPayments, editData, permissions, currentRole }) => {
    const [formConfig, setFormConfig] = useState([]); // Cấu hình form tùy chỉnh
    const [customFields, setFormData] = useState({}); // Dữ liệu nhập từ người dùng
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef([]); // Để quản lý autofocus và chuyển trường
    const [errors, setErrors] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const userId = jwtDecode(token)?.userId;
    const hasPermission = (permission) => permissions?.[permission] || false;

    // Lấy cấu hình form từ server
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const response = await axios.get(`${apiUrl}/payment-form-config/${currentRole}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const config = response.data || [];
                setFormConfig(config);
                // Tải options cho trường apiSelect
                const updatedConfig = [...config];
                for (let i = 0; i < config.length; i++) {
                    const field = config[i];
                    if (field.fieldType === "apiSelect" && field.apiEndpoint) {
                        const resolvedEndpoint = field.apiEndpoint
                            .replace("${apiUrl}", apiUrl)
                            .replace("${userId}", userId)
                            .replace("${currentRole}", currentRole);

                        const apiResponse = await axios.get(resolvedEndpoint, {
                            headers: { Authorization: `Bearer ${token}` },
                        });
                        // Mapping các options từ API
                        const options = apiResponse.data || [];
                        updatedConfig[i].options = options.map((item) => ({
                            value: item._id,
                            label: `${item.customFields.tenDoiTac} - ${item.customFields.diaChi}`,
                        }));
                    }
                }
                setFormConfig(updatedConfig);
            } catch (error) {
                console.error("Lỗi khi tải cấu hình form:", error);
                toast.error("Không thể tải cấu hình form!");
            }
        };

        if (show) fetchFormConfig(); // Chỉ tải cấu hình khi Modal được mở
    }, [apiUrl, token, userId, show]);

    // Xử lý điền dữ liệu khi editData thay đổi (khi sửa)
    useEffect(() => {
        if (editData) {
            setFormData(editData.customFields || {});
        } else {
            setFormData({});
        }
    }, [editData]);

    // Xử lý thay đổi dữ liệu form
    const handleChange = (fieldName, value) => {
        setFormData((prev) => {
            const updatedFields = { ...prev, [fieldName]: value };

            if (fieldName === "giaBan") {
                updatedFields["giaBanBangChu"] = to_vietnamese(value).charAt(0).toUpperCase() + to_vietnamese(value).slice(1);
            }
            if (fieldName === "tenDoiTac") {
                const selectedOption = formConfig.find(
                    (config) => config.fieldName === "tenDoiTac"
                )?.options.find((option) => option.value === value);

                if (selectedOption) {
                    updatedFields["diaChi"] = selectedOption.label.split(" - ")[1];
                }
            }

            return updatedFields;
        });
    };

    // Di chuyển trường khi bấm Enter
    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleBlur = (field) => {
        if (field.isRequired && !customFields[field.fieldName]) {
            setErrors((prev) => ({
                ...prev,
                [field.fieldName]: `${field.fieldLabel} là bắt buộc.`,
            }));
        } else {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[field.fieldName];
                return newErrors;
            });
        }
    };
    // Gửi dữ liệu lên server
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Kiểm tra quyền
        if (!editData && !hasPermission("canAdd")) {
            toast.error("Bạn không có quyền thêm phiếu chi.");
            return;
        }
        if (editData && !hasPermission("canEdit")) {
            toast.error("Bạn không có quyền sửa phiếu chi.");
            return;
        }

        // Kiểm tra lỗi trên các trường bắt buộc
        const newErrors = {};
        formConfig.forEach((field) => {
            if (field.isRequired && !customFields[field.fieldName]) {
                newErrors[field.fieldName] = `${field.fieldLabel} là bắt buộc.`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error("Vui lòng điền đầy đủ các trường bắt buộc.");
            return;
        }

        try {
            setLoading(true);
            if (editData) {
                // Nhánh cập nhật\
                await axios.put(
                    `${apiUrl}/payments/${editData._id}`,
                    { customFields, createdBy: userId, currentRole },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                toast.success("Cập nhật phiếu chi thành công!");
            } else {
                // Nhánh thêm mới
                await axios.post(
                    `${apiUrl}/payments`,
                    { customFields, createdBy: userId, currentRole },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                toast.success("Thêm phiếu chi thành công!");
            }
            fetchPayments();
            handleClose();
        } catch (error) {
            console.error("Lỗi khi thêm/cập nhật phiếu chi:", error);
            if (editData) {
                toast.error("Không thể cập nhật phiếu chi!");
            } else {
                toast.error("Không thể thêm phiếu chi!");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{editData ? <>
                    <FontAwesomeIcon icon={faPencil} className="me-2" /> Sửa Phiếu Chi</> : <>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Thêm Phiếu Chi</>}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {formConfig.map((field, index) => (
                        <InputGroup className="mb-3" key={field.fieldName}>
                            <InputGroup.Text>{field.fieldLabel}</InputGroup.Text>
                            {/* Hiển thị các loại input dựa trên fieldType */}
                            {field.fieldType === "number" ? (
                                field.fieldName === "giaBan" ? (
                                    <Form.Control
                                        type="number"
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        value={customFields[field.fieldName] || ""}
                                        onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        required={field.isRequired}
                                        autoFocus={index === 0}
                                        onBlur={() => handleBlur(field)}
                                        isInvalid={!!errors[field.fieldName]}
                                    />
                                ) : (
                                    <Form.Control
                                        type="number"
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        value={customFields[field.fieldName] || ""}
                                        onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        required={field.isRequired}
                                        autoFocus={index === 0}
                                        onBlur={() => handleBlur(field)}
                                        isInvalid={!!errors[field.fieldName]}
                                    />
                                )
                            ) : field.fieldType === "text" ? (
                                field.fieldName === "giaBanBangChu" ? (
                                    <Form.Control
                                        type="text"
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        value={customFields[field.fieldName] || ""}
                                        readOnly // Chỉ đọc
                                    />
                                ) : (
                                    <Form.Control
                                        type="text"
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        value={customFields[field.fieldName] || ""}
                                        onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        required={field.isRequired}
                                        autoFocus={index === 0}
                                        onBlur={() => handleBlur(field)}
                                        isInvalid={!!errors[field.fieldName]}
                                    />
                                )
                            ) : null}
                            {field.fieldType === "date" && (
                                <Form.Control
                                    type="date"
                                    value={customFields[field.fieldName] || ""}
                                    onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    required={field.isRequired}
                                    autoFocus={index === 0}
                                    onBlur={() => handleBlur(field)}
                                    isInvalid={!!errors[field.fieldName]}
                                />
                            )}
                            {field.fieldType === "select" && (
                                <>
                                    {field.options.length <= 5 ? (
                                        <Form.Select
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: "80%",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: "38px",
                                                }),
                                            }}
                                            ref={(el) => (inputRefs.current[index] = el)}
                                            value={customFields[field.fieldName] || ""}
                                            onChange={(e) =>
                                                handleChange(field.fieldName, e.target.value)
                                            }
                                            onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                            isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        >
                                            <option value="">-- Chọn {field.fieldLabel} --</option>
                                            {field.options.map((option, idx) => (
                                                <option key={idx} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        <Select
                                            options={field.options.map((opt) => ({
                                                label: opt,
                                                value: opt,
                                            }))}
                                            value={
                                                customFields[field.fieldName]
                                                    ? {
                                                        label: customFields[field.fieldName],
                                                        value: customFields[field.fieldName],
                                                    }
                                                    : null
                                            }
                                            onChange={(selected) =>
                                                handleChange(
                                                    field.fieldName,
                                                    selected?.value || ""
                                                )
                                            }
                                            onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                            isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            placeholder={`Chọn ${field.fieldLabel}`}
                                            isClearable
                                            isSearchable
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: "80%",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: "38px",
                                                }),
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {field.fieldType === "apiSelect" && field.fieldName === "tenDoiTac" && (
                                <Select
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            width: "80%",
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            minHeight: "38px",
                                        }),
                                    }}
                                    options={field.options}
                                    value={field.options.find(
                                        (opt) =>
                                            opt.value === customFields.tenDoiTac || // So khớp ID đối tác
                                            opt.label.split(" - ")[0] === customFields.tenDoiTac // So khớp tên đối tác
                                    )}
                                    onChange={(selected) =>
                                        handleChange(field.fieldName, selected?.value || "")
                                    }
                                    placeholder={`Chọn ${field.fieldLabel}`}
                                    isClearable
                                    isSearchable
                                />
                            )}
                            <Form.Control.Feedback type="invalid">
                                {errors[field.fieldName]} {/* Hiển thị lỗi */}
                            </Form.Control.Feedback>
                        </InputGroup>
                    ))}
                    <div className="text-center">
                        <Button variant="secondary" onClick={handleClose} className="m-1">
                            Đóng
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            className="m-1"
                            disabled={loading}>
                            {editData ?
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-1" />  {loading ? ('Đang cập nhật...') : ('Cập nhật')}</>
                                :
                                <><FontAwesomeIcon icon={faSave} className="me-1" /> {loading ? ('Đang lưu...') : ('Lưu')}
                                </>}
                        </Button>

                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
export default AddSupply;
