// frontend/src/pages/Register.js

import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faUserClock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        const apiUrl = process.env.REACT_APP_API_URL;

        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Mật khẩu không khớp!');
            return;
        }
        try {
            await axios.post(`${apiUrl}/register`, { email, password });
            setSuccess('Tạo tài khoản thành công, hãy khám phá nào!');
            toast.success('Chào mừng đến với OHS.Plus!');
            setTimeout(() => {
                navigate('/login'); // Chuyển hướng đến trang đăng nhập sau khi đăng ký
            }, 3000);
        } catch (error) {

            setError('Đăng ký thất bại. Vui lòng thử lại.');
            console.error('Lỗi đăng ký:', error.response.data.message);
        }
    };

    return (
        <div className="register-container">
            <center><h2><FontAwesomeIcon icon={faUserClock} /> Đăng Ký</h2></center>
            {error && <p className="text-danger text-center">{error}</p>}
            {success && <p className='text-success text-center'>{success}</p>}
            <Form onSubmit={handleRegister}>
                <Form.Group controlId="formEmail">
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                        <Form.Control
                            type="email"
                            placeholder="Nhập email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </InputGroup>
                </Form.Group>
                <br />
                <Form.Group controlId="formPassword">
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                        <Form.Control
                            type="password"
                            placeholder="Nhập mật khẩu"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </InputGroup>
                </Form.Group>
                <br />
                <Form.Group controlId="formConfirmPassword">
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                        <Form.Control
                            type="password"
                            placeholder="Xác nhận mật khẩu"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </InputGroup>
                </Form.Group>
                <br />
                <center>
                    <Button variant="primary" type="submit">
                        Đăng Ký
                    </Button>
                </center>
            </Form>
        </div>
    );
};

export default Register;
