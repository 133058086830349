// frontend/src/pages/ServiceList.js
import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import { RoleContext } from '../components/RoleContext';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInbox, faBoxOpen, faFileInvoice, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl } from 'react-bootstrap';
import AddService from '../components/AddService';
import Loading from '../components/Loading';
import ModalConfirm from '../components/ModalConfirm';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ServiceList = () => {
    const { currentRole } = useContext(RoleContext);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([]); // Danh sách dịch vụ
    const [editData, setEditData] = useState(null); // Dữ liệu để sửa
    const [formConfig, setFormConfig] = useState([]); // Cấu hình form tùy chỉnh
    const [showModal, setShowModal] = useState(false); // Modal thêm dịch vụ
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Trạng thái hiển thị Modal xác nhận xóa
    const [serviceToDelete, setServiceToDelete] = useState(null); // Vật tư cần xóa
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    const userId = jwtDecode(token)?.userId;
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });
    // Hàm lấy permissions
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.get(`${apiUrl}/employees/permissions`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { currentRole, userId },
                });
                setPermissions(response.data);
            } catch (error) {
                console.error("Lỗi khi lấy quyền:", error);
            }
        };

        fetchPermissions();
    }, [currentRole, apiUrl, token, userId]);
    // Lấy cấu hình form
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const response = await axios.get(`${apiUrl}/service-form-config/${currentRole}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setFormConfig(response.data);
            } catch (error) {
                console.error("Lỗi khi tải cấu hình form:", error);
                toast.error("Không thể tải cấu hình form!");
            }
        };

        fetchFormConfig();
    }, [apiUrl, token, userId, currentRole]);

    // Lấy danh sách dịch vụ
    const fetchServices = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/services`, {
                params: {
                    currentRole,
                    createdBy: userId,
                    search,
                },
                headers: { Authorization: `Bearer ${token}` },
            });
            setServices(response.data);
        } catch (error) {
            console.error("Lỗi khi tải danh sách dịch vụ:", error);
            toast.error("Không thể tải danh sách dịch vụ!");
        } finally {
            setLoading(false);
        }
    }, [apiUrl, token, userId, currentRole, search]); // Thêm search vào dependencies

    // Gọi fetchServices mỗi khi có thay đổi trong search hoặc currentRole
    useEffect(() => {
        if (currentRole) {
            fetchServices();
        }
    }, [fetchServices, currentRole]);


    // Hiển thị Modal thêm mới hoặc sửa
    const handleShowModal = (data = null) => {
        setEditData(data); // Nếu `data` có giá trị, chuyển sang chế độ sửa
        setShowModal(true);
    };

    // Đóng Modal
    const handleCloseModal = () => {
        setEditData(null); // Reset dữ liệu sửa
        setShowModal(false);
        fetchServices(); // Làm mới danh sách sau khi thêm/sửa
    };
    // Hiển thị Modal xác nhận xóa
    const confirmDelete = (service) => {
        setServiceToDelete(service); // Ghi nhận dịch vụ cần xóa
        setShowConfirmModal(true);
    };

    // Xử lý xóa dịch vụ
    const handleDelete = async () => {
        if (!serviceToDelete) return;

        try {
            await axios.delete(`${apiUrl}/services/${serviceToDelete}`, {
                data: {
                    createdBy: userId,
                    currentRole: currentRole,
                },
                headers: { Authorization: `Bearer ${token}` },
            }
            );
            toast.success("Xóa dịch vụ thành công!");
            fetchServices(); // Làm mới danh sách
        } catch (error) {
            console.error("Lỗi khi xóa dịch vụ:", error);
            toast.error("Không thể xóa dịch vụ!");
        } finally {
            setShowConfirmModal(false); // Đóng Modal xác nhận
        }
    };
    const handleSearch = (e) => {
        setSearch(e.target.value); // Cập nhật giá trị tìm kiếm
    };

    return (
        <div className='m-2'>
            <h2><FontAwesomeIcon icon={faBoxOpen} /> Danh Sách Dịch Vụ</h2>
            <div className='d-flex mb-3'>
                <Button variant='primary' className='m-1' title='Thêm Dịch vụ mới' onClick={() => handleShowModal()}>
                    <FontAwesomeIcon icon={faPlus} /> Thêm Dịch Vụ
                </Button>
                <FormControl
                    type="text"
                    placeholder="Tìm kiếm dịch vụ..."
                    value={search}
                    onChange={handleSearch} // Gọi hàm handleSearch khi nhập liệu
                    className="ms-3"
                    style={{ flexGrow: 1 }}
                />
            </div>
            {loading ? (<Loading />) : (
                <Table striped bordered hover responsive>
                    <thead className='text-center'>
                        <tr>
                            <th>#</th>
                            <th>Mã Dịch Vụ</th>
                            {formConfig.map((field) => (
                                <th key={field.fieldName}>{field.fieldLabel}</th>
                            ))}
                            <th>Hành Động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {services.length > 0 && services.map((service, index) => (
                            <tr key={service._id}>
                                <td className='text-center'>{services.length - index}</td>
                                <td>{service.serviceCode.slice(0, 6)}</td>
                                {formConfig.map((field) => (
                                    <td key={field.fieldName}
                                        style={
                                            field.fieldName === "tenDichVu"
                                                ? { fontWeight: "bold" } // In đậm trường họ tên
                                                : {}
                                        }
                                    >
                                        {field.fieldName === "giaBan" || field.fieldName === "giaNhap"
                                            ? new Intl.NumberFormat("vi-VN", {
                                                style: "currency",
                                                currency: "VND",
                                            }).format(service.customFields?.[field.fieldName] || 0)
                                            : service.customFields?.[field.fieldName] || ""}
                                    </td>
                                ))}
                                <td className='text-center'>
                                    <Button variant="warning" size="sm" className="me-2" onClick={() => handleShowModal(service)}>
                                        Sửa
                                    </Button>
                                    <Button variant="danger" size="sm" onClick={() => confirmDelete(service._id)}>
                                        Xóa
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>)}
            <AddService
                currentRole={currentRole}
                permissions={permissions}
                show={showModal}
                handleClose={handleCloseModal}
                fetchServices={fetchServices}
                editData={editData} // Gửi dữ liệu để sửa (nếu có)
            />
            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDelete}
            />
        </div>
    );
};

export default ServiceList;
