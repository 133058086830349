import React from 'react';
import { components } from 'react-select';

// Tùy chỉnh thành phần Option để in đậm tên thuốc
const SupplyCustomOption = (props) => {
    return (
        <components.Option {...props}>
            <div>
                <strong>{props.data.tenVatTu}</strong> | Còn: {props.data.soLuong < 20 ? <span className='text-danger'><strong>{props.data.soLuong}</strong></span> : props.data.soLuong} {props.data.donVi} | Giá (1 {props.data.donVi}): {props.data.giaBan ? props.data.giaBan.toLocaleString('vi-VN') : ''}đ
            </div>
        </components.Option>
    );
};

export default SupplyCustomOption;
