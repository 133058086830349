import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Table, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import PayDebtModal from '../components/PayDebtModal'; // Import PayDebtModal
import ModalConfirm from '../components/ModalConfirm';
import Loading from '../components/Loading';
import PrintDebtReceiptModal from '../components/PrintDebtReceiptModal';
import { RoleContext } from '../components/RoleContext';

const Debts = () => {
    const { currentRole } = useContext(RoleContext);
    const [debts, setDebts] = useState([]); // Danh sách phiếu thu còn nợ
    const [debtReceipts, setDebtReceipts] = useState([]); // Danh sách phiếu thu nợ
    const [loading, setLoading] = useState(true);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [selectedDebtReceipt, setSelectedDebtReceipt] = useState(null);
    const [showPayDebtModal, setShowPayDebtModal] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [totalDebtReceiptsForYear, setTotalDebtReceiptsForYear] = useState(0);
    const [confirmDeleteCallback, setConfirmDeleteCallback] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token) {
        const decoded = jwtDecode(token);
        userId = decoded.userId;
    }

    // Fetch danh sách phiếu thu còn nợ
    const fetchDebts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/receipts?createdBy=${currentRole}`,
                {
                    params: { createdBy: userId, currentRole },
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (response.data.success) {
                const debtsData = response.data.receipts.filter(receipt => receipt.remainingAmount > 0).sort((a, b) => new Date(b.date) - new Date(a.date)); // Lọc phiếu thu còn nợ
                setDebts(debtsData);
                setLoading(false);
            } else {
                //toast.error('Lỗi khi lấy danh sách phiếu thu!');
                setDebts([]);
                setLoading(false);
            }
        } catch (error) {
            console.error('Lỗi khi lấy danh sách phiếu thu: ', error);
            setDebts([]);
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId, currentRole]);

    useEffect(() => {
        fetchDebts();
    }, [fetchDebts]);

    const reFetchDebts = () => {
        fetchDebts();
        fetchDebtReceipts();
    }

    // Fetch danh sách phiếu thu nợ
    const fetchDebtReceipts = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/debtreceipts?createdBy=${currentRole}`);
            if (response.data.success) {
                const allDebtReceipts = response.data.debtReceipts.sort((a, b) => new Date(b.date) - new Date(a.date));
                setDebtReceipts(allDebtReceipts);
            } else {
                //toast.error('Lỗi khi lấy phiếu thu nợ!')
                setDebtReceipts([]);
            }


        } catch (error) {
            console.error('Lỗi khi lấy danh sách phiếu thu nợ:', error);
            //toast.error('Lỗi khi lấy danh sách phiếu thu nợ!');
            setDebtReceipts([]);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId]);

    useEffect(() => {

        fetchDebtReceipts(); // Lấy danh sách phiếu thu nợ
    }, [fetchDebtReceipts]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Tháng từ 01 đến 12
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handlePayDebt = (receipt) => {
        setSelectedReceipt(receipt); // Đặt phiếu thu đã chọn
        setShowPayDebtModal(true); // Hiển thị modal thanh toán nợ
    };

    const fetchTotalDebtReceiptsYears = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/debtreceipts?createdBy=${currentRole}`);
            const currentYear = new Date().getFullYear();
            const totalDebtReceipts = response.data.debtReceipts.filter(debt => {
                const debtReceiptYear = new Date(debt.date).getFullYear();
                return debtReceiptYear === currentYear;
            });
            setTotalDebtReceiptsForYear(totalDebtReceipts.length);
        } catch (error) {
            console.error('Error fetching total receipts:', error);
            setTotalDebtReceiptsForYear(0);
        }
    }, [apiUrl, userId]);

    useEffect(() => {

        fetchTotalDebtReceiptsYears();
    }, [fetchTotalDebtReceiptsYears]);

    const handlePrintDebtReceipt = (debt) => {
        const updatedDebtReceipt = {
            ...debt,
            debtReceiptCode: debt.debtReceiptCode.slice(0, 11),
            printDate: new Date(debt.date)
        };

        const day = updatedDebtReceipt.printDate.getDate();
        const month = updatedDebtReceipt.printDate.getMonth() + 1;
        const year = updatedDebtReceipt.printDate.getFullYear();

        updatedDebtReceipt.printDay = day;
        updatedDebtReceipt.printMonth = month;
        updatedDebtReceipt.printYear = year;
        updatedDebtReceipt.totalDebtReceiptsForYear = totalDebtReceiptsForYear;

        setSelectedDebtReceipt(updatedDebtReceipt);
        setShowPrintModal(true);
    };

    useEffect(() => {
    }, [confirmDeleteCallback]);

    // Hàm để mở modal và đặt callback cho xóa tất cả
    const openModalForDeleteAllDebts = () => {
        setConfirmDeleteCallback(() => handleConfirmDeleteAllDebts);
        setShowModalConfirm(true);
    };

    const handleConfirmDeleteAllDebts = async () => {
        try {
            await axios.delete(`${apiUrl}/debtreceipts/deleteAll/${userId}`); // Gọi API để xóa tất cả phiếu thu nợ
            toast.success('Đã xóa tất cả phiếu thu nợ thành công!');
            fetchDebtReceipts(); // Lấy lại danh sách phiếu thu nợ
        } catch (error) {
            console.error('Lỗi khi xóa tất cả phiếu thu nợ:', error);
            toast.error('Lỗi khi xóa tất cả phiếu thu nợ!');
        } finally {
            setShowModalConfirm(false); // Đóng ModalConfirm
        }
    };

    // Hàm mở modal cho xóa mục cụ thể
    const openModalForDeleteItem = (itemId) => {
        setSelectedDebtReceipt(itemId); // Đặt ID phiếu thu cần xóa
        setConfirmDeleteCallback(() => async () => {
            if (itemId) {
                try {
                    await axios.delete(`${apiUrl}/debtreceipts/${itemId}`);
                    toast.success('Xóa phiếu thu thành công!');
                    fetchDebtReceipts();
                } catch (error) {
                    console.error('Lỗi khi xóa phiếu thu:', error);
                    toast.error('Lỗi khi xóa phiếu thu!');
                } finally {
                    setShowModalConfirm(false);
                }
            }
        });
        setShowModalConfirm(true);
    };


    return (
        <div className='m-2'>
            <h2>Quản Lý Nợ</h2>
            {loading ? (<Loading />) : (<>
                {
                    debts.length === 0 ? (
                        <center><p>Không có phiếu thu nào còn nợ.</p></center>
                    ) : (
                        <Table striped bordered hover>
                            <thead className="text-center">
                                <tr>
                                    <th>STT</th>
                                    <th>Số Phiếu Thu</th>
                                    <th>Tên Khách Hàng</th>
                                    <th>Số Tiền Còn Nợ</th>
                                    <th>Ngày Lập</th>
                                    <th>Hành Động</th>
                                </tr>
                            </thead>
                            <tbody>
                                {debts.map((debt, index) => (
                                    <tr key={debt._id}>
                                        <td>{index + 1}</td>
                                        <td>{debt.receiptCode.slice(0, 10)}</td>
                                        <td><b>{debt.customerName}</b></td>
                                        <td>{debt.remainingAmount ? debt.remainingAmount.toLocaleString('vi-VN') + 'VND' : ''}</td>
                                        <td>{formatDate(new Date(debt.date).toLocaleString())}</td>
                                        <td className="text-center">
                                            <Button variant="success" size="md" onClick={() => handlePayDebt(debt)} className='m-1'>
                                                Thanh Toán
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    )
                } < hr />
                <h3>Danh Sách Phiếu Thu Nợ</h3>
                <Button variant="danger" onClick={openModalForDeleteAllDebts} className='mb-2' disabled={!debtReceipts.length}>
                    Xóa Tất Cả Phiếu Thu Nợ
                </Button>
                {debtReceipts.length === 0 ? (
                    <center><p>Không có phiếu thu nợ nào.</p></center>
                ) : (
                    <Table striped bordered hover>
                        <thead className="text-center">
                            <tr>
                                <th>STT</th>
                                <th>Số Phiếu Thu Nợ</th>
                                <th>Tên Khách Hàng</th>
                                <th>Số Nợ Đã Trả</th>
                                <th>Số Tiền Còn Nợ</th>
                                <th>Ngày Lập</th>
                                <th>Hành Động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {debtReceipts.map((s_debt, index) => (
                                <tr key={s_debt._id}>
                                    <td>{index + 1}</td>
                                    <td>{s_debt.debtReceiptCode.slice(0, 11)}</td>
                                    <td>{s_debt.customerName}</td>
                                    <td>{s_debt.debtAmount ? s_debt.debtAmount.toLocaleString('vi-VN') + ' VND' : ''}</td>
                                    <td style={s_debt.debtRemainingAmount === 0 ? { textAlign: 'center' } : {}}>
                                        {s_debt.debtRemainingAmount ? s_debt.debtRemainingAmount.toLocaleString('vi-VN') + ' VND' : <><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> Đã hoàn thành!</>}</td>
                                    <td>{formatDate(new Date(s_debt.date).toLocaleString())}</td>
                                    <td className="text-center">
                                        <Button variant="success" size="sm" onClick={() => handlePrintDebtReceipt(s_debt)} className='m-1'>
                                            <FontAwesomeIcon icon={faPrint} />
                                        </Button>
                                        <Button variant="danger" size="sm" onClick={() => openModalForDeleteItem(s_debt._id)} className='m-1'>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )
                }</>)}

            <PayDebtModal
                onReFresh={reFetchDebts}
                show={showPayDebtModal}
                handleClose={() => setShowPayDebtModal(false)}
                receipt={selectedReceipt} // Truyền thông tin phiếu thu nợ vào modal
                currentRole={currentRole}
            />
            <ModalConfirm
                show={showModalConfirm}
                handleClose={() => setShowModalConfirm(false)}
                handleConfirm={() => {
                    if (confirmDeleteCallback) {
                        confirmDeleteCallback(); // Gọi hàm callback được thiết lập
                    }
                }}
            />
            <PrintDebtReceiptModal
                show={showPrintModal}
                handleClose={() => setShowPrintModal(false)}
                debt={selectedDebtReceipt || {}}
            />
        </div >
    );

};
export default Debts;
