import React from 'react';
import { ListGroup } from 'react-bootstrap';

const TimelineSection = ({ events }) => {
    return (
        <ListGroup className="timeline-section">
            {events.map((event, index) => (
                <ListGroup.Item key={index}>
                    <strong>{event.date}</strong> - {event.description}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

export default TimelineSection;
