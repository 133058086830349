import { useRef } from "react";

export const useFormNavigation = (onSubmit) => {
    const fieldRefs = useRef([]);

    const registerField = (index) => (element) => {
        if (element) {
            fieldRefs.current[index] = element;
        }
    };

    const handleKeyDown = (index, fieldCount) => (event) => {
        if (event.key === "Enter") {
            event.preventDefault();

            if (index === fieldCount - 1) {
                // Trường cuối cùng => Submit form
                onSubmit();
            } else {
                // Focus vào trường tiếp theo
                const nextField = fieldRefs.current[index + 1];
                if (nextField) {
                    nextField.focus();
                }
            }
        }
    };

    const resetFocus = () => {
        if (fieldRefs.current[0]) {
            fieldRefs.current[0].focus();
        }
    };

    return { registerField, handleKeyDown, resetFocus };
};
