import React, { useEffect, useState, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode'; // Giữ nguyên import này
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
//import { generateBarcodeBase64 } from '../utils/generateBarcodeBase64';

const PrintPrescriptionModal = ({ show, handleClose, prescription }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  let userId;
  if (token && typeof token === 'string') {
    try {
      const decoded = jwtDecode(token);
      userId = decoded.userId;
    } catch (error) {
      console.error('Token không hợp lệ:', error);
    }
  }

  const [companyInfo, setCompanyInfo] = useState({ name: '', nameCPN: '', logo: '', address: '', phone: '' });

  const fetchUser = useCallback(async () => {
    if (userId) {
      try {
        const response = await axios.get(`${apiUrl}/user/${userId}`);
        setCompanyInfo({
          name: response.data.user.name,
          nameCPN: response.data.user.companyName,
          logo: response.data.user.logo,
          address: response.data.user.companyAddress,
          phone: response.data.user.companyPhone,
        });
      } catch (error) {
        console.error('Lỗi khi lấy thông tin người dùng:', error);
      }
    }
  }, [apiUrl, userId]);

  useEffect(() => {
    if (prescription) {
      fetchUser();
    }
  }, [prescription, fetchUser]);

  let day, month, year;

  if (prescription?.createdAt) {
    const createdAt = new Date(prescription.createdAt);
    day = createdAt.getDate();
    month = createdAt.getMonth() + 1;
    year = createdAt.getFullYear();
  } else {
    day = month = year = 'N/A';
  }

  const prescriptionDetailsHTML = prescription?.prescriptions.map((med, index) => `
  <p><b>${index + 1}. ${med.medicineId.customFields.tenThuoc}</b></p>
  <p>Liều dùng: ${med.duongDung || med.medicineId.customFields.duongDung}, 
  ${med.dosePerTime} ${med.medicineId.customFields.donViTinh}, 
  ${med.timesPerDay} lần/ngày.<br/>
  <i>${med.note}</i></p>
`).join('');

  const handlePrint = async () => {
    try {
      // const barcodeValue = prescription?.prescriptionId || "248570174";
      // const barcodeBase64 = await generateBarcodeBase64(barcodeValue);

      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
        <head>
          <title>In Toa Thuốc</title>
          <style>
            @media print {
              @page { size: A5; margin: 5mm; }
            }
            body {
              font-family: Arial, sans-serif;
              font-size: 14px;
              margin: 0;
              padding: 0;
              width: 100%;
            }
            table {
              width: 100%;
              font-size: 14px;
              border-collapse: collapse;
            }
            .logo {
              max-width: 50px;
            }
          </style>
        </head>
        <body>
          <div class="prescription-container">
            <div class="prescription">
              <table>
                <tr>
                  <td rowspan="4" style="width: 100px">
                    ${companyInfo.logo ? `<img src="${companyInfo.logo}" alt="Logo" class="logo" />` : ''}
                  </td>
                  <td>${companyInfo.nameCPN}</td>
                </tr>
                <tr><td>Địa chỉ: ${companyInfo.address}</td></tr>
                <tr><td>Điện thoại: ${companyInfo.phone}</td></tr>
              </table>
              <center>
              <h1>TOA THUỐC</h1>
              <p>${prescription?.prescriptionCode.slice(0, 11)}</p>
              </center>
              <p>Họ Tên: <b>${prescription?.customerId.customFields.hoVaTen.toUpperCase()}</b> - Tuổi: <b>${new Date().getFullYear() - Number(prescription.customerId.customFields.namSinh)}</b> - Giới: <b>${prescription.customerId.customFields.gioiTinh}</b></p>
              <p><b>Địa chỉ:</b> ${prescription.customerId.customFields.diaChi}</p>
              <p><b>Chẩn Đoán:</b> ${prescription.diagnosis}</p><hr/>
              ${prescriptionDetailsHTML}
            </div>
          </div>
          <table style="position: absolute; bottom: 20px;">
          <tbody>
          <tr>
          <td width="50%">
            <b>Lời dặn:</b> ${prescription.doctorAdvice || "Theo hướng dẫn của bác sĩ"}
            </td>
            <td width="50%" style="text-align: center;">
            <i>Ngày ${day} Tháng ${month} Năm ${year}</i><br/>
            <br/>
            BÁC SĨ ĐIỀU TRỊ<br/><br/><br/>
            <b>${companyInfo.name}</b>
            </td>
            </tr>
          </tbody>
          </table>
        </body>
        </html>
      `);

      printWindow.document.close();
      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 500);
    } catch (error) {
      console.error("Lỗi khi in toa thuốc:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title><FontAwesomeIcon icon={faPrint} /> Xem Toa Thuốc</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Khách Hàng: <b>{prescription?.customerId.customFields.hoVaTen}</b></p>
        <p>Năm sinh: <b>{prescription?.customerId.customFields.namSinh}</b> Giới: <b>{prescription?.customerId.customFields.gioiTinh}</b></p>
        <p>Địa Chỉ: <b>{prescription?.customerId.customFields.diaChi}</b></p>
        <p>Chẩn Đoán: <b>{prescription?.diagnosis}</b></p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="secondary" onClick={handleClose}>Đóng</Button>
        <Button variant="primary" onClick={handlePrint}>Xem Toa Thuốc</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintPrescriptionModal;
