// src/components/NotFound.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadCry } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/'); // Thay '/' bằng đường dẫn bạn muốn chuyển hướng đến
        }, 5000);

        // Dọn dẹp timer khi component unmount
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1><br />Ố_ồ</h1>
            <p>Liên kết bạn truy cập không tồn tại.</p>
            <p>Bạn sẽ được chuyển đến Trang Chủ trong 5 giây nữa...</p>
            <h1><FontAwesomeIcon icon={faSadCry} /></h1>
        </div>
    );
};

export default NotFound;