import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import Select from 'react-select';
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPencil, faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons";

const AddSupply = ({ show, handleClose, fetchPartners, editData, permissions, currentRole }) => {
    const [formConfig, setFormConfig] = useState([]); // Cấu hình form tùy chỉnh
    const [customFields, setFormData] = useState({}); // Dữ liệu nhập từ người dùng
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef([]); // Để quản lý autofocus và chuyển trường
    const [errors, setErrors] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const userId = jwtDecode(token)?.userId;
    const hasPermission = (permission) => permissions?.[permission] || false;

    // Lấy cấu hình form từ server
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const response = await axios.get(`${apiUrl}/partner-form-config/${currentRole}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setFormConfig(response.data);
            } catch (error) {
                console.error("Lỗi khi tải cấu hình form:", error);
                toast.error("Không thể tải cấu hình form!");
            }
        };

        if (show) fetchFormConfig(); // Chỉ tải cấu hình khi Modal được mở
    }, [apiUrl, token, userId, show]);

    // Xử lý điền dữ liệu khi editData thay đổi (khi sửa)
    useEffect(() => {
        if (editData) {
            setFormData(editData.customFields || {});
        } else {
            setFormData({});
        }
    }, [editData]);

    // Xử lý thay đổi dữ liệu form
    const handleChange = (fieldName, value) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    // Di chuyển trường khi bấm Enter
    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleBlur = (field) => {
        if (field.isRequired && !customFields[field.fieldName]) {
            setErrors((prev) => ({
                ...prev,
                [field.fieldName]: `${field.fieldLabel} là bắt buộc.`,
            }));
        } else {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[field.fieldName];
                return newErrors;
            });
        }
    };
    // Gửi dữ liệu lên server
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!editData && !hasPermission("canAdd")) {
            toast.error("Bạn không có quyền thêm đối tác.");
            return;
        }
        if (editData && !hasPermission("canEdit")) {
            toast.error("Bạn không có quyền sửa đối tác.");
            return;
        }

        // Kiểm tra lỗi trên tất cả các trường
        const newErrors = {};
        formConfig.forEach((field) => {
            if (field.isRequired && !customFields[field.fieldName]) {
                newErrors[field.fieldName] = `${field.fieldLabel} là bắt buộc.`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Hiển thị lỗi
            toast.error("Vui lòng điền đầy đủ các trường bắt buộc.");
            return;
        }
        try {
            if (editData) {
                // Nếu đang sửa
                setLoading(true);
                await axios.put(
                    `${apiUrl}/partners/${editData._id}`,
                    { customFields, createdBy: userId, currentRole: currentRole },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                toast.success("Cập nhật đối tác thành công!");
                setLoading(false);
                fetchPartners();
                handleClose(); // Đóng modal
            } else {
                // Nếu đang thêm mới
                setLoading(true);
                await axios.post(
                    `${apiUrl}/partners`,
                    { customFields, createdBy: userId, currentRole: currentRole },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                toast.success("Thêm đối tác thành công!");
                setLoading(false);
                fetchPartners(); // Làm mới danh sách
                handleClose(); // Đóng modal
            }
        } catch (error) {
            console.error("Lỗi khi thêm/cập nhật đối tác:", error);
            toast.error("Không thể thêm/cập nhật đối tác!");
        }
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{editData ? <>
                    <FontAwesomeIcon icon={faPencil} className="me-2" /> Sửa Đối Tác</> : <>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Thêm Đối Tác</>}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {formConfig.map((field, index) => (
                        <InputGroup className="mb-3" key={field.fieldName}>
                            <InputGroup.Text>{field.fieldLabel}</InputGroup.Text>
                            {/* Hiển thị các loại input dựa trên fieldType */}
                            {field.fieldType === "text" && (
                                <Form.Control
                                    type="text"
                                    placeholder={`Nhập ${field.fieldLabel}`}
                                    value={customFields[field.fieldName] || ""}
                                    onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    required={field.isRequired}
                                    autoFocus={index === 0}
                                    onBlur={() => handleBlur(field)}
                                    isInvalid={!!errors[field.fieldName]}
                                />
                            )}
                            {field.fieldType === "number" && (
                                <Form.Control
                                    type="number"
                                    placeholder={`Nhập ${field.fieldLabel}`}
                                    value={customFields[field.fieldName] || ""}
                                    onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    required={field.isRequired}
                                    autoFocus={index === 0}
                                    onBlur={() => handleBlur(field)}
                                    isInvalid={!!errors[field.fieldName]}
                                />
                            )}
                            {field.fieldType === "date" && (
                                <Form.Control
                                    type="date"
                                    value={customFields[field.fieldName] || ""}
                                    onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    required={field.isRequired}
                                    autoFocus={index === 0}
                                    onBlur={() => handleBlur(field)}
                                    isInvalid={!!errors[field.fieldName]}
                                />
                            )}
                            {field.fieldType === "select" && (
                                <>
                                    {field.options.length <= 5 ? (
                                        <Form.Select
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: "80%",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: "38px",
                                                }),
                                            }}
                                            ref={(el) => (inputRefs.current[index] = el)}
                                            value={customFields[field.fieldName] || ""}
                                            onChange={(e) =>
                                                handleChange(field.fieldName, e.target.value)
                                            }
                                            onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                            isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        >
                                            <option value="">-- Chọn {field.fieldLabel} --</option>
                                            {field.options.map((option, idx) => (
                                                <option key={idx} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    ) : (
                                        <Select
                                            options={field.options.map((opt) => ({
                                                label: opt,
                                                value: opt,
                                            }))}
                                            value={
                                                customFields[field.fieldName]
                                                    ? {
                                                        label: customFields[field.fieldName],
                                                        value: customFields[field.fieldName],
                                                    }
                                                    : null
                                            }
                                            onChange={(selected) =>
                                                handleChange(
                                                    field.fieldName,
                                                    selected?.value || ""
                                                )
                                            }
                                            onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                            isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            placeholder={`Chọn ${field.fieldLabel}`}
                                            isClearable
                                            isSearchable
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: "80%",
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: "38px",
                                                }),
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            <Form.Control.Feedback type="invalid">
                                {errors[field.fieldName]} {/* Hiển thị lỗi */}
                            </Form.Control.Feedback>
                        </InputGroup>
                    ))}
                    <div className="text-center">
                        <Button variant="secondary" onClick={handleClose} className="m-1">
                            Đóng
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            className="m-1"
                            disabled={loading}>
                            {editData ?
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-1" />  {loading ? ('Đang cập nhật...') : ('Cập nhật')}</>
                                :
                                <><FontAwesomeIcon icon={faSave} className="me-1" /> {loading ? ('Đang lưu...') : ('Lưu')}
                                </>}
                        </Button>

                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
export default AddSupply;
