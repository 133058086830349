import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faCalendarDays, faCalendarPlus, faCheckCircle, faCircle, faClockRotateLeft, faHeartbeat, faHeartBroken, faPencil, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/vi'; // Để sử dụng ngôn ngữ Việt Nam
import 'bootstrap/dist/css/bootstrap.min.css'; // Thêm Bootstrap vào dự án
import { Modal, Button, Form, Table, Badge } from 'react-bootstrap'; // Import Modal từ react-bootstrap
import axios from 'axios'; // Thêm axios để lấy dữ liệu khách hàng từ API
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import formatDate from '../components/FormatDate';
import AppoitmentsList from '../components/AppoitmentsList';
import PageWrapper from '../components/PageWrapper';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RoleContext } from '../components/RoleContext';
import { v4 as uuidv4 } from 'uuid';


moment.locale('vi');
const localizer = momentLocalizer(moment);

const Appointments = () => {
    const { currentRole } = useContext(RoleContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const [events, setEvents] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [eventTitle, setEventTitle] = useState('');
    const [eventStart, setEventStart] = useState(new Date());
    const [eventEnd, setEventEnd] = useState(new Date());
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [appointments, setAppointments] = useState([]); // Lưu danh sách cuộc hẹn
    const [customerData, setCustomerData] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [refreshAppointments, setRefreshAppointments] = useState(null);


    const [modalStatusShow, setModalStatusShow] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [newStatus, setNewStatus] = useState('Pending'); // Mặc định là "Pending"

    const getEventStyle = (event) => {
        let backgroundColor;
        switch (event.title) {
            case 'Tư vấn':
                backgroundColor = '#f39c12'; // Màu cam
                break;
            case 'Tái khám':
                backgroundColor = '#27ae60'; // Màu xanh lá
                break;
            case 'Thực hiện Dịch Vụ':
                backgroundColor = '#e74c3c'; // Màu xanh dương
                break;
            case 'Thực hiện Liệu Trình':
                backgroundColor = '#3498db'; // Màu tím
                break;
            case 'Thay băng cắt chỉ':
                backgroundColor = '#9b59b6'; // Màu đỏ
                break;
            default:
                backgroundColor = '#95a5a6'; // Màu xám cho các loại khác
        }
        return {
            style: {
                backgroundColor,
                color: '#fff', // Màu chữ trắng để dễ đọc
                borderRadius: '5px', // Tùy chọn, làm tròn góc
                border: 'none' // Tùy chọn, không viền
            }
        };
    };

    const inputRefs = useRef([]);

    useEffect(() => {
        if (modalShow) {
            inputRefs.current[0]?.focus();
        }
    }, [modalShow]);

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextIndex = index + 1;
            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                document.getElementById('createAppointmentButton')?.focus();
            }
        }
    };

    const handleShowModal = (appointment) => {
        setSelectedAppointment(appointment);
        setModalStatusShow(true);
    };

    const [editModalShow, setEditModalShow] = useState(false);
    const [currentAppointment, setCurrentAppointment] = useState(null);
    const [editAppointmentTitle, setEditAppointmentTitle] = useState('');
    const [editAppointmentStart, setEditAppointmentStart] = useState(new Date());
    const [editAppointmentEnd, setEditAppointmentEnd] = useState(new Date());
    const [editStatus, setEditStatus] = useState('Pending');
    const appointmentTypes = [
        { value: 'Tư vấn', label: 'Tư vấn' },
        { value: 'Tái khám', label: 'Tái khám' },
        { value: 'Thực hiện dịch vụ', label: 'Thực hiện Dịch Vụ' },
        { value: 'Thực hiện liệu trình', label: 'Thực hiện Liệu Trình' },
        { value: 'Thay băng cắt chỉ', label: 'Thay băng cắt chỉ' }
    ];

    const handleShowEditModal = (appointment) => {
        setCurrentAppointment(appointment);
        setEditAppointmentTitle(appointment.title); // Đặt tiêu đề hiện tại vào state
        setEditAppointmentStart(new Date(appointment.start)); // Đặt thời gian bắt đầu vào state
        setEditAppointmentEnd(new Date(appointment.end)); // Đặt thời gian kết thúc vào state
        setEditModalShow(true);
    };

    const handleCloseEditModal = () => {
        setEditModalShow(false);
    };

    const handleConfirmEdit = async (appointmentId) => {
        const updatedData = {
            title: editAppointmentTitle,
            start: editAppointmentStart,
            end: editAppointmentEnd,
            status: editStatus, // Thêm trạng thái vào dữ liệu cập nhật
        };

        try {
            await axios.put(`${apiUrl}/appointments/${appointmentId}`, {
                updatedData, // Gửi toàn bộ dữ liệu cập nhật trong `updatedData`
                createdBy: userId,
                currentRole,
            });

            // Cập nhật dữ liệu trong state
            setAppointments(prev =>
                prev.map(app =>
                    app._id === appointmentId ? { ...app, ...updatedData } : app
                )
            );

            toast.success("Cập nhật cuộc hẹn thành công!");
            if (typeof fetchAppointments === "function") {
                await fetchAppointments();
            }
            if (typeof refreshAppointments === "function") {
                await refreshAppointments();
            }
        } catch (error) {
            console.error("Lỗi khi cập nhật cuộc hẹn:", error);
            toast.error("Không thể cập nhật cuộc hẹn!");
        } finally {
            handleCloseEditModal(); // Đóng modal
        }
    };


    const handleCloseModal = () => {
        setModalStatusShow(false);
    };

    // Hàm để xử lý xác nhận
    // Hàm để xử lý xác nhận
    const handleConfirmStatus = async () => {
        if (selectedAppointment) {
            try {
                const updatedData = {
                    status: newStatus, // Chỉ trường `status` được cập nhật
                };

                await axios.put(`${apiUrl}/appointments/${selectedAppointment._id}`, {
                    updatedData,
                    createdBy: userId,
                    currentRole,
                });

                // Cập nhật trạng thái trong state
                setAppointments(prev =>
                    prev.map(app =>
                        app._id === selectedAppointment._id ? { ...app, status: newStatus } : app
                    )
                );

                toast.success("Cập nhật trạng thái thành công!");
                if (typeof refreshAppointments === "function") {
                    refreshAppointments();
                } else {
                    console.error("refreshAppointments không phải là một hàm hợp lệ");
                }
            } catch (error) {
                console.error("Lỗi khi cập nhật trạng thái:", error);
                toast.error("Không thể cập nhật trạng thái!");
            } finally {
                handleCloseModal();
            }
        }
    };


    const fetchAppointments = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/appointments`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { createdBy: userId, currentRole },
                });
            setAppointments(response.data);
            setLoading(false);
            const formattedAppointments = response.data.map(appointment => ({
                start: new Date(appointment.start),
                end: new Date(appointment.end),
                title: appointment.title,
                customerId: appointment.customerId._id, // Lấy _id của khách hàng
                status: appointment.status
            }));
            setEvents(formattedAppointments);

            // Fetch customer data for appointments
            const customerIds = [...new Set(formattedAppointments.map(app => app.customerId))]; // Bây giờ customerId đã là chuỗi

            const customerDetails = await Promise.all(
                customerIds.map(async id => {
                    const res = await axios.get(`${apiUrl}/customers/${id}`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                            params: { createdBy: userId, currentRole },
                        });
                    return { id, ...res.data };
                })
            );

            const customerMap = customerDetails.reduce((acc, customer) => {
                acc[customer.id] = customer;
                return acc;
            }, {});

            setCustomerData(customerMap);
            setLoading(false);

        } catch (error) {
            console.error("Lỗi khi lấy danh sách cuộc hẹn:", error);
            setLoading(false);
        }
    }, [apiUrl, userId]);

    const fetchCustomers = useCallback(async () => {
        try {
            setLoading(true);

            // Lấy dữ liệu consultations
            const consultationsResponse = await axios.get(`${apiUrl}/consultations`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { createdBy: userId, currentRole },
            });
            const consultations = consultationsResponse.data || [];

            // Lấy dữ liệu khách hàng
            const customersResponse = await axios.get(`${apiUrl}/customers`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { createdBy: userId, currentRole, format: "simple" },
            });
            const customersData = customersResponse.data || [];

            // Kết hợp dữ liệu khách hàng với tư vấn
            const combinedCustomers = customersData.map(customer => {
                // Lọc tư vấn liên quan
                const relatedConsultations = consultations.filter(consultation =>
                    consultation.customFields.hoVaTen === customer._id
                );

                // Lấy tư vấn mới nhất
                const latestConsultation = relatedConsultations.reduce((latest, current) => {
                    return new Date(latest.createdAt.$date) > new Date(current.createdAt.$date) ? latest : current;
                }, null);

                return {
                    ...customer, // Giữ nguyên `_id`
                    latestConsultationDate: latestConsultation
                        ? new Date(latestConsultation.createdAt.$date)
                        : null
                };
            });

            // Sắp xếp từ mới đến cũ theo `latestConsultationDate`
            const sortedCustomers = combinedCustomers.sort((a, b) =>
                (b.latestConsultationDate || 0) - (a.latestConsultationDate || 0)
            );

            // Chuyển đổi sang dạng options
            const customerOptions = sortedCustomers.map(customer => ({
                value: customer.value,
                label: `${customer.label}`,
            }));

            setCustomers(customerOptions);
        } catch (error) {
            console.error("Lỗi khi lấy danh sách khách hàng hoặc tư vấn:", error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl, token, userId, currentRole]);

    useEffect(() => {
        fetchCustomers();
        fetchAppointments();
    }, [fetchAppointments, fetchCustomers]);


    const reFetchAppointments = () => {
        fetchAppointments();
    }

    const handleSelect = ({ start }) => {
        // Gọi handleDateChange với ngày được chọn
        handleDateChange(start);
    };

    const handleDateChange = (date) => {
        const start = new Date(date);
        const end = new Date(date);

        // Đặt thời gian bắt đầu và kết thúc cho cuộc hẹn
        start.setHours(8, 0, 0, 0); // Giả sử giờ hẹn bắt đầu là 8:00
        end.setHours(17, 0, 0, 0); // Giả sử giờ hẹn kết thúc là 17:00

        const now = new Date();

        if (start < now) {
            toast.error('Không thể tạo cuộc hẹn cho thời gian trong quá khứ!');
            return;
        }

        // Logic xử lý sự kiện tạo cuộc hẹn
        setEventStart(start);
        setEventEnd(end);
        setModalShow(true);
    };


    const handleSubmit = async () => {
        const now = new Date();

        if (eventStart < now) {
            toast.error('Thời gian bắt đầu không thể ở quá khứ!');
            return;
        }

        if (eventTitle && selectedCustomers.length > 0) {
            try {

                // Tạo promises để gửi yêu cầu tạo cuộc hẹn cho từng khách hàng
                const appointmentPromises = selectedCustomers.map(customerId => {

                    const newAppointment = {
                        customerId: customerId, // Đảm bảo customerId được truyền chính xác
                        title: eventTitle,
                        start: eventStart,
                        end: eventEnd,
                        priority: "Normal",
                        note: "",
                        createdBy: userId,
                        currentRole,
                        status: "Pending" // Trạng thái mặc định là "Đang chờ"
                    };

                    // Gửi yêu cầu POST cho từng cuộc hẹn
                    return axios.post(`${apiUrl}/appointments`, newAppointment);
                });

                // Chờ tất cả các promises hoàn thành
                await Promise.all(appointmentPromises);

                toast.success('Thêm cuộc hẹn thành công cho tất cả khách hàng!');
                await fetchAppointments();
                refreshAppointments();
                setEventTitle('');
                setSelectedCustomers([]); // Reset lại danh sách khách hàng chọn
                setModalShow(false); // Đóng modal sau khi tạo xong
            } catch (error) {
                console.error('Lỗi khi lưu cuộc hẹn:', error.response?.data || error.message);
                toast.error('Không thể lưu cuộc hẹn!');
            }
        } else {
            toast.error('Vui lòng điền đầy đủ thông tin và chọn ít nhất một khách hàng!');
        }
    };


    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedCustomers([]);
        } else {
            setSelectedCustomers(customers.map(customer => customer.value));
        }
        setSelectAll(!selectAll);
    };

    const handleCustomerChange = (customerId) => {
        if (selectedCustomers.includes(customerId)) {
            setSelectedCustomers(selectedCustomers.filter(id => id !== customerId));
        } else {
            setSelectedCustomers([...selectedCustomers, customerId]);
        }
    };

    const [selectedDate, setSelectedDate] = useState(new Date());

    const renderAppointmentsByStatus = (status) => {
        const now = new Date(); // Lấy thời gian hiện tại
        const filteredAppointments = appointments.filter(app => {
            const isMatchingStatus = app.status.localeCompare(status, undefined, { sensitivity: 'base' }) === 0;
            const isNotExpired = new Date(app.start) >= now; // Kiểm tra xem cuộc hẹn chưa quá ngày

            return isMatchingStatus && isNotExpired; // Chỉ giữ các cuộc hẹn phù hợp với trạng thái và chưa quá hạn
        });

        if (filteredAppointments.length === 0) {
            return <p>Không có cuộc hẹn nào.</p>;
        }

        return (

            <div>
                {filteredAppointments.map((appointment, index) => {
                    const customer = customerData[appointment.customerId._id] || {}; // Truy xuất thông tin khách hàng
                    const { style } = getEventStyle(appointment); // Lấy thuộc tính style từ hàm getEventStyle
                    const backgroundColor = style.backgroundColor; // Lấy màu nền từ style

                    return (
                        <div className="ms-2 me-auto">
                            <div key={uuidv4()}>
                                {isMobile ?
                                    <><Badge bg='secondary'>{index + 1}</Badge> <b>{customer.customFields?.hoVaTen}</b> - {customer.customFields?.namSinh}<br />
                                        <FontAwesomeIcon icon={faPhone} /> {customer.customFields?.soDienThoai} <span className="bg-warning badge text-muted p2 m-1" style={{ cursor: 'pointer' }} title='Sửa Cuộc Hẹn' onClick={() => handleShowEditModal(appointment)}>&nbsp;&nbsp;<FontAwesomeIcon icon={faPencil} /></span></>
                                    :
                                    <><Badge bg='secondary'>{index + 1}</Badge> <b>{customer.customFields?.hoVaTen}</b> - <FontAwesomeIcon icon={faBirthdayCake} /> {customer.customFields?.namSinh} - <FontAwesomeIcon icon={faPhone} /> {customer.customFields?.soDienThoai} <span className="bg-warning badge text-muted p2 m-1" style={{ cursor: 'pointer' }} title='Sửa Cuộc Hẹn' onClick={() => handleShowEditModal(appointment)}>&nbsp;&nbsp;<FontAwesomeIcon icon={faPencil} /></span></>
                                }
                            </div>
                            {isMobile ? (
                                <Table striped bordered hover responsive>
                                    <tbody>
                                        <tr style={{ width: '100%' }}>
                                            <td className='text-start'>
                                                <span className="badge m-1" style={{ backgroundColor, color: '#fff', borderRadius: 6, paddingLeft: 16, paddingRight: 6 }}>
                                                    {appointment.title} - <i>Ngày: {formatDate(appointment.start)}</i>
                                                </span></td>
                                            <td className='text-end'>
                                                {appointment.status === "Pending" && (
                                                    <span
                                                        className="badge bg-success text-light"
                                                        style={{ cursor: 'pointer', borderRadius: 6, paddingLeft: 6, paddingRight: 6 }}
                                                        onClick={() => handleShowModal(appointment)}
                                                    >
                                                        Xác nhận
                                                    </span>
                                                )}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            ) : (<Table striped bordered hover responsive>
                                <tbody>
                                    <tr style={{ width: '100%' }}>
                                        <td className='text-start'>
                                            <span className="badge m-1" style={{ backgroundColor, color: '#fff', borderRadius: 6, paddingLeft: 16, paddingRight: 6 }}>
                                                {appointment.title} - <i>Ngày: {formatDate(appointment.start)}</i>
                                            </span></td>
                                        <td className='text-end'>
                                            {appointment.status === "Pending" && (
                                                <span
                                                    className="badge bg-success text-light"
                                                    style={{ cursor: 'pointer', borderRadius: 6, paddingLeft: 6, paddingRight: 6 }}
                                                    onClick={() => handleShowModal(appointment)}
                                                >
                                                    Xác nhận
                                                </span>
                                            )}</td>
                                    </tr>
                                </tbody>
                            </Table>)}
                        </div>
                    );
                })}
            </div>

        );
    };

    return (
        <div className="m-2">
            <PageWrapper
                pageTitle="Lịch Hẹn"
                pageIcon={<FontAwesomeIcon icon={faCalendarDays} />}>
                {loading ? (<Loading />) : (
                    <div className="row">
                        <div className='col m-1'>
                            <div className="card mt-3">
                                <div className="card-body">
                                    <h4 className='card-title'><FontAwesomeIcon icon={faHeartbeat} /> Sắp tới</h4>
                                    {renderAppointmentsByStatus("Pending")} {/* Đang chờ */}
                                </div>
                            </div>
                            <div className="card mt-3">
                                <div className="card-body">
                                    <h4 className='card-title'><FontAwesomeIcon icon={faCheckCircle} /> Hoàn thành</h4>
                                    {renderAppointmentsByStatus("Completed")} {/* Hoàn thành */}
                                </div>
                            </div>
                            <div className="card mt-3">
                                <div className="card-body">
                                    <h4 className='card-title'><FontAwesomeIcon icon={faHeartBroken} /> Đã hủy</h4>
                                    {renderAppointmentsByStatus("Canceled")} {/* Hủy hẹn */}
                                </div>
                            </div>

                        </div>
                        <div className="col m-1">
                            <div className="card mt-3">
                                <div className="card-body calendar-wrapper">
                                    {!isMobile ? (
                                        <BigCalendar
                                            className="calendar"
                                            localizer={localizer}
                                            events={events}
                                            startAccessor="start"
                                            endAccessor="end"
                                            style={{ height: 700 }}
                                            selectable
                                            onSelectSlot={handleSelect}
                                            eventPropGetter={getEventStyle}
                                            formats={{
                                                dayFormat: 'dddd',
                                                monthHeaderFormat: 'MMMM YYYY',
                                                dayHeaderFormat: 'dddd, DD MMMM YYYY',
                                            }}
                                            messages={{
                                                next: 'Tiếp theo',
                                                previous: 'Trước',
                                                today: 'Hôm nay',
                                                month: 'Tháng',
                                                week: 'Tuần',
                                                day: 'Ngày',
                                                agenda: 'Lịch trình',
                                                showMore: (total) => `+${total} cuộc hẹn...`,
                                            }}
                                        />
                                    ) : (
                                        <Calendar
                                            value={selectedDate}
                                            onChange={(date) => {
                                                setSelectedDate(date); // Cập nhật ngày chọn
                                                handleDateChange(date); // Gọi hàm xử lý
                                            }}
                                            tileContent={({ date, view }) => {
                                                if (view === 'month') {
                                                    const event = events.find(
                                                        (event) =>
                                                            new Date(event.start).toDateString() === date.toDateString()
                                                    );

                                                    if (event) {
                                                        const { style } = getEventStyle(event); // Lấy style từ hàm getEventStyle
                                                        return (
                                                            <div
                                                                style={{
                                                                    ...style,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    borderRadius: '10px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <span><FontAwesomeIcon icon={faCircle} title={event.title} /></span> {/* Hiển thị tên sự kiện */}
                                                            </div>
                                                        );
                                                    }
                                                }
                                            }}
                                        />

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>)}

                <Modal show={modalShow} onHide={() => setModalShow(false)} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title><FontAwesomeIcon icon={faCalendarPlus} /> Tạo Nhắc Hẹn</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formSearchCustomer" className="mt-3">
                                <Form.Label>Chọn Khách Hàng</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Tìm kiếm khách hàng..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    ref={el => inputRefs.current[0] = el}
                                    onKeyDown={(e) => handleKeyDown(e, 0)}
                                />
                            </Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="Chọn tất cả khách hàng"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                                className="mt-2"
                            />
                            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                {customers.filter(customer => customer.label.toLowerCase().includes(searchTerm.toLowerCase())).length > 0 ? (
                                    customers.filter(customer => customer.label.toLowerCase().includes(searchTerm.toLowerCase())).map(customer => (
                                        <Form.Check
                                            key={uuidv4()}
                                            type="checkbox"
                                            label={customer.label}
                                            checked={selectedCustomers.includes(customer.value)}
                                            onChange={() => handleCustomerChange(customer.value)}
                                            className="mt-1"
                                        />
                                    ))
                                ) : (
                                    <p>Không có khách hàng nào để hiển thị.</p>
                                )}
                            </div>
                            <Form.Group controlId="formEventTitle">
                                <Form.Label>Chọn Nhắc Hẹn</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={eventTitle}
                                    onChange={(e) => setEventTitle(e.target.value)}
                                    ref={el => inputRefs.current[1] = el}
                                    onKeyDown={(e) => handleKeyDown(e, 1)}
                                >
                                    <option value="">Chọn loại nhắc hẹn...</option>
                                    <option value="Tư vấn">Tư vấn</option>
                                    <option value="Tái khám">Tái khám</option>
                                    <option value="Thực hiện Dịch Vụ">Thực hiện Dịch Vụ</option>
                                    <option value="Thực hiện Liệu Trình">Thực hiện Liệu Trình</option>
                                    <option value="Thay băng cắt chỉ">Thay băng cắt chỉ</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formEventStart">
                                <Form.Label>Thời gian bắt đầu</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    value={moment(eventStart).format('YYYY-MM-DDTHH:mm')}
                                    onChange={(e) => setEventStart(new Date(e.target.value))}
                                    ref={el => inputRefs.current[2] = el}
                                    onKeyDown={(e) => handleKeyDown(e, 2)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formEventEnd">
                                <Form.Label>Thời gian kết thúc</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    value={moment(eventEnd).format('YYYY-MM-DDTHH:mm')}
                                    onChange={(e) => setEventEnd(new Date(e.target.value))}
                                    ref={el => inputRefs.current[3] = el}
                                    onKeyDown={(e) => handleKeyDown(e, 3)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <Button variant="secondary" onClick={() => setModalShow(false)}>
                            Đóng
                        </Button>
                        <Button id="createAppointmentButton" variant="primary" onClick={handleSubmit}>
                            Tạo cuộc hẹn
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={modalStatusShow} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title><FontAwesomeIcon icon={faClockRotateLeft} /> Cập nhật trạng thái</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formStatusSelect">
                            <Form.Label>Vui lòng chọn trạng thái</Form.Label>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}> {/* Sử dụng Flexbox */}
                                <Form.Check
                                    type="radio"
                                    label="Đang chờ"
                                    name="status"
                                    value="Pending"
                                    checked={newStatus === 'Pending'}
                                    onChange={(e) => setNewStatus(e.target.value)}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Hoàn thành"
                                    name="status"
                                    value="Completed"
                                    checked={newStatus === 'Completed'}
                                    onChange={(e) => setNewStatus(e.target.value)}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Hủy hẹn"
                                    name="status"
                                    value="Canceled"
                                    checked={newStatus === 'Canceled'}
                                    onChange={(e) => setNewStatus(e.target.value)}
                                />
                            </div>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Đóng
                        </Button>
                        <Button variant="primary" onClick={handleConfirmStatus}>
                            Xác nhận
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={editModalShow} onHide={handleCloseEditModal} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>Chỉnh sửa cuộc hẹn</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formEditTitle">
                            <Form.Label>Tên cuộc hẹn</Form.Label>
                            <Form.Control
                                as="select"
                                value={editAppointmentTitle}
                                onChange={(e) => setEditAppointmentTitle(e.target.value)}
                            >
                                <option value="">Chọn loại cuộc hẹn...</option>
                                {appointmentTypes.map(type => (
                                    <option key={uuidv4()} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formEditStart">
                            <Form.Label>Thời gian bắt đầu</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={moment(editAppointmentStart).format('YYYY-MM-DDTHH:mm')}
                                onChange={(e) => setEditAppointmentStart(new Date(e.target.value))}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEditEnd">
                            <Form.Label>Thời gian kết thúc</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={moment(editAppointmentEnd).format('YYYY-MM-DDTHH:mm')}
                                onChange={(e) => setEditAppointmentEnd(new Date(e.target.value))}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEditStatus">
                            <Form.Label>Chọn trạng thái mới</Form.Label>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Form.Check
                                    type="radio"
                                    label="Đang chờ"
                                    name="editStatus"
                                    value="Pending"
                                    checked={editStatus === 'Pending'}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Hoàn thành"
                                    name="editStatus"
                                    value="Completed"
                                    checked={editStatus === 'Completed'}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Hủy hẹn"
                                    name="editStatus"
                                    value="Canceled"
                                    checked={editStatus === 'Canceled'}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                />
                            </div>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <Button variant="secondary" onClick={handleCloseEditModal}>
                            Đóng
                        </Button>
                        <Button variant="primary" onClick={() => handleConfirmEdit(currentAppointment._id)}>
                            Cập nhật
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AppoitmentsList
                    onFresh={reFetchAppointments}
                    apiUrl={apiUrl}
                    userId={userId}
                    refreshList={(fetchAppointmentsCallback) => setRefreshAppointments(() => fetchAppointmentsCallback)} />
            </PageWrapper>
        </div>
    );

};

export default Appointments;