const chuHangDonVi = 'không một hai ba bốn năm sáu bảy tám chín'.split(' ');
const chuHangChuc = 'lẻ mười hai ba bốn năm sáu bảy tám chín'.split(' ');
const chuHangTram = 'không một hai ba bốn năm sáu bảy tám chín'.split(' ');

function convert_block_three(number) {
    if (number === '000') return '';
    const _a = number + '';
    switch (_a.length) {
        case 1: return chuHangDonVi[_a];
        case 2: return convert_block_two(_a);
        case 3: {
            const chuc_dv = _a.slice(1, 3) !== '00' ? convert_block_two(_a.slice(1, 3)) : '';
            const tram = chuHangTram[_a[0]] + ' trăm';
            return tram + (chuc_dv ? ' ' + chuc_dv : '');
        }
        default: return 'Giá trị không hợp lệ';
    }
}

function convert_block_two(number) {
    if (number === '00') return '';
    let chuc = number[0];
    let dv = number[1];
    let result = '';

    if (chuc === '0') {
        result = 'lẻ ' + chuHangDonVi[dv];
    } else if (chuc === '1') {
        result = 'mười';
        if (dv === '0') result += '';
        else if (dv === '5') result += ' lăm';
        else result += ' ' + chuHangDonVi[dv];
    } else {
        result = chuHangChuc[chuc] + ' mươi';
        if (dv === '1') result += ' mốt';
        else if (dv === '5') result += ' lăm';
        else if (dv !== '0') result += ' ' + chuHangDonVi[dv];
    }
    return result;
}

const dvBlock = ['', 'nghìn', 'triệu', 'tỷ'];

function to_vietnamese(number) {
    const str = parseInt(number) + '';
    let index = str.length;
    const arr = [];
    const result = [];
    let rsString = '';
    if (index === 0 || str === 'NaN') {
        return '';
    }
    while (index > 0) {
        arr.push(str.substring(Math.max(index - 3, 0), index));
        index -= 3;
    }
    for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i] !== '' && arr[i] !== '000') {
            result.push(convert_block_three(arr[i]));
            if (dvBlock[i]) {
                result.push(dvBlock[i]);
            }
        }
    }
    rsString = result.join(' ');
    return rsString.replace(/  +/g, ' ').trim() + ' đồng chẵn';
}

export { to_vietnamese };
