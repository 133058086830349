import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
    const token = localStorage.getItem("token");
    const savedCurrentRole = localStorage.getItem("currentRole");
    const savedUserId = localStorage.getItem("currentUserId");

    let initialUserId = null;

    if (token) {
        try {
            const decoded = jwtDecode(token);
            initialUserId = decoded.userId;
        } catch (error) {
            console.error("Lỗi khi giải mã token:", error);
        }
    }

    const [currentRole, setCurrentRole] = useState(savedCurrentRole || initialUserId);
    const [userId, setUserId] = useState(savedUserId || initialUserId);

    useEffect(() => {
        if (currentRole) {
            localStorage.setItem("currentRole", currentRole);
        }
        if (userId) {
            localStorage.setItem("currentUserId", userId);
        }
    }, [currentRole, userId]);

    return (
        <RoleContext.Provider value={{ currentRole, setCurrentRole, userId, setUserId }}>
            {children}
        </RoleContext.Provider>
    );
};
