// Import các thư viện cần thiết
import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Form, Row, Col, Button, Container, Table } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClipboard, faHandHoldingMedical, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import ModalConfirm from './ModalConfirm';
import { jwtDecode } from 'jwt-decode';
import SupplyUsageList from './SupplyUsageList';
import SupplyCustomOption from "./SupplyCustomOption";
import { RoleContext } from './RoleContext';

const SupplyUsage = () => {
    const { currentRole } = useContext(RoleContext);
    const [customers, setCustomers] = useState([]);
    const [supplies, setSupplies] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [serviceText, setServiceText] = useState('');
    const [usageList, setUsageList] = useState([{ supplyId: '', soLuong: '', note: '' }]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedTemplateToDelete, setSelectedTemplateToDelete] = useState(null);
    const [reloadList, setReloadList] = useState(false);
    const [totalAmount, setTotalAmount] = useState(null);
    const [instructions, setInstructions] = useState('');
    const inputRefs = useRef([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    const userId = token ? jwtDecode(token).userId : null;

    const fetchData = useCallback(async () => {
        try {
            const [consultationRes, supplyRes, templateRes, customerRes] = await Promise.all([
                axios.get(`${apiUrl}/consultations`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole,    // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    }
                ),
                axios.get(`${apiUrl}/supplies`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole,    // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    }
                ),
                axios.get(`${apiUrl}/supply-templates`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole,    // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    }
                ),
                axios.get(`${apiUrl}/customers`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        createdBy: userId,
                        currentRole,
                        format: "simple", // Lấy danh sách khách hàng theo định dạng đơn giản
                    },
                }),
            ]);

            // Lấy ngày tư vấn mới nhất cho mỗi khách hàng
            const customerConsultationMap = {};
            consultationRes.data.forEach((consultation) => {
                const customerId = consultation.customFields?.hoVaTen; // Lấy ID khách hàng từ customFields.hoVaTen
                const createdAt = new Date(consultation.createdAt); // Ngày tư vấn
                if (!customerConsultationMap[customerId] || customerConsultationMap[customerId] < createdAt) {
                    customerConsultationMap[customerId] = createdAt; // Cập nhật ngày tư vấn mới nhất
                }
            });

            // Sắp xếp ID khách hàng theo ngày tư vấn từ mới đến cũ
            const sortedCustomerIds = Object.keys(customerConsultationMap).sort(
                (a, b) => customerConsultationMap[b] - customerConsultationMap[a]
            );

            // Kết hợp dữ liệu khách hàng từ `customerRes` và sắp xếp theo ngày tư vấn
            const customerMap = customerRes.data.reduce((acc, customer) => {
                if (customer.value) { // Kiểm tra giá trị `value` có tồn tại
                    acc[customer.value] = customer; // Sử dụng `value` làm key
                } else {
                    console.warn("Missing customer value:", customer); // Log cảnh báo nếu `value` bị thiếu
                }
                return acc;
            }, {});

            const customerDetails = sortedCustomerIds
                .filter((id) => customerMap[id]) // Chỉ giữ các khách hàng có trong danh sách
                .map((id) => ({
                    value: id,
                    label: customerMap[id].label, // Sử dụng định dạng đã được backend chuẩn bị
                }));

            setCustomers(customerDetails);

            setSupplies(
                supplyRes.data.map(s => ({
                    value: s._id,
                    label: `${s.customFields.tenVatTu} | Số lượng: ${s.customFields.soLuong || 0} | Giá: ${s.customFields.giaBan?.toLocaleString('vi-VN') || "N/A"}đ`,
                    tenVatTu: s.customFields.tenVatTu, // Thêm trường này
                    soLuong: s.customFields.soLuong, // Thêm trường này
                    donVi: s.customFields.donVi, // Thêm trường này
                    giaBan: s.customFields.giaBan // Thêm trường này
                }))
            );

            setTemplates(templateRes.data.data);
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu:', error);
        }
    }, [apiUrl, userId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (usageList && usageList.length > 0) {
            calculateTotalAmount();
        }
    }, [usageList]); // Chỉ chạy khi usageList thay đổi    

    const refreshSupplyUsageList = () => {
        setReloadList(!reloadList);
    };

    const handleSelectCustomerChange = async (selectedOption) => {
        setSelectedCustomer(selectedOption);

        try {
            // Lấy tất cả tư vấn của khách hàng đã chọn, sắp xếp từ mới nhất đến cũ nhất
            const consultationRes = await axios.get(`${apiUrl}/consultations`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        customerId: selectedOption.value,
                        createdBy: userId,
                        currentRole
                    },
                });

            // Sắp xếp các tư vấn từ mới nhất đến cũ nhất theo `createdAt`
            const sortedConsultations = consultationRes.data
                .filter(consultation => consultation.customFields.hoVaTen === selectedOption.value)
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            if (sortedConsultations.length > 0) {
                // Lấy dịch vụ từ lần tư vấn mới nhất
                const latestConsultation = sortedConsultations[0];
                // Lấy tên dịch vụ từ lần tư vấn mới nhất
                const selectedServicesText = latestConsultation.customFields.tenDichVu
                    .map(item => item.label.split("-")[0].trim())
                    .join(", ");
                setServiceText(selectedServicesText);

            } else {
                // Không có tư vấn nào
                setServiceText('');
            }
        } catch (error) {
            console.error("Lỗi khi lấy thông tin tư vấn của khách hàng:", error);
            setServiceText('');
        }

        // Focus vào ô "Chọn Vật Tư" sau khi chọn khách hàng
        inputRefs.current[1]?.focus();
    };


    const handleKeyDown = (e, refIndex, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (field === 'soLuong') {
                document.getElementById('addMoreSupply')?.focus();
            } else {
                inputRefs.current[refIndex + 1]?.focus();
            }
        }
    };

    const handleAddSupplyUsage = () => {
        setUsageList((prevUsageList) => [
            ...prevUsageList,
            { supplyId: '', soLuong: '', note: '' }
        ]);
        setTimeout(() => {
            inputRefs.current[usageList.length * 3 + 2]?.focus();
        }, 0);
    };

    const handleSupplySelectChange = (selectedOption, index) => {
        const updatedUsageList = [...usageList];
        updatedUsageList[index].supplyId = selectedOption.value;
        setUsageList(updatedUsageList);

        inputRefs.current[index * 3 + 3]?.focus();
    };

    const handleInputChange = (index, field, value) => {
        const updatedUsageList = [...usageList];
        updatedUsageList[index][field] = value;
        setUsageList(updatedUsageList);
    };

    const handleSubmit = async () => {
        if (!selectedCustomer) {
            toast.error('Vui lòng chọn khách hàng');
            return;
        }

        const validUsageList = usageList.filter(
            (item) => item.supplyId && item.soLuong
        );

        if (validUsageList.length === 0) {
            toast.error('Vui lòng nhập vật tư và số lượng');
            return;
        }

        try {
            await axios.post(`${apiUrl}/supplyUsage`, {
                customerId: selectedCustomer.value,
                usageServices: serviceText,
                usageDetails: validUsageList,
                instructions,
                createdBy: userId,
                currentRole
            });

            toast.success('Lưu vật tư thành công!');
            setUsageList([{ supplyId: '', soLuong: '', note: '' }]);
            setSelectedCustomer(null);
            setServiceText('');
            setInstructions('');
            refreshSupplyUsageList();
            updateSupplyStock();
            fetchData();
        } catch (error) {
            console.error('Lỗi khi lưu vật tư:', error);
            toast.error('Lỗi khi lưu vật tư!');
        }
    };

    const handleApplyTemplate = (template) => {
        if (!template.usageList || template.usageList.length === 0) {
            toast.warn('Mẫu này không có chi tiết vật tư để áp dụng.');
            return;
        }

        setUsageList(template.usageList);
        toast.success(`Áp dụng mẫu "${template.name}" thành công!`);
    };

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    const updateSupplyStock = async () => {
        try {
            const stockUpdates = usageList.map(async (item) => {
                const response = await axios.get(`${apiUrl}/supplies/${item.supplyId}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole,    // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    }
                );

                // Kiểm tra nếu dữ liệu trả về không đầy đủ
                if (!response.data || !response.data.customFields || typeof response.data.customFields.soLuong === "undefined") {
                    throw new Error(`Không tìm thấy thông tin số lượng cho vật tư ID: ${item.supplyId}`);
                }

                const currentQuantity = Number(response.data.customFields.soLuong);

                // Tính toán số lượng còn lại
                const updatedQuantity = currentQuantity - Number(item.soLuong);

                if (updatedQuantity < 0) {
                    throw new Error(`Số lượng vật tư "${response.data.customFields.tenVatTu}" không đủ trong kho.`);
                }
                // Cập nhật số lượng mới cho vật tư
                await axios.put(
                    `${apiUrl}/supplies/${item.supplyId}`,
                    { customFields: { soLuong: updatedQuantity }, createdBy: userId, currentRole: currentRole },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
            });

            // Thực hiện cập nhật tất cả các vật tư song song
            await Promise.all(stockUpdates);
            toast.success("Cập nhật kho vật tư thành công!");
        } catch (error) {
            console.error("Lỗi khi cập nhật kho vật tư:", error);
            toast.error("Không thể cập nhật kho vật tư!");
        }
    };

    const calculateTotalAmount = async () => {

        if (!usageList || usageList.length === 0) {
            return;
        }

        const isValidData = usageList.every(
            (usage) => usage.supplyId && Number(usage.soLuong) > 0
        );

        if (!isValidData) {
            return;
        }
        try {
            let totalAmount = 0;
            const totalCostPromises = usageList.map(async (usage, index) => {
                const response = await axios.get(`${apiUrl}/supplies/${usage.supplyId}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole,    // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    }
                );
                const giaBan = response.data.customFields.giaBan;
                const soLuong = Number(usage.soLuong || 0);
                const amount = soLuong * giaBan;
                totalAmount += amount;
            });

            await Promise.all(totalCostPromises);
            setTotalAmount(totalAmount);
        } catch (error) {
            console.error("Lỗi khi tính tổng tiền vật tư:", error);
            toast.error("Không thể tính tổng tiền vật tư!");
        }
    };

    const handleDeleteTemplate = async () => {
        if (!selectedTemplateToDelete) return;

        try {
            await axios.delete(`${apiUrl}/supply-templates/${selectedTemplateToDelete}`);
            setTemplates(templates.filter(template => template._id !== selectedTemplateToDelete));
            toast.success('Xóa mẫu vật tư thành công!');

            setShowConfirmModal(false); // Đảm bảo modal đóng sau khi xóa thành công
        } catch (error) {
            console.error('Lỗi khi xóa mẫu vật tư:', error);
            toast.error('Lỗi khi xóa mẫu vật tư!');
        }
    };


    return (
        <Container fluid className="mt-2">
            <h2><FontAwesomeIcon icon={faClipboard} /> Sử Dụng Vật Tư</h2>
            <Row>
                <Col md={10}>
                    <Form>
                        <Form.Group controlId="selectCustomer" className="mb-3">
                            <Form.Label><FontAwesomeIcon icon={faUser} /> Chọn Khách Hàng</Form.Label>
                            <Select
                                options={customers}
                                value={selectedCustomer}
                                onChange={handleSelectCustomerChange}
                                placeholder="Tìm và chọn khách hàng..."
                                ref={el => inputRefs.current[0] = el}
                            />
                        </Form.Group>
                        <Form.Group controlId="serviceText" className="mb-3">
                            <Form.Label><FontAwesomeIcon icon={faHandHoldingMedical} /> Dịch vụ</Form.Label>
                            <Form.Control
                                type="text"
                                value={serviceText}
                                onChange={(e) => setServiceText(e.target.value)}
                                placeholder="Dịch vụ đã chọn khi Tư vấn"
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                                ref={el => inputRefs.current[1] = el}
                            />
                        </Form.Group>
                        {usageList.map((usage, index) => (
                            <div key={index} className="mb-4">
                                <Row>
                                    <Col md={1} className='m-1 text-center'>
                                        <b>{index + 1}.</b>
                                    </Col>
                                    <Col md={5}>
                                        <Select
                                            options={supplies}
                                            value={supplies.find(s => s.value === usage.supplyId) || null}
                                            onChange={(option) => handleSupplySelectChange(option, index)}
                                            placeholder="Chọn vật tư"
                                            ref={el => inputRefs.current[index * 3 + 2] = el}
                                            components={{ Option: SupplyCustomOption }}

                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Control
                                            type="number"
                                            placeholder="Số lượng"
                                            value={usage.soLuong}
                                            onChange={(e) => handleInputChange(index, 'soLuong', e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(e, index * 3 + 3, 'soLuong')}
                                            ref={el => inputRefs.current[index * 3 + 3] = el}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ghi chú"
                                            value={usage.note}
                                            onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                                            ref={el => inputRefs.current[index * 3 + 4] = el}
                                        />
                                    </Col>
                                </Row>
                                <hr /></div>
                        ))}
                        <Button id="addMoreSupply" variant="success" onClick={handleAddSupplyUsage}>
                            Thêm vật tư +
                        </Button>
                        <Form.Group controlId="instructions" className="mt-3">
                            <Form.Label><FontAwesomeIcon icon={faNoteSticky} /> Lời dặn</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Nhập lời dặn cho việc sử dụng vật tư..."
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                            />
                        </Form.Group>
                        <center>
                            <Button variant="primary" onClick={handleSubmit} className="mt-3 m-1">
                                Tạo phiếu sử dụng Vật Tư
                            </Button>
                        </center>
                    </Form>
                </Col>
                <Col md={2}>
                    <h4 className="text-center">Mẫu Vật Tư</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Tên Mẫu</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.map(template => (
                                <tr key={template._id}>
                                    <td>{template.name}</td>
                                    <td>
                                        <Button size="sm" variant="primary" onClick={() => handleApplyTemplate(template)}>
                                            Áp dụng
                                        </Button>{' '}
                                        <Button
                                            variant="danger"
                                            onClick={() => {
                                                setSelectedTemplateToDelete(template._id);
                                                setShowConfirmModal(true);
                                            }}
                                        >
                                            Xóa
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <strong>Thành tiền: {totalAmount ? totalAmount.toLocaleString('vi-VN') : 0}đ</strong>
            <hr />
            <SupplyUsageList reload={reloadList} refreshSupplyUsageList={refreshSupplyUsageList} refreshTemplateList={fetchData} />
            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={() => handleDeleteTemplate(selectedTemplateToDelete)} // Gọi hàm xóa khi xác nhận
            />

        </Container>
    );
};

export default SupplyUsage;
