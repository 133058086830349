import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Form, Modal, InputGroup } from "react-bootstrap";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import ModalConfirm from "../components/ModalConfirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faArrowAltCircleUp, faCog, faPencil, faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons";

const PartnerFormConfigManager = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [formConfig, setFormConfig] = useState([]);
    const [currentField, setCurrentField] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [fieldToDelete, setFieldToDelete] = useState(null);
    const inputRefs = useRef([]);

    const token = localStorage.getItem("token");
    let userId;

    if (token && typeof token === "string") {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error("Token không hợp lệ:", error);
        }
    }

    // Lấy cấu hình form từ backend
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/partner-form-config/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const sortedFields = (response.data || []).sort((a, b) => a.order - b.order);
                setFormConfig(sortedFields);
            } catch (error) {
                console.error("Lỗi khi tải cấu hình form đối tác:", error);
                toast.error("Không thể tải cấu hình form đối tác!");
            } finally {
                setLoading(false);
            }
        };

        fetchFormConfig();
    }, [apiUrl, userId, token]);

    const confirmDeleteField = (fieldId) => {
        const field = formConfig.find((f) => f._id === fieldId);
        if (!field) {
            toast.error("Không tìm thấy trường để xóa.");
            return;
        }
        setFieldToDelete(field);
        setShowConfirmModal(true);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleDeleteField = async () => {
        try {
            await axios.delete(`${apiUrl}/partner-form-config/${userId}/${fieldToDelete._id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            toast.success("Xóa trường thành công!");
            refreshFormConfig(); // Làm mới danh sách sau khi xóa
            setShowConfirmModal(false);
        } catch (error) {
            console.error("Lỗi khi xóa trường:", error);
            toast.error("Không thể xóa trường.");
        }
    };

    const refreshFormConfig = async () => {
        try {
            const response = await axios.get(`${apiUrl}/partner-form-config/${userId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setFormConfig(response.data);
        } catch (error) {
            console.error("Lỗi khi tải lại cấu hình form đối tác:", error);
            toast.error("Không thể tải lại cấu hình.");
        }
    };

    const handleAddField = async () => {
        if (!currentField?.fieldName || !currentField?.fieldLabel || !currentField?.fieldType) {
            toast.error("Tên trường, nhãn hiển thị và loại dữ liệu là bắt buộc.");
            return;
        }

        try {
            const response = await axios.post(
                `${apiUrl}/partner-form-config/`,
                { ...currentField, userId },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            toast.success("Thêm trường mới thành công!");
            setFormConfig([...formConfig, response.data]); // Cập nhật danh sách
            setShowModal(false);
        } catch (error) {
            console.error("Lỗi khi thêm trường:", error);
            toast.error("Không thể thêm trường.");
        }
    };


    const handleUpdateField = async () => {
        try {
            setLoading(true);
            const payload = {
                ...currentField, // Dữ liệu trực tiếp từ currentField
            };

            await axios.put(
                `${apiUrl}/partner-form-config/${userId}/${currentField._id}`,
                payload, // Gửi payload trực tiếp
                { headers: { Authorization: `Bearer ${token}` } }
            );

            toast.success("Cập nhật trường thành công!");
            refreshFormConfig(); // Làm mới danh sách
            setShowModal(false);
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi cập nhật trường:", error);
            toast.error("Không thể cập nhật trường.");
        }
    };


    const moveField = (fieldId, direction) => {
        const index = formConfig.findIndex((field) => field._id === fieldId);
        if (index === -1) return;

        const targetIndex = index + direction;
        if (targetIndex < 0 || targetIndex >= formConfig.length) return;

        const updatedFormConfig = [...formConfig];
        const temp = updatedFormConfig[index];
        updatedFormConfig[index] = updatedFormConfig[targetIndex];
        updatedFormConfig[targetIndex] = temp;

        const reorderedFields = updatedFormConfig.map((field, idx) => ({
            ...field,
            order: idx + 1,
        }));

        setFormConfig(reorderedFields); // Chỉ cập nhật state, không gửi API
    };

    const handleSaveConfig = async () => {
        try {
            const reorderedFields = formConfig.map((field, index) => ({
                ...field,
                order: index + 1, // Cập nhật thứ tự
            }));

            await axios.put(
                `${apiUrl}/partner-form-config/${userId}`,
                { formConfig: reorderedFields },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            toast.success("Lưu cấu hình thành công!");
            refreshFormConfig(); // Làm mới danh sách sau khi lưu
        } catch (error) {
            console.error("Lỗi khi lưu cấu hình:", error);
            toast.error("Không thể lưu cấu hình.");
        }
    };


    return (
        <div className="container-fluid">
            <h3><FontAwesomeIcon icon={faCog} className="me-2" /> Nhập Đối Tác</h3>
            <div className="mb-3 text-center">
                <Button
                    variant="primary"
                    onClick={() => {
                        setCurrentField({
                            fieldName: "",
                            fieldLabel: "",
                            fieldType: "text",
                            isRequired: false,
                            isTogglable: false,
                        });
                        setShowModal(true);
                    }}
                >
                    <FontAwesomeIcon icon={faPlusCircle} /> Thêm Trường Mới
                </Button>
                <Button
                    className="m-1"
                    variant="success"
                    onClick={handleSaveConfig}
                    disabled={loading} // Vô hiệu hóa khi đang tải
                >
                    <FontAwesomeIcon icon={faSave} /> {loading ? "Đang lưu..." : "Lưu cấu hình"}
                </Button>
            </div>

            <Table striped bordered hover>
                <thead className="text-center">
                    <tr>
                        <th>#</th>
                        <th>Tên Trường</th>
                        <th>Nhãn Hiển Thị</th>
                        <th>Loại</th>
                        <th>Bắt Buộc</th>
                        <th>Ẩn/Hiện</th>
                        <th>Sắp xếp</th>
                        <th>Hành Động</th>
                    </tr>
                </thead>
                <tbody>
                    {formConfig.map((field, index) => (
                        <tr key={field._id}>
                            <td>{index + 1}</td>
                            <td>{field.fieldName}</td>
                            <td>{field.fieldLabel}</td>
                            <td>{field.fieldType}</td>
                            <td>{field.isRequired ? "Có" : "Không"}</td>
                            <td>{field.isTogglable ? "Có" : "Không"}</td>
                            <td className="text-center">
                                <Button
                                    variant="light"
                                    disabled={index === 0}
                                    onClick={() => moveField(field._id, -1)}
                                >
                                    <FontAwesomeIcon icon={faArrowAltCircleUp} />
                                </Button>
                                <Button
                                    variant="light"
                                    disabled={index === formConfig.length - 1}
                                    onClick={() => moveField(field._id, 1)}
                                >
                                    <FontAwesomeIcon icon={faArrowAltCircleDown} />
                                </Button>
                            </td>
                            <td>
                                <Button
                                    variant="warning"
                                    size="sm"
                                    onClick={() => {
                                        setCurrentField({ ...field });
                                        setShowModal(true);
                                    }}
                                    className="m-1"
                                >
                                    Sửa
                                </Button>
                                <Button
                                    className="m-1"
                                    variant="danger"
                                    size="sm"
                                    onClick={() => confirmDeleteField(field._id)}
                                >
                                    Xóa
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal thêm/sửa */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentField?._id ? <>
                        <FontAwesomeIcon icon={faPencil} className="me-2" /> Sửa Trường</> : <>
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Thêm Trường Mới</>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Tên Trường */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Trường</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={currentField?.fieldName || ""}
                                onChange={(e) =>
                                    setCurrentField((prev) => ({ ...prev, fieldName: e.target.value }))
                                }
                                placeholder="Nhập tên trường"
                                ref={(el) => (inputRefs.current[0] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                                autoFocus
                            />
                        </InputGroup>

                        {/* Nhãn Hiển Thị */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Nhãn Hiển Thị</InputGroup.Text>
                            <Form.Control
                                type="text"
                                value={currentField?.fieldLabel || ""}
                                onChange={(e) =>
                                    setCurrentField((prev) => ({ ...prev, fieldLabel: e.target.value }))
                                }
                                placeholder="Nhập nhãn hiển thị"
                                ref={(el) => (inputRefs.current[1] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                            />
                        </InputGroup>

                        {/* Loại Dữ Liệu */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Loại Dữ Liệu</InputGroup.Text>
                            <Form.Select
                                value={currentField?.fieldType || ""}
                                onChange={(e) => {
                                    setCurrentField((prev) => ({ ...prev, fieldType: e.target.value }))
                                    handleKeyDown(e, 2)
                                }
                                }

                                ref={(el) => (inputRefs.current[2] = el)}
                            >
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="date">Date</option>
                                <option value="textarea">Textarea</option>
                                <option value="select">Select</option>
                                <option value="checkbox">Checkbox</option>
                            </Form.Select>
                        </InputGroup>
                        {currentField?.fieldType === "select" && (
                            <div className="mb-3">
                                <Form.Label>Các Mục Tùy Chọn</Form.Label>
                                <div>
                                    {currentField.options?.map((option, index) => (
                                        <InputGroup className="mb-2" key={index}>
                                            <Form.Control
                                                type="text"
                                                value={option}
                                                onChange={(e) => {
                                                    const updatedOptions = [...currentField.options];
                                                    updatedOptions[index] = e.target.value;
                                                    setCurrentField((prev) => ({
                                                        ...prev,
                                                        options: updatedOptions,
                                                    }));
                                                }}
                                                placeholder={`Tùy chọn ${index + 1}`}
                                            />
                                            <Button
                                                variant="danger"
                                                onClick={() => {
                                                    const updatedOptions = currentField.options.filter(
                                                        (_, idx) => idx !== index
                                                    );
                                                    setCurrentField((prev) => ({
                                                        ...prev,
                                                        options: updatedOptions,
                                                    }));
                                                }}
                                            >
                                                Xóa
                                            </Button>
                                        </InputGroup>
                                    ))}
                                    <Button
                                        variant="success"
                                        onClick={() =>
                                            setCurrentField((prev) => ({
                                                ...prev,
                                                options: [...(prev.options || []), ""],
                                            }))
                                        }
                                    >
                                        Thêm Tùy Chọn
                                    </Button>
                                </div>
                            </div>
                        )}
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="Bắt Buộc"
                                checked={currentField?.isRequired || false}
                                onChange={(e) =>
                                    setCurrentField((prev) => ({ ...prev, isRequired: e.target.checked }))
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="Tùy Chọn Hiển Thị"
                                checked={currentField?.isTogglable || false}
                                onChange={(e) =>
                                    setCurrentField((prev) => ({ ...prev, isTogglable: e.target.checked }))
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Đóng
                    </Button>
                    <Button
                        variant="primary"
                        onClick={currentField?._id ? handleUpdateField : handleAddField}
                    >
                        {currentField?._id ? "Cập Nhật" : "Thêm"}
                    </Button>

                </Modal.Footer>
            </Modal>

            {/* Modal xác nhận xóa */}
            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDeleteField}
            />
        </div>
    );
};

export default PartnerFormConfigManager;
