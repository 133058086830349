import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSave } from "@fortawesome/free-solid-svg-icons";

const AddCustomer = ({ show, handleClose, fetchData, selectedCustomer, isEditMode, permissions, currentRole }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [formConfig, setFormConfig] = useState([]);
    const [triggerCalculation, setTriggerCalculation] = useState(false);
    const [customerData, setCustomerData] = useState({ customFields: {} });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const fieldRefs = useRef([]);
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    const userId = decoded.userId;
    const hasPermission = (permission) => permissions?.[permission] || false;

    useEffect(() => {
        if (show) {
            fetchFormConfig();
            if (isEditMode && selectedCustomer) {
                setCustomerData({
                    customFields: selectedCustomer.customFields || {},
                });
            } else {
                resetForm();
            }
            // Auto-focus vào trường đầu tiên
            setTimeout(() => {
                if (fieldRefs.current[0]) {
                    fieldRefs.current[0].focus();
                }
            }, 200); // Đợi modal render xong
        }
    }, [show, isEditMode, selectedCustomer]);

    const fetchFormConfig = async () => {
        try {
            const response = await axios.get(`${apiUrl}/customer-form-config/${currentRole}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const config = response.data || [];
            setFormConfig(config);

            const updatedConfig = [...config];
            for (let i = 0; i < config.length; i++) {
                const field = config[i];
                if (field.fieldType === "apiSelect" && field.apiEndpoint) {
                    const resolvedEndpoint = field.apiEndpoint
                        .replace("${apiUrl}", apiUrl)
                        .replace("${userId}", userId);
                    const apiResponse = await axios.get(resolvedEndpoint, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                }
            }
            setFormConfig(updatedConfig);
        } catch (error) {
            toast.error("Lỗi khi tải cấu hình form!");
            console.error("Error fetching formConfig:", error);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (fieldRefs.current[index + 1]) {
                fieldRefs.current[index + 1].focus();
            } else {
                handleSave();
            }
        }
    };

    const handleBlur = (field) => {
        if (field.isRequired && !customerData.customFields[field.fieldName]) {
            setErrors((prev) => ({
                ...prev,
                [field.fieldName]: `${field.fieldLabel} là bắt buộc.`,
            }));
        } else {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[field.fieldName];
                return newErrors;
            });
        }
    };

    const handleCustomFieldChange = (fieldName, value) => {
        const fieldType = formConfig.find((field) => field.fieldName === fieldName)?.fieldType;

        // Xử lý giá trị nhập vào dựa trên loại dữ liệu
        const updatedCustomFields = {
            ...customerData.customFields,
            [fieldName]: fieldType === "date" ? new Date(value).toISOString() : value, // Lưu ngày dạng ISO
        };

        // Kiểm tra và cập nhật các trường tính toán phụ thuộc
        formConfig.forEach((field) => {
            if (
                field.fieldType === "calculated" &&
                field.dependencies?.includes(fieldName) &&
                field.formula
            ) {
                updatedCustomFields[field.fieldName] = handleCalculateField(
                    field,
                    updatedCustomFields
                );
            }
        });

        setCustomerData((prev) => ({
            ...prev,
            customFields: updatedCustomFields,
        }));
    };

    const handleCalculateField = (field, customFields) => {
        try {
            const formulaFunction = new Function(...field.dependencies, `return ${field.formula};`);
            const dependencyValues = field.dependencies.map((dep) => {
                const depValue = customFields[dep];
                if (!depValue) throw new Error(`Trường ${dep} chưa có giá trị.`);

                // Xử lý giá trị ngày
                if (field.fieldType === "date" || depValue.includes("-")) {
                    return new Date(depValue).getTime(); // Chuyển ngày về timestamp
                }
                return parseFloat(depValue); // Xử lý các giá trị số
            });

            return formulaFunction(...dependencyValues);
        } catch (error) {
            console.error(`Lỗi khi tính toán trường ${field.fieldName}:`, error);
            return "Lỗi công thức";
        }
    };

    useEffect(() => {
        if (!triggerCalculation) return; // Chỉ chạy khi trigger được bật

        formConfig.forEach((field) => {
            if (
                field.fieldType === "calculated" &&
                field.formula &&
                Array.isArray(field.dependencies)
            ) {
                try {
                    const dependencyValues = field.dependencies.map((dep) => {
                        const depValue = customerData.customFields[dep];
                        if (!depValue) throw new Error(`Trường ${dep} chưa có giá trị.`);

                        // Kiểm tra và xử lý giá trị ngày
                        if (depValue.includes("-")) {
                            return new Date(depValue).getTime(); // Chuyển ngày về timestamp
                        }
                        return parseFloat(depValue);
                    });

                    const formulaFunction = new Function(
                        ...field.dependencies,
                        `return ${field.formula};`
                    );

                    const calculatedValue = formulaFunction(...dependencyValues);

                    setCustomerData((prev) => ({
                        ...prev,
                        customFields: {
                            ...prev.customFields,
                            [field.fieldName]: isNaN(calculatedValue)
                                ? "Lỗi công thức"
                                : calculatedValue,
                        },
                    }));
                } catch (error) {
                    console.error(`Lỗi khi tính toán trường ${field.fieldName}:`, error.message);
                    setCustomerData((prev) => ({
                        ...prev,
                        customFields: {
                            ...prev.customFields,
                            [field.fieldName]: "Chưa nhập đủ dữ liệu",
                        },
                    }));
                }
            }
        });

        setTriggerCalculation(false); // Reset trigger sau khi xử lý xong
    }, [triggerCalculation]);


    // Gọi hàm khi cần kích hoạt lại tính toán
    const handleDependencyChange = () => {
        setTriggerCalculation(true);
    };

    const handleSave = async () => {
        if (!isEditMode && !hasPermission("canAdd")) {
            toast.error("Bạn không có quyền thêm khách hàng.");
            return;
        }
        if (isEditMode && !hasPermission("canEdit")) {
            toast.error("Bạn không có quyền sửa khách hàng.");
            return;
        }

        // Kiểm tra lỗi trên tất cả các trường
        const newErrors = {};
        formConfig.forEach((field) => {
            if (field.isRequired && !customerData.customFields[field.fieldName]) {
                newErrors[field.fieldName] = `${field.fieldLabel} là bắt buộc.`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors); // Hiển thị lỗi
            toast.error("Vui lòng điền đầy đủ các trường bắt buộc.");
            return;
        }

        try {
            setLoading(true);
            if (isEditMode) {
                await axios.put(
                    `${apiUrl}/customers/${selectedCustomer._id}`,
                    { ...customerData, createdBy: userId, currentRole: currentRole },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                fetchData();
                handleClose();
                toast.success("Chỉnh sửa thông tin thành công!");
                setLoading(false);
            } else {
                setLoading(true);
                await axios.post(
                    `${apiUrl}/customers`,
                    { ...customerData, createdBy: userId, currentRole: currentRole },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                fetchData();
                handleClose();
                toast.success("Thêm bệnh nhân thành công!");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            toast.error("Đã xảy ra lỗi. Vui lòng thử lại.");
        }
    };

    const resetForm = () => {
        setCustomerData({ customFields: {} });
        setErrors({});
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? "Sửa Bệnh Nhân" : "Thêm Bệnh Nhân"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Render các trường động */}
                    {formConfig.map((field, index) => (
                        <Form.Group key={field.fieldName} className="mb-3" style={{ width: "100%" }}>
                            <InputGroup>
                                <InputGroup.Text>{field.fieldLabel}</InputGroup.Text>
                                {field.fieldType === "text" && (
                                    <Form.Control
                                        type="text"
                                        ref={(el) => (fieldRefs.current[index] = el)} // Gắn ref
                                        value={customerData.customFields[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleCustomFieldChange(field.fieldName, e.target.value)
                                        }
                                        onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        onKeyDown={(e) => handleKeyDown(e, index)} // Nhảy focus
                                    />
                                )}
                                {field.fieldType === "textinline" && (
                                    <div className="d-flex flex-wrap">
                                        {field.fields.map((inlineField) => (
                                            <Form.Group key={inlineField.fieldName} className="mb-3 me-3">
                                                <Form.Label>{inlineField.fieldLabel}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={customerData.customFields[inlineField.fieldName] || ""}
                                                    onChange={(e) =>
                                                        handleCustomFieldChange(inlineField.fieldName, e.target.value)
                                                    }
                                                    placeholder={`Nhập ${inlineField.fieldLabel}`}
                                                />
                                            </Form.Group>
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "number" && (
                                    <Form.Control
                                        type="number"
                                        ref={(el) => (fieldRefs.current[index] = el)} // Gắn ref
                                        value={customerData.customFields[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleCustomFieldChange(field.fieldName, e.target.value)
                                        }
                                        onBlur={() => {
                                            handleBlur(field)
                                            handleDependencyChange()
                                        }} // Kiểm tra khi mất focus
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        onKeyDown={(e) => handleKeyDown(e, index)} // Nhảy focus
                                    />
                                )}
                                {field.fieldType === "numberinline" && (
                                    <div className="d-flex flex-wrap">
                                        {field.fields.map((inlineField) => (
                                            <Form.Group key={inlineField.fieldName} className="mb-3 me-3">
                                                <Form.Label>{inlineField.fieldLabel}</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={customerData.customFields[inlineField.fieldName] || ""}
                                                    onChange={(e) =>
                                                        handleCustomFieldChange(inlineField.fieldName, e.target.value)
                                                    }
                                                    placeholder={`Nhập ${inlineField.fieldLabel}`}
                                                />
                                            </Form.Group>
                                        ))}
                                    </div>
                                )}

                                {field.fieldType === "date" && (
                                    <Form.Control
                                        type="date"
                                        ref={(el) => (fieldRefs.current[index] = el)}
                                        value={
                                            customerData.customFields[field.fieldName]
                                                ? new Date(customerData.customFields[field.fieldName]).toISOString().split("T")[0]
                                                : ""
                                        }
                                        onChange={(e) => handleCustomFieldChange(field.fieldName, e.target.value)}
                                        onBlur={() => {
                                            handleBlur(field);
                                            handleDependencyChange();
                                        }} // Kiểm tra khi mất focus
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                )}
                                {field.fieldType === "dateinline" && (
                                    <div className="d-flex flex-wrap">
                                        {field.fields.map((inlineField) => (
                                            <Form.Group key={inlineField.fieldName} className="mb-3 me-3">
                                                <Form.Label>{inlineField.fieldLabel}</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={customerData.customFields[inlineField.fieldName] || ""}
                                                    onChange={(e) =>
                                                        handleCustomFieldChange(inlineField.fieldName, e.target.value)
                                                    }
                                                    placeholder={`Chọn ${inlineField.fieldLabel}`}
                                                />
                                            </Form.Group>
                                        ))}
                                    </div>
                                )}

                                {field.fieldType === "calculated" && (
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={customerData.customFields[field.fieldName] || "Chưa có dữ liệu"} // Hiển thị kết quả tính toán hoặc thông báo nếu chưa đủ dữ liệu
                                        onFocus={(e) => handleCalculateField(field, e)} // Tính lại giá trị khi focus nếu cần
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi nếu có
                                        placeholder={`Tự động tính toán: ${field.fieldLabel}`} // Placeholder hướng dẫn
                                    />
                                )}

                                {field.fieldType === "select" && (
                                    <>
                                        {field.options.length <= 5 ? (
                                            <Form.Select
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        width: "80%",
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        minHeight: "38px",
                                                    }),
                                                }}
                                                ref={(el) => (fieldRefs.current[index] = el)}
                                                value={customerData.customFields[field.fieldName] || ""}
                                                onChange={(e) =>
                                                    handleCustomFieldChange(field.fieldName, e.target.value)
                                                }
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            >
                                                <option value="">-- Chọn {field.fieldLabel} --</option>
                                                {field.options.map((option, idx) => (
                                                    <option key={idx} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        ) : (
                                            <Select
                                                options={field.options.map((opt) => ({
                                                    label: opt,
                                                    value: opt,
                                                }))}
                                                value={
                                                    customerData.customFields[field.fieldName]
                                                        ? {
                                                            label: customerData.customFields[field.fieldName],
                                                            value: customerData.customFields[field.fieldName],
                                                        }
                                                        : null
                                                }
                                                onChange={(selected) =>
                                                    handleCustomFieldChange(
                                                        field.fieldName,
                                                        selected?.value || ""
                                                    )
                                                }
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                                placeholder={`Chọn ${field.fieldLabel}`}
                                                isClearable
                                                isSearchable
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        width: "80%",
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        minHeight: "38px",
                                                    }),
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                                {field.fieldType === "selectmulti" && (
                                    <div className="">
                                        {/* Kiểm tra nếu là vatTu */}
                                        {field.fieldName === "vatTu" ? (
                                            <>
                                                {/* Multi-select input cho vatTu */}
                                                <Select
                                                    styles={{
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: "80%",
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            minHeight: "38px",
                                                        }),
                                                    }}
                                                    options={field.options?.map((option) => ({
                                                        value: option,
                                                        label: option,
                                                    }))} // Chuyển đổi danh sách thành {value, label}
                                                    value={
                                                        Array.isArray(customerData.customFields[field.fieldName])
                                                            ? customerData.customFields[field.fieldName].map((item) => ({
                                                                value: item.name,
                                                                label: item.name,
                                                            }))
                                                            : []
                                                    } // Hiển thị các lựa chọn đã chọn
                                                    onChange={(selected) => {
                                                        const updatedSelections = selected
                                                            ? selected.map((option) => {
                                                                const existingItems = Array.isArray(customerData.customFields[field.fieldName])
                                                                    ? customerData.customFields[field.fieldName]
                                                                    : [];
                                                                const existingItem = existingItems.find((item) => item.name === option.value);
                                                                return {
                                                                    name: option.value,
                                                                    quantity: existingItem?.quantity || 1, // Số lượng mặc định là 1
                                                                };
                                                            })
                                                            : [];
                                                        setCustomerData((prev) => ({
                                                            ...prev,
                                                            customFields: {
                                                                ...prev.customFields,
                                                                [field.fieldName]: updatedSelections,
                                                            },
                                                        }));
                                                    }}
                                                    placeholder={`Chọn ${field.fieldLabel}`}
                                                    isMulti // Bật chế độ chọn nhiều giá trị
                                                    isSearchable // Bật ô tìm kiếm
                                                    isClearable // Thêm nút xóa tất cả lựa chọn
                                                />
                                                {/* Hiển thị danh sách vatTu đã chọn với số lượng */}
                                                <div style={{ marginTop: "10px" }}>
                                                    {Array.isArray(customerData.customFields[field.fieldName]) &&
                                                        customerData.customFields[field.fieldName].map((item, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    marginBottom: "5px",
                                                                }}
                                                            >
                                                                <span style={{ marginRight: "10px" }}>{item.name}:</span>
                                                                <input
                                                                    type="number"
                                                                    value={item.quantity}
                                                                    min="1"
                                                                    onChange={(e) => {
                                                                        const updatedSelections = [
                                                                            ...customerData.customFields[field.fieldName],
                                                                        ];
                                                                        updatedSelections[index].quantity = parseInt(e.target.value, 10) || 1;
                                                                        setCustomerData((prev) => ({
                                                                            ...prev,
                                                                            customFields: {
                                                                                ...prev.customFields,
                                                                                [field.fieldName]: updatedSelections,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    style={{
                                                                        width: "60px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                />
                                                                <span>cái</span>
                                                            </div>
                                                        ))}
                                                </div>
                                            </>
                                        ) : (
                                            // Multi-select input bình thường cho các trường khác
                                            <Select
                                                styles={{
                                                    container: (provided) => ({
                                                        ...provided,
                                                        maxWidth: "100%",
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        minHeight: "38px",
                                                    }),
                                                }}
                                                options={field.options?.map((option) => ({
                                                    value: option,
                                                    label: option,
                                                }))} // Chuyển đổi danh sách thành {value, label}
                                                value={
                                                    typeof customerData.customFields[field.fieldName] === "string"
                                                        ? customerData.customFields[field.fieldName]
                                                            .split(", ") // Chuyển chuỗi thành mảng
                                                            .map((value) => ({
                                                                value: value.trim(),
                                                                label: value.trim(),
                                                            }))
                                                        : []
                                                } // Hiển thị các lựa chọn đã chọn
                                                onChange={(selected) => {
                                                    const selectedValues = selected ? selected.map((option) => option.value) : [];
                                                    const joinedValues = selectedValues.join(", "); // Nối các giá trị thành chuỗi với dấu phẩy
                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: joinedValues, // Lưu chuỗi vào customFields
                                                        },
                                                    }));
                                                }}
                                                placeholder={`Chọn ${field.fieldLabel}`}
                                                isMulti // Bật chế độ chọn nhiều giá trị
                                                isSearchable // Bật ô tìm kiếm
                                                isClearable // Thêm nút xóa tất cả lựa chọn
                                            />
                                        )}
                                    </div>
                                )}
                                {field.fieldType === "apiSelect" && (
                                    <Select
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                width: "80%",
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                minHeight: "38px",
                                            }),
                                        }}
                                        options={field.options}
                                        ref={(el) => (fieldRefs.current[index] = el)} // Không thể focus Select
                                        value={field.options.find(
                                            (opt) =>
                                                opt.value ===
                                                customerData.customFields[field.fieldName]
                                        )}
                                        onChange={(selected) =>
                                            handleCustomFieldChange(field.fieldName, selected?.value || "")
                                        }
                                        placeholder={`Chọn ${field.fieldLabel}`}
                                        isClearable
                                        isSearchable
                                    />
                                )}
                                {field.fieldType === "checkbox" && (
                                    <div>
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="checkbox"
                                                label={option}
                                                value={option}
                                                checked={
                                                    customerData.customFields[field.fieldName]?.includes(option) ||
                                                    false
                                                }
                                                onChange={(e) => {
                                                    const selectedOptions =
                                                        customerData.customFields[field.fieldName] || [];
                                                    const updatedOptions = e.target.checked
                                                        ? [...selectedOptions, option]
                                                        : selectedOptions.filter((opt) => opt !== option);

                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: updatedOptions,
                                                        },
                                                    }));
                                                }}
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                            />
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "checkboxinline" && (
                                    <div className="d-flex align-items-center flex-wrap">
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="checkbox"
                                                inline // Đặt inline để các checkbox nằm cùng dòng
                                                label={option}
                                                value={option}
                                                checked={
                                                    customerData.customFields[field.fieldName]?.includes(option) || false
                                                }
                                                onChange={(e) => {
                                                    const selectedOptions = customerData.customFields[field.fieldName] || [];
                                                    const updatedOptions = e.target.checked
                                                        ? [...selectedOptions, option]
                                                        : selectedOptions.filter((opt) => opt !== option);

                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: updatedOptions,
                                                        },
                                                    }));
                                                }}
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                                className="me-3" // Thêm khoảng cách giữa các checkbox
                                            />
                                        ))}
                                    </div>
                                )}

                                {field.fieldType === "radioinline" && (
                                    <div className="d-flex align-items-center">
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="radio"
                                                inline
                                                label={option}
                                                name={field.fieldName}
                                                value={option}
                                                checked={customerData.customFields[field.fieldName] === option}
                                                onChange={(e) => {
                                                    const selectedOption = e.target.value;
                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: selectedOption,
                                                        },
                                                    }));
                                                }}
                                                className="ms-2"
                                            />
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "radio" && (
                                    <div>
                                        {field.options.map((option, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="radio"
                                                label={option}
                                                name={field.fieldName} // Đảm bảo tất cả radio cùng nhóm có cùng name
                                                value={option}
                                                checked={customerData.customFields[field.fieldName] === option} // So sánh với giá trị đã chọn
                                                onChange={(e) => {
                                                    const selectedOption = e.target.value; // Lấy giá trị được chọn
                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: selectedOption, // Gán giá trị radio đã chọn
                                                        },
                                                    }));
                                                }}
                                                onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                                isInvalid={!!errors[field.fieldName]} // Highlight lỗi nếu có
                                            />
                                        ))}
                                    </div>
                                )}
                                {field.fieldType === "file" && (
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        customFields: {
                                                            ...prev.customFields,
                                                            [field.fieldName]: reader.result, // Lưu file dưới dạng base64
                                                        },
                                                    }));
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                )}
                                {field.fieldType === "textarea" && (
                                    <Form.Control
                                        as="textarea" // Đặt là textarea
                                        ref={(el) => (fieldRefs.current[index] = el)} // Gắn ref
                                        rows={3} // Số dòng hiển thị mặc định
                                        value={customerData.customFields[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleCustomFieldChange(field.fieldName, e.target.value)
                                        }
                                        onBlur={() => handleBlur(field)} // Kiểm tra khi mất focus
                                        isInvalid={!!errors[field.fieldName]} // Highlight lỗi
                                        placeholder={`Nhập ${field.fieldLabel}`}
                                        onKeyDown={(e) => handleKeyDown(e, index)} // Nhảy focus
                                    />
                                )}
                                <Form.Control.Feedback type="invalid">
                                    {errors[field.fieldName]} {/* Hiển thị lỗi */}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleSave}
                    disabled={loading}>
                    {isEditMode ? (<>
                        <FontAwesomeIcon icon={faCheckCircle} className="me-1" />{loading ? ('Đang cập nhật...') : ('Cập nhật')}</>) : (<>
                            <FontAwesomeIcon icon={faSave} className="me-1" />{loading ? ('Đang lưu...') : ('Lưu')}</>)}
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default AddCustomer;
