import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import formatDates from "../components/FormatDates";
import { RoleContext } from "../components/RoleContext";
import { v4 as uuidv4 } from 'uuid';

const ActivityLogPage = () => {
    const [activityLogs, setActivityLogs] = useState([]);
    const { currentRole } = useContext(RoleContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");

    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    useEffect(() => {
        const fetchActivityLogs = async () => {
            try {
                const response = await axios.get(`${apiUrl}/activity-log`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { userId, currentRole },
                });
                setActivityLogs(response.data.logs);
            } catch (error) {
                console.error("Lỗi khi lấy nhật ký hoạt động:", error);
                toast.error("Không thể tải nhật ký hoạt động!");
            }
        };

        fetchActivityLogs();
    }, [token, apiUrl, userId, currentRole]);

    const handleDeleteUserLogs = async () => {
        try {
            const response = await axios.delete(`${apiUrl}/activity-log/user/${userId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success(response.data.message);
            // Cập nhật danh sách nhật ký sau khi xóa
            setActivityLogs((prevLogs) =>
                prevLogs.filter(
                    (log) => !(log.userId._id === userId && log.ownerId._id === userId)
                )
            );
        } catch (error) {
            console.error("Lỗi khi xóa nhật ký cá nhân:", error);
            toast.error("Không thể xóa nhật ký cá nhân!");
        }
    };

    const handleDeleteOwnerLogs = async () => {
        try {
            const response = await axios.delete(`${apiUrl}/activity-log/owner/${currentRole}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success(response.data.message);
            // Cập nhật danh sách nhật ký sau khi xóa
            setActivityLogs((prevLogs) =>
                prevLogs.filter((log) => log.ownerId._id !== currentRole)
            );
        } catch (error) {
            console.error("Lỗi khi xóa tất cả nhật ký:", error);
            toast.error("Không thể xóa tất cả nhật ký!");
        }
    };

    return (
        <div className="container-fluid">
            <h3>Nhật Ký Hoạt Động</h3>
            <div className='d-flex justify-content-center'>
                <Button
                    variant="danger"
                    className="m-2"
                    onClick={handleDeleteUserLogs} // Xóa nhật ký cá nhân
                >
                    Xóa Nhật Ký Của Bạn
                </Button>
                {currentRole === userId && (
                    <Button
                        variant="warning"
                        className="m-2"
                        onClick={handleDeleteOwnerLogs} // Xóa toàn bộ nhật ký
                    >
                        Xóa Tất Cả Nhật Ký
                    </Button>
                )}
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Người Thực Hiện</th>
                        <th>Chủ Sở Hữu</th>
                        <th>Hành Động</th>
                        <th>Chi Tiết</th>
                        <th>Thời Gian</th>
                    </tr>
                </thead>
                <tbody>
                    {activityLogs.map((log, index) => {
                        return (
                            <tr key={uuidv4()}>

                                <td>{activityLogs.length - index}</td>
                                <td>{log.userId?.name || "Không rõ"}</td>
                                <td>{log.ownerId?.name || "Không rõ"}</td>
                                <td>{log.action}</td>
                                <td>{log.details || "Không có chi tiết"}</td>
                                <td>{formatDates(log.timestamp)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default ActivityLogPage;
