import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
import { RoleContext } from '../components/RoleContext';
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faUserPlus, faTrash, faEye, faEyeSlash, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, FormControl, Card, Badge } from "react-bootstrap";
import AddCustomer from "../components/AddCustomer";
import Loading from "../components/Loading";
import ModalConfirm from "../components/ModalConfirm";
import { toast } from "react-toastify";
import PageWrapper from "../components/PageWrapper";
import formatDate from "../components/FormatDate";
import ConsultationModal from "../components/ConsultationModal";
import noManProfile from '../assets/images/noManProfile.png';
import noWomanProfile from '../assets/images/noWomanProfile.png';

const CustomerList = () => {
    const { currentRole } = useContext(RoleContext);
    const [showConsultationModal, setShowConsultationModal] = useState(false); // Điều khiển modal
    const [consultationCustomerIds, setConsultationCustomerIds] = useState(new Set());
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formConfig, setFormConfig] = useState([]);
    const [temporaryVisibleColumns, setTemporaryVisibleColumns] = useState({});
    const [search, setSearch] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null); // Dùng để chỉnh sửa bệnh nhân
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null); // ID bệnh nhân cần xóa
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    let userId;

    if (token) {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error("Token không hợp lệ:", error);
        }
    }

    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });

    const handleOpenConsultationModal = (customerId) => {
        setSelectedCustomerId(customerId); // Lưu ID khách hàng được chọn
        setShowConsultationModal(true); // Mở modal
    };

    const handleCloseConsultationModal = () => {
        setShowConsultationModal(false);
        setSelectedCustomer(null); // Reset khi đóng modal
    };
    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            // Lấy quyền
            const permissionsResponse = await axios.get(`${apiUrl}/employees/permissions`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { currentRole, userId },
            });
            setPermissions(permissionsResponse.data);

            // Lấy cấu hình form
            const formConfigResponse = await axios.get(`${apiUrl}/customer-form-config/${currentRole}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const config = formConfigResponse.data || [];
            setFormConfig(config);

            // Tải các trường apiSelect
            const updatedConfig = [...config];
            for (let i = 0; i < config.length; i++) {
                const field = config[i];
                if (field.fieldType === "apiSelect" && field.apiEndpoint) {
                    const resolvedEndpoint = field.apiEndpoint
                        .replace("${apiUrl}", apiUrl)
                        .replace("${userId}", userId);
                    const apiResponse = await axios.get(resolvedEndpoint, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    updatedConfig[i].options = apiResponse.data;
                }
            }
            setFormConfig(updatedConfig);

            // Lấy danh sách bệnh nhân
            const customersResponse = await axios.get(`${apiUrl}/customers`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { currentRole, createdBy: userId },
            });
            setCustomers(customersResponse.data);

            // Lấy danh sách tư vấn
            const consultationsResponse = await axios.get(`${apiUrl}/consultations`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { createdBy: userId, currentRole },
            });
            const consultations = consultationsResponse.data || [];
            const customerIds = consultations.map((consultation) => consultation.customFields?.hoVaTen);
            setConsultationCustomerIds(new Set(customerIds));
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi tải dữ liệu:", error);
        }
    }, [apiUrl, token, userId, currentRole]);
    useEffect(() => {
        if (!currentRole) {
            console.log("Đang chờ vai trò được thiết lập...");
            return;
        }

        fetchData();
    }, [fetchData]);

    // Xử lý thay đổi kích thước màn hình
    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener("resize", updateIsMobile);
        return () => window.removeEventListener("resize", updateIsMobile);
    }, []);

    // Toggle hiển thị cột
    const toggleTemporaryVisibility = (fieldName) => {
        setTemporaryVisibleColumns((prev) => ({
            ...prev,
            [fieldName]: !prev[fieldName],
        }));
    };

    /// Tìm kiếm bệnh nhân
    const filteredCustomers = (customers || []).filter((customer) => {
        const customerCodeMatch = customer.customerCode.toString().toLowerCase().includes(search.toLowerCase());
        const customFieldsMatch = Object.values(customer.customFields || {}).some((value) =>
            value?.toString().toLowerCase().includes(search.toLowerCase())
        );
        return customerCodeMatch || customFieldsMatch; // Kết hợp điều kiện OR
    });

    const displayedCustomers = filteredCustomers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Xử lý sửa bệnh nhân
    const handleEditCustomer = (customer) => {
        setSelectedCustomer(customer); // Gán dữ liệu bệnh nhân cần sửa
        setShowAddModal(true); // Hiển thị modal thêm/sửa
    };

    // Xử lý xóa bệnh nhân
    const confirmDelete = (customerId) => {
        setSelectedCustomerId(customerId);
        setShowConfirmModal(true);
    };

    const handleDeleteCustomer = async () => {
        try {
            await axios.delete(
                `${apiUrl}/customers/${selectedCustomerId}`,
                {
                    data: {
                        createdBy: userId,
                        currentRole: currentRole,
                    },
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            toast.success("Xóa bệnh nhân thành công!");
            setShowConfirmModal(false);
            fetchData();
        } catch (error) {
            console.error("Lỗi khi xóa bệnh nhân:", error.response?.data || error.message);
            toast.error("Không thể xóa bệnh nhân.");
        }
    };


    return (
        <div className="m-2">
            <PageWrapper pageTitle="DS Bệnh Nhân" pageIcon={<FontAwesomeIcon icon={faUserGroup} />}>
                <div className="d-flex mb-3">
                    <Button variant="primary" onClick={() => setShowAddModal(true)} className="add-customer-btn">
                        <FontAwesomeIcon icon={faUserPlus} size="lg" />
                    </Button>
                    <FormControl
                        type="text"
                        placeholder="Tìm kiếm bệnh nhân..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="m-1"
                        style={{ flexGrow: 1 }}
                    />
                </div>
                {loading ? (
                    <Loading />
                ) : isMobile ? (
                    <div className="row">
                        {displayedCustomers.map((customer, index) => (
                            <div className="col-md-6 col-sm-12 mb-4" key={customer._id}>
                                <Card>
                                    <Card.Body className="d-flex align-items-center">
                                        {/* Avatar Section */}
                                        <div className="me-3">
                                            <img
                                                src={
                                                    customer.customFields.gioiTinh === "Nam"
                                                        ? noManProfile
                                                        : noWomanProfile
                                                }
                                                alt="avatar"
                                                className="rounded-circle"
                                                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                                            />
                                        </div>
                                        {/* Customer Info */}
                                        <div>
                                            <Card.Title>
                                                <Badge bg="secondary" className="me-2">
                                                    {index + 1}
                                                </Badge>
                                                <span>{customer.customFields.hoVaTen || "Chưa có dữ liệu"}</span>
                                            </Card.Title>
                                            <Card.Text>
                                                {/* Hiển thị các trường động từ formConfig */}
                                                {formConfig.map((field) => (
                                                    <div key={field.fieldName}>
                                                        <strong>{field.fieldLabel}:</strong>{" "}
                                                        {customer.customFields?.[field.fieldName] || "Chưa có dữ liệu"}
                                                    </div>
                                                ))}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                    <Card.Footer className="d-flex justify-content-between">
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            onClick={() => handleEditCustomer(customer)}
                                        >
                                            <FontAwesomeIcon icon={faPencil} /> Sửa
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => confirmDelete(customer._id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> Xóa
                                        </Button>
                                    </Card.Footer>
                                </Card>
                            </div>
                        ))}
                    </div>

                ) : (
                    <Table striped bordered hover>
                        <thead className="text-center">
                            <tr>
                                <th>{displayedCustomers.length}</th>
                                {formConfig.map((field) => (
                                    <th key={field.fieldName}>
                                        {field.fieldLabel}
                                        {field.isTogglable && (
                                            <FontAwesomeIcon
                                                icon={temporaryVisibleColumns[field.fieldName] ? faEye : faEyeSlash}
                                                onClick={() => toggleTemporaryVisibility(field.fieldName)}
                                                style={{ cursor: "pointer", marginLeft: "5px" }}
                                            />
                                        )}
                                    </th>
                                ))}
                                <th>Hành Động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedCustomers.map((customer, index) => (
                                <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    {formConfig.map((field) =>
                                        temporaryVisibleColumns[field.fieldName] !== false ? (
                                            <td
                                                key={`${customer._id}-${field.fieldName}`}
                                                style={
                                                    field.fieldName === "hoVaTen"
                                                        ? { fontWeight: "bold" } // In đậm trường họ tên
                                                        : {}
                                                }
                                            >
                                                {field.fieldType === "file" && customer.customFields?.[field.fieldName] ? (
                                                    <img
                                                        src={customer.customFields[field.fieldName]}
                                                        alt={field.fieldLabel}
                                                        style={{
                                                            width: "50px",
                                                            height: "50px",
                                                            objectFit: "cover",
                                                            borderRadius: "5px",
                                                        }}
                                                    />
                                                ) : field.fieldType === "apiSelect" &&
                                                    customer.customFields?.[field.fieldName] ? (
                                                    field.options?.find(
                                                        (option) => option.value === customer.customFields[field.fieldName]
                                                    )?.label || "Chưa có dữ liệu"
                                                ) : field.fieldType === "date" ? (
                                                    customer.customFields?.[field.fieldName] ? (
                                                        formatDate(customer.customFields?.[field.fieldName])
                                                    ) : (
                                                        "Chưa có dữ liệu"
                                                    )
                                                ) : field.fieldType === "textinline" ? (
                                                    <div>
                                                        {field.fields.map((subField, idx) => (
                                                            <span key={idx}>
                                                                {customer.customFields?.[subField.fieldName] || "Chưa có dữ liệu"}
                                                                {idx < field.fields.length - 1 && ", "}
                                                            </span>
                                                        ))}
                                                    </div>
                                                ) : field.fieldType === "numberinline" ? (
                                                    <div>
                                                        {field.fields.map((subField, idx) => (
                                                            <span key={idx}>
                                                                {customer.customFields?.[subField.fieldName] || "0"}
                                                                {idx < field.fields.length - 1 && ", "}
                                                            </span>
                                                        ))}
                                                    </div>
                                                ) : field.fieldType === "dateinline" ? (
                                                    <div>
                                                        {field.fields.map((subField, idx) => (
                                                            <span key={idx}>
                                                                {customer.customFields?.[subField.fieldName]
                                                                    ? formatDate(customer.customFields[subField.fieldName])
                                                                    : "Chưa có dữ liệu"}
                                                                {idx < field.fields.length - 1 && ", "}
                                                            </span>
                                                        ))}
                                                    </div>
                                                ) : field.fieldType === "selectmulti" && Array.isArray(customer.customFields?.[field.fieldName]) ? (
                                                    <div>
                                                        {customer.customFields[field.fieldName].map((item, idx) => (
                                                            <div key={idx}>
                                                                <span style={{ fontWeight: "bold" }}>{item.name}</span>
                                                                {item.quantity ? ` - Số lượng: ${item.quantity}` : ""}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : customer.customFields?.[field.fieldName] || "Chưa có dữ liệu"}


                                            </td>
                                        ) : (
                                            <td>&nbsp;</td>
                                        )
                                    )}
                                    <td className="text-center">
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            onClick={() => handleEditCustomer(customer)}
                                            className="m-1"
                                        >
                                            Sửa
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => confirmDelete(customer._id)}
                                            className="m-1"
                                            disabled={consultationCustomerIds.has(customer._id)}
                                        >
                                            Xóa
                                        </Button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                )}
                <AddCustomer
                    currentRole={currentRole}
                    permissions={permissions}
                    fetchData={fetchData}
                    show={showAddModal}
                    handleClose={() => {
                        setSelectedCustomer(null); // Reset bệnh nhân khi đóng
                        setShowAddModal(false);
                    }}
                    selectedCustomer={selectedCustomer}
                    isEditMode={!!selectedCustomer}
                />

                <ModalConfirm
                    show={showConfirmModal}
                    handleClose={() => setShowConfirmModal(false)}
                    handleConfirm={handleDeleteCustomer}
                />
            </PageWrapper>
        </div>
    );
};

export default CustomerList;
