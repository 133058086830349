import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Support = ({ message = '0985.660.198', icon = faQuestionCircle }) => {
    return (
        <div className="justify-content-center" style={{ height: '5vh' }}>
            <FontAwesomeIcon icon={icon} className="text-success grow-animation-support" />&nbsp;<b>{message}</b>
        </div>
    );
};

export default Support;
