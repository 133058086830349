import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

const EditImageModal = ({ show, handleClose, album, onReFresh }) => {
    const [imagePairs, setImagePairs] = useState([]);
    const [notes, setNotes] = useState(album?.notes || '');
    const [deletedImageIds, setDeletedImageIds] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (album) {
            setNotes(album.notes || '');
            const formattedPairs = [];
            for (let i = 0; i < album.images.length; i += 2) {
                formattedPairs.push({
                    before: album.images[i] || null,
                    after: album.images[i + 1] || null,
                });
            }
            setImagePairs(formattedPairs);
        }
    }, [album]);

    const handleImageChange = async (index, position, file) => {
        try {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 800,
                useWebWorker: true,
                fileType: 'image/webp',
            };
            const compressedFile = await imageCompression(file, options);
            const newImagePairs = [...imagePairs];
            newImagePairs[index][position] = {
                data: compressedFile,
                contentType: 'image/webp',
                name: position === 'before' ? 'Trước' : 'Sau',
                modifiedAt: new Date(),
                preview: URL.createObjectURL(compressedFile)
            };
            setImagePairs(newImagePairs);
        } catch (error) {
            console.error('Lỗi khi xử lý hình ảnh:', error);
        }
    };

    const handleAddImagePair = () => {
        setImagePairs([...imagePairs, { before: null, after: null }]);
    };

    const handleRemoveImage = (index, position) => {
        const newImagePairs = [...imagePairs];
        if (newImagePairs[index] && newImagePairs[index][position]) {
            const imageId = newImagePairs[index][position]._id;
            if (imageId) {
                setDeletedImageIds(prev => [...prev, imageId]); // Thêm kiểm tra để tránh lỗi
            }
            newImagePairs[index][position] = null;
            setImagePairs(newImagePairs);
        }
    };


    const handleNameChange = (index, position, newName) => {
        const newImagePairs = [...imagePairs];
        if (newImagePairs[index][position]) {
            newImagePairs[index][position].name = newName;
        }
        setImagePairs(newImagePairs);
    };

    const handleDescriptionChange = (index, position, newDescription) => {
        const newImagePairs = [...imagePairs];
        if (newImagePairs[index][position]) {
            newImagePairs[index][position].description = newDescription;
        }
        setImagePairs(newImagePairs);
    };

    const saveChanges = async () => {
        try {
            const formData = new FormData();
            formData.append('notes', notes);

            imagePairs.forEach((pair, index) => {
                ['before', 'after'].forEach((position) => {
                    if (pair[position]) {
                        // Nếu có hình ảnh mới được tải lên
                        if (pair[position].data instanceof Blob) {
                            formData.append('images', pair[position].data); // Thêm hình ảnh mới
                            formData.append('imageNames', pair[position].name || (position === 'before' ? 'Trước' : 'Sau')); // Thêm tên hình ảnh
                            formData.append('imageDescriptions', pair[position].description || ''); // Thêm mô tả hình ảnh
                        } else {
                            // Nếu là hình ảnh cũ
                            formData.append('existingImageIds', pair[position]._id); // ID của hình cũ
                            formData.append('existingImageNames', pair[position].name || ''); // Tên hình ảnh cũ
                            formData.append('existingImageDescriptions', pair[position].description || ''); // Mô tả hình ảnh cũ
                        }
                    }
                });
            });

            // Xử lý xóa hình ảnh
            if (deletedImageIds.length > 0) {
                formData.append('deletedImageIds', JSON.stringify(deletedImageIds)); // Gửi ID ảnh đã xóa
            }

            const response = await axios.put(`${apiUrl}/albums/${album._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                toast.success('Cập nhật album thành công!');
                handleClose();
                if (onReFresh) onReFresh();
            }
        } catch (error) {
            console.error('Lỗi khi cập nhật album:', error);
            toast.error('Không thể cập nhật album.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa Album: {album?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formNotes">
                        <Form.Label>Mô tả</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </Form.Group>

                    {imagePairs.map((pair, index) => (
                        <div className="row mb-3" key={index}>
                            <div className="col-md-6 text-center">
                                <Form.Group>
                                    <Form.Label>Hình Ảnh Trước</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Tên hình ảnh trước"
                                        value={pair.before?.name || ''}
                                        onChange={(e) => handleNameChange(index, 'before', e.target.value)}
                                        className="mb-2"
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Mô tả hình ảnh trước"
                                        value={pair.before?.description || ''}
                                        onChange={(e) => handleDescriptionChange(index, 'before', e.target.value)}
                                        className="mb-2"
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => handleImageChange(index, 'before', e.target.files[0])}
                                        id={`before-${index}`}
                                        style={{ display: 'none' }}
                                    />
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => document.getElementById(`before-${index}`).click()}
                                        className="mt-2"
                                    >
                                        <FontAwesomeIcon icon={faPlusCircle} /> Chọn Hình Ảnh
                                    </Button>
                                    {pair.before && (
                                        <>
                                            <img
                                                src={pair.before.preview || `data:${pair.before.contentType};base64,${pair.before.data}`}
                                                alt={pair.before.name}
                                                className="mt-2"
                                                style={{ maxWidth: '100%', maxHeight: '150px' }}
                                            />
                                            <Button
                                                variant="danger"
                                                onClick={() => handleRemoveImage(index, 'before')}
                                                className="mt-2"
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} /> Xóa
                                            </Button>
                                        </>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 text-center">
                                <Form.Group>
                                    <Form.Label>Hình Ảnh Sau</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Tên hình ảnh sau"
                                        value={pair.after?.name || ''}
                                        onChange={(e) => handleNameChange(index, 'after', e.target.value)}
                                        className="mb-2"
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Mô tả hình ảnh sau"
                                        value={pair.after?.description || ''}
                                        onChange={(e) => handleDescriptionChange(index, 'after', e.target.value)}
                                        className="mb-2"
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => handleImageChange(index, 'after', e.target.files[0])}
                                        id={`after-${index}`}
                                        style={{ display: 'none' }}
                                    />
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => document.getElementById(`after-${index}`).click()}
                                        className="mt-2"
                                    >
                                        <FontAwesomeIcon icon={faPlusCircle} /> Chọn Hình Ảnh
                                    </Button>
                                    {pair.after && (
                                        <>
                                            <img
                                                src={pair.after.preview || `data:${pair.after.contentType};base64,${pair.after.data}`}
                                                alt={pair.after.name}
                                                className="mt-2"
                                                style={{ maxWidth: '100%', maxHeight: '150px' }}
                                            />
                                            <Button
                                                variant="danger"
                                                onClick={() => handleRemoveImage(index, 'after')}
                                                className="mt-2"
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} /> Xóa
                                            </Button>
                                        </>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    ))}

                    <Button variant="success" onClick={handleAddImagePair} className="mt-3">
                        Thêm hình ảnh +
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={saveChanges}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditImageModal;
