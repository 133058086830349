import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCapsules, faSave } from '@fortawesome/free-solid-svg-icons';
import InputGroupText from 'react-bootstrap/esm/InputGroupText';

const CreateMedicineReceipt = ({ show, handleClose, fetchMedicines, currentRole }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [medicines, setMedicines] = useState([]);
    const [receiptMedicines, setReceiptMedicines] = useState([{ medicineId: '', soLuong: '', giaNhap: '', expirationDate: '' }]);
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const selectRef = useRef(null);
    const inputRefs = useRef([]);
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    useEffect(() => {
        const fetchAllMedicines = async () => {
            try {
                const response = await axios.get(`${apiUrl}/medicines`, {
                    params: { createdBy: userId, currentRole },
                    headers: { Authorization: `Bearer ${token}` },
                });
                setMedicines(response.data.map(med => ({
                    value: med._id,
                    label: `${med.customFields.tenThuoc} | Số lượng: ${med.customFields.soLuong} | Giá bán: ${med.customFields.giaBan ? med.customFields.giaBan.toLocaleString("vi-VN") : ""}đ`,
                })));
            } catch (error) {
                console.error('Lỗi khi lấy danh sách thuốc:', error);
            }
        };
        fetchAllMedicines();
    }, [show, apiUrl]);

    useEffect(() => {
        if (show && selectRef.current) {
            selectRef.current.focus();
        }
    }, [show]);

    const handleAddMedicine = () => {
        setReceiptMedicines([...receiptMedicines, { medicineId: '', soLuong: 0, giaNhap: 0, expirationDate: '' }]);
    };

    const handleRemoveMedicine = (index) => {
        setReceiptMedicines(receiptMedicines.filter((_, i) => i !== index));
    };

    const handleSelectChange = (selectedOption, index) => {
        setReceiptMedicines(receiptMedicines.map((r, i) =>
            i === index ? { ...r, medicineId: selectedOption.value } : r
        ));
        inputRefs.current[index * 4 + 1]?.focus();
    };

    const handleKeyDown = (event, index, field) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let nextIndex;

            if (field === 'soLuong') {
                nextIndex = index * 4 + 2;
            } else if (field === 'giaNhap') {
                nextIndex = index * 4 + 3;
            } else if (field === 'expirationDate') {
                nextIndex = 'submitButton';
            }

            if (nextIndex === 'submitButton') {
                document.getElementById('submitButton')?.focus();
            } else if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await axios.post(
                `${apiUrl}/medicineReceipts`,
                {
                    medicines: receiptMedicines,
                    note,
                    createdBy: userId,
                    currentRole,
                }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Tạo phiếu nhập thuốc thành công!');

            const stockUpdates = receiptMedicines.map(async (item) => {
                try {
                    // Lấy thông tin vật tư hiện tại
                    const medicineResponse = await axios.get(`${apiUrl}/medicines/${item.medicineId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            currentRole, // Chủ sở hữu dữ liệu
                            createdBy: userId, // Người thực hiện hành động
                        },
                    });

                    const currentQuantity = Number(medicineResponse.data.customFields.soLuong || 0);
                    const updatedQuantity = currentQuantity + Number(item.soLuong);

                    // Cập nhật số lượng
                    await axios.put(
                        `${apiUrl}/medicines/${item.medicineId}`,
                        {
                            customFields: { soLuong: updatedQuantity },
                            createdBy: userId,
                            currentRole: currentRole,
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );

                } catch (error) {
                    console.error(`Lỗi khi cập nhật vật tư ${item.medicineId}:`, error.response?.data || error.message);
                }
            });

            // Đợi tất cả các vật tư được cập nhật
            await Promise.all(stockUpdates);

            handleClose();
            fetchMedicines();
            toast.success('Cập nhật số lượng Thuốc thành công!');
            setLoading(false);
        } catch (error) {
            console.error('Lỗi khi tạo phiếu nhập thuốc:', error.response?.data || error.message);
            toast.error('Lỗi khi tạo phiếu nhập thuốc!');
        }
    };

    const isSubmitDisabled = () => {
        return receiptMedicines.some(item => !item.medicineId || item.soLuong <= 0 || item.giaNhap <= 0);
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faCapsules} /> Nhập Thuốc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {receiptMedicines.map((item, index) => (
                        <Row key={index} className="mb-3">
                            <Col className='col-10'>
                                <Form.Group>
                                    <InputGroup>
                                        <InputGroupText>Chọn Thuốc</InputGroupText>
                                        <Select
                                            styles={{
                                                container: (base) => ({
                                                    ...base,
                                                    width: "80%", // Đặt độ rộng container 100%
                                                }),
                                            }}
                                            options={medicines}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                            placeholder="Tìm và chọn thuốc..."
                                            ref={el => inputRefs.current[index * 4] = el}
                                            autoFocus={index === 0}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col><hr />
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Số Lượng</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={item.soLuong}
                                        onChange={(e) =>
                                            setReceiptMedicines(receiptMedicines.map((r, i) =>
                                                i === index ? { ...r, soLuong: Number(e.target.value) } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 4 + 1] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'soLuong')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Giá Nhập</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={item.giaNhap}
                                        onChange={(e) =>
                                            setReceiptMedicines(receiptMedicines.map((r, i) =>
                                                i === index ? { ...r, giaNhap: Number(e.target.value) } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 4 + 2] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'giaNhap')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-2'>
                                <Form.Group>
                                    <Form.Label>Hạn Dùng</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={item.expirationDate}
                                        onChange={(e) =>
                                            setReceiptMedicines(receiptMedicines.map((r, i) =>
                                                i === index ? { ...r, expirationDate: e.target.value } : r
                                            ))
                                        }
                                        ref={el => inputRefs.current[index * 4 + 3] = el}
                                        onKeyDown={(e) => handleKeyDown(e, index, 'expirationDate')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="d-flex align-items-end col-2">
                                <Button variant="danger" onClick={() => handleRemoveMedicine(index)}>
                                    X
                                </Button>
                            </Col>
                        </Row>
                    ))}

                    <Button variant="success" onClick={handleAddMedicine} className='m-1'>
                        Thêm +
                    </Button>

                    <Form.Group className="mt-3">
                        <Form.Label>Ghi Chú</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </Form.Group>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <Button id="submitButton" variant="primary" type="submit" className="mt-3" disabled={loading || isSubmitDisabled()}>
                            <FontAwesomeIcon icon={faSave} className='me-1' /> {loading ? "Đang tạo phiếu..." : "Tạo Phiếu Nhập"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateMedicineReceipt;
