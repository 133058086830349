import React, { useEffect, useRef } from 'react';
import './GridEffect.css';

const GridEffect = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const width = canvas.parentElement.offsetWidth;
        const height = canvas.parentElement.offsetHeight;
        canvas.width = width;
        canvas.height = height;

        const nodes = [];
        const numNodes = 100;

        for (let i = 0; i < numNodes; i++) {
            nodes.push({
                x: Math.random() * width,
                y: Math.random() * height,
                radius: 3 + Math.random() * 2,
                dx: (Math.random() - 0.5) * 2,
                dy: (Math.random() - 0.5) * 2,
                color: 'rgba(255, 255, 255, 0.7)',
            });
        }

        const animate = () => {
            context.clearRect(0, 0, width, height);
            for (let i = 0; i < numNodes; i++) {
                const node = nodes[i];
                node.x += node.dx;
                node.y += node.dy;

                if (node.x < 0 || node.x > width) node.dx *= -1;
                if (node.y < 0 || node.y > height) node.dy *= -1;

                context.beginPath();
                context.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
                context.fillStyle = node.color;
                context.fill();
            }

            for (let i = 0; i < numNodes; i++) {
                for (let j = i + 1; j < numNodes; j++) {
                    const dist = Math.hypot(nodes[i].x - nodes[j].x, nodes[i].y - nodes[j].y);
                    if (dist < 150) {
                        context.beginPath();
                        context.moveTo(nodes[i].x, nodes[i].y);
                        context.lineTo(nodes[j].x, nodes[j].y);
                        context.strokeStyle = `rgba(255, 255, 255, ${1 - dist / 150})`;
                        context.stroke();
                    }
                }
            }

            requestAnimationFrame(animate);
        };

        animate();

        const handleMouseMove = (event) => {
            const rect = canvas.getBoundingClientRect();
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;

            let closestNode = null;
            let minDist = Infinity;

            for (let i = 0; i < numNodes; i++) {
                const node = nodes[i];
                const dist = Math.hypot(mouseX - node.x, mouseY - node.y);
                if (dist < minDist) {
                    minDist = dist;
                    closestNode = node;
                }
            }

            if (closestNode) {
                closestNode.dx = (mouseX - closestNode.x) * 0.1;
                closestNode.dy = (mouseY - closestNode.y) * 0.1;
            }
        };

        canvas.addEventListener('mousemove', handleMouseMove);

        return () => {
            canvas.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return <canvas ref={canvasRef} className="canvas"></canvas>;
};

export default GridEffect;
