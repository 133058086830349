import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCamera, faCheckCircle, faMapMarkedAlt, faPhone, faQuestionCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import GridEffect from '../GridEffect';
import { Button } from 'react-bootstrap';

const ProfileHeader = ({ slug }) => {
    const [userData, setUserData] = useState(null);
    const [isEditingCover, setIsEditingCover] = useState(false);
    const [newCoverPhoto, setNewCoverPhoto] = useState(null);
    const [isOwner, setIsOwner] = useState(false); // Kiểm tra quyền sở hữu
    const apiUrl = process.env.REACT_APP_API_URL;

    // Lấy thông tin người dùng từ slug
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/user/slug/${slug}`);
                setUserData(response.data);

                // Kiểm tra quyền sở hữu dựa trên token
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const decoded = jwtDecode(token);
                        setIsOwner(decoded.userId === response.data._id);
                    } catch (error) {
                        console.error('Token không hợp lệ:', error);
                    }
                }
            } catch (error) {
                console.error('Lỗi khi tải thông tin người dùng:', error);
                toast.error('Không thể tải thông tin người dùng');
            }
        };
        fetchUserData();
    }, [slug, apiUrl]);

    const handleCoverPhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewCoverPhoto(reader.result);
                setIsEditingCover(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveCoverPhoto = async () => {
        if (newCoverPhoto && isOwner) {
            try {
                await axios.put(`${apiUrl}/user/${userData._id}/cover-picture`, { coverPicture: newCoverPhoto });
                setUserData((prev) => ({ ...prev, coverPicture: newCoverPhoto }));
                toast.success('Cập nhật ảnh bìa thành công!');
                setIsEditingCover(false);
            } catch (error) {
                console.error('Lỗi khi cập nhật ảnh bìa:', error);
                toast.error('Lỗi khi cập nhật ảnh bìa!');
            }
        } else {
            toast.warn('Chọn một ảnh trước khi lưu');
        }
    };

    if (!userData) {
        return <center><p>Không tìm thấy người dùng</p></center>
    }

    return (
        <div className="profile-header-wrapper">
            {/* Ảnh bìa */}
            <div className="cover-photo-section">
                <img src={newCoverPhoto || userData.coverPicture || 'default_cover_photo_url'} alt="Cover" className="cover-photo" onContextMenu={(e) => e.preventDefault()} />
                {isOwner && (
                    <>
                        <label className="profile-header-icon profile-header-camera-icon" htmlFor="cover-photo-input">
                            <FontAwesomeIcon icon={faCamera} />
                        </label>
                        <input
                            type="file"
                            id="cover-photo-input"
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={handleCoverPhotoChange}
                        />
                        {isEditingCover && (
                            <button className="profile-header-icon profile-header-save-icon" onClick={handleSaveCoverPhoto}>
                                <FontAwesomeIcon icon={faSave} />
                            </button>
                        )}
                    </>
                )}
            </div>
            <div className='canvas' onContextMenu={(e) => e.preventDefault()}>
                <GridEffect />
            </div>

            {/* Ảnh đại diện */}
            <div className="profile-photo-wrapper">
                <img src={userData.profilePicture || 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEivYejoR2LHSmj1k4rPX5GAzkudAMk1bry1vGcUIMKT6KGqVdZHCXei00LlHmy9sVd-KgyBGoBnjUMEadamNiYPCw9ujoBgsZ7d42o2ICLNeiFb_-EZVJ4JJXj9VaIpNDew1kj8OTQzjp682jz71pNprN_YaK5nFQxZ5CKDVsdpkObFahGt-KLNsIIU0vP1/s16000/noProfile.png'} alt="Profile" className="profile-photo" onContextMenu={(e) => e.preventDefault()} />
            </div>

            {/* Tên và dấu tick */}
            <h2 className="profile-name">
                {userData.name || 'Tên không có sẵn'}
                {userData.verified ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="verified-badge-wrapper verified-badge-icon" title='Tài khoản đã xác minh' />
                ) : (
                    <FontAwesomeIcon icon={faQuestionCircle} className="verified-badge-wrapper verified-badge-icon" title='Tài khoản chưa xác minh' />
                )}
            </h2>
            <Button variant='primary' size='sm' className='m-1'><FontAwesomeIcon icon={faPhone} /> Gọi ngay</Button>
            <Button variant='success' className='m-1'><FontAwesomeIcon icon={faCalendarPlus} /> Đặt Lịch Hẹn</Button>
            <Button variant='info' size='sm' className='m-1'><FontAwesomeIcon icon={faMapMarkedAlt} /> Đường đi</Button>
        </div>
    );
};

export default ProfileHeader;
