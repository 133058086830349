import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Card, Button, Container, } from "react-bootstrap";
import { toast } from "react-toastify";

const Invitations = () => {
    const [invitations, setInvitations] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");

    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }
    const fetchNotifications = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/employees/notifications`, {
                params: { userId },
                headers: { Authorization: `Bearer ${token}` },
            });
            setNotifications(response.data);
        } catch (error) {
            console.error("Lỗi khi lấy thông báo:", error);
        }
    }, [apiUrl, token, userId]);
    useEffect(() => {
        if (userId) {
            fetchNotifications();
        }
    }, [fetchNotifications]);

    const handleResponse = async (invitationId, action) => {
        try {
            await axios.post(
                `${apiUrl}/employees/notifications/${invitationId}/respond`,
                { action },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setInvitations((prev) => prev.filter((inv) => inv._id !== invitationId));
        } catch (error) {
            console.error("Lỗi khi phản hồi lời mời:", error);
        }
    };

    return (
        <Container className="m-2">
            <Card>
                <Card.Header className="bg-success text-white text-center">
                    <h5>Bạn Nhận Được Lời Mời</h5>
                </Card.Header>
                <Card.Body>
                    {notifications.length === 0 ? (
                        <p className="text-center text-muted">Không có lời mời nào.</p>
                    ) : (
                        notifications.map((inv) => (
                            <div key={inv._id}>
                                <p>
                                    Vai trò: <strong>{inv.role}</strong>
                                </p>
                                <p>
                                    Bạn được mời bởi:{" "}
                                    <strong>{inv.ownerId?.name || inv.ownerId?.email || "Không rõ"}</strong>
                                </p>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <Button variant="success" onClick={() => {
                                        handleResponse(inv._id, "accept");
                                        toast.success('Bạn đã chấp nhận lời mời!');
                                        fetchNotifications();
                                    }}>
                                        Chấp nhận
                                    </Button>
                                    <Button variant="danger" onClick={() => {
                                        handleResponse(inv._id, "reject");
                                        toast.info('Bạn đã từ chối lời mời');
                                        fetchNotifications();
                                    }}>
                                        Từ chối
                                    </Button>
                                </div>
                            </div>
                        ))
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Invitations;
