import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button, Table, Form, Modal, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPencil, faPlusCircle, faSave, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const NavbarConfigManager = () => {
    const [navbarItems, setNavbarItems] = useState([]);
    const [currentItem, setCurrentItem] = useState(null); // Để lưu mục hiện tại khi thêm/sửa
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef([]); // Để quản lý autofocus và chuyển trường
    const token = localStorage.getItem("token");
    const apiUrl = process.env.REACT_APP_API_URL;
    const decoded = jwtDecode(token);
    const userId = decoded.userId;

    // Lấy danh sách Navbar từ API
    const fetchNavbarConfig = async () => {
        try {
            const response = await axios.get(`${apiUrl}/navbar-config/${userId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Sắp xếp theo thứ tự 'order' tăng dần
            const sortedItems = response.data.sort((a, b) => a.order - b.order);
            setNavbarItems(sortedItems);
        } catch (error) {
            console.error("Lỗi khi lấy cấu hình Navbar:", error);
        }
    };

    // Lưu cấu hình Navbar
    const saveNavbarConfig = async () => {
        try {
            setLoading(true);

            const updatedNavbarItems = navbarItems.map((item, index) => ({
                ...item,
                userId, // Thêm userId vào từng mục
                order: index, // Cập nhật thứ tự
            }));

            await axios.put(
                `${apiUrl}/navbar-config/${userId}`,
                { navBarConfig: updatedNavbarItems },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            toast.success("Lưu cấu hình Navbar thành công!");
        } catch (error) {
            console.error("Lỗi khi lưu cấu hình Navbar:", error);
            toast.error("Không thể lưu cấu hình Navbar!");
        } finally {
            setLoading(false);
        }
    };

    // Di chuyển mục lên hoặc xuống
    const moveItem = (index, direction) => {
        const newNavbarItems = [...navbarItems];
        if (direction === "up" && index > 0) {
            // Đổi chỗ mục hiện tại với mục phía trên
            [newNavbarItems[index - 1], newNavbarItems[index]] = [newNavbarItems[index], newNavbarItems[index - 1]];
        } else if (direction === "down" && index < newNavbarItems.length - 1) {
            // Đổi chỗ mục hiện tại với mục phía dưới
            [newNavbarItems[index + 1], newNavbarItems[index]] = [newNavbarItems[index], newNavbarItems[index + 1]];
        }
        // Cập nhật danh sách Navbar
        setNavbarItems(newNavbarItems);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Ngăn chặn hành động mặc định của Enter
            const nextInput = inputRefs.current[index + 1]; // Lấy input tiếp theo
            if (nextInput) {
                nextInput.focus(); // Focus vào input tiếp theo
            }
        }
    };

    // Thêm hoặc chỉnh sửa menu
    const handleSaveItem = () => {
        if (!currentItem.key || !currentItem.label || !currentItem.href || !currentItem.icon) {
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }

        const updatedItems = currentItem._id
            ? navbarItems.map((item) =>
                item._id === currentItem._id ? currentItem : item
            )
            : [...navbarItems, { ...currentItem, _id: Date.now().toString(), order: navbarItems.length }];

        setNavbarItems(updatedItems);
        setShowModal(false);
        setCurrentItem(null);
    };

    // Hiển thị Modal thêm/sửa menu
    const handleEditItem = (item = null) => {
        setCurrentItem(item || { key: "", label: "", href: "", icon: "", visible: true, order: navbarItems.length });
        setShowModal(true);
    };

    useEffect(() => {
        fetchNavbarConfig();
    }, []);

    return (
        <div className="container-fluid">
            <h3><FontAwesomeIcon icon={faCog} className="me-2" /> Navbar Menu</h3>
            <div className="mb-3">
                <div className='d-flex justify-content-center'>
                    <Button variant="primary" onClick={() => handleEditItem()}>
                        <FontAwesomeIcon icon={faPlusCircle} className="ms-1" /> Thêm Mục Mới
                    </Button>
                    <Button
                        variant="success"
                        className="ms-2"
                        onClick={saveNavbarConfig}
                        disabled={loading}
                    >
                        <FontAwesomeIcon icon={faSave} className="ms-1" /> {loading ? "Đang lưu..." : "Lưu Cấu Hình"}
                    </Button>
                </div>
            </div>

            <Table striped bordered hover>
                <thead className="text-center">
                    <tr>
                        <th>#</th>
                        <th>Key</th>
                        <th>Nhãn</th>
                        <th>Icon</th>
                        <th>Đường Dẫn</th>
                        <th>Hiển Thị</th>
                        <th>Thứ Tự</th>
                        <th>Hành Động</th>
                    </tr>
                </thead>
                <tbody>
                    {navbarItems.map((item, index) => (
                        <tr key={item.key}>
                            <td>{index + 1}</td>
                            <td>{item.key}</td>
                            <td>{item.label}</td>
                            <td>
                                <i className={`fa ${item.icon}`} /> {item.icon}
                            </td>
                            <td>{item.href}</td>
                            <td>{item.visible ? "Có" : "Không"}</td>
                            <td className="text-center">
                                <Button
                                    variant="light"
                                    size="sm"
                                    className="m-1"
                                    onClick={() => moveItem(index, "up")}
                                    disabled={index === 0}
                                >
                                    <FontAwesomeIcon icon={faArrowUp} />
                                </Button>
                                <Button
                                    variant="light"
                                    size="sm"
                                    className="m-1"
                                    onClick={() => moveItem(index, "down")}
                                    disabled={index === navbarItems.length - 1}
                                >
                                    <FontAwesomeIcon icon={faArrowDown} />
                                </Button>
                            </td>
                            <td className="text-center">
                                <Button
                                    variant="warning"
                                    size="sm"
                                    className="m-1"
                                    onClick={() => handleEditItem(item)}
                                >
                                    Sửa
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal Thêm/Sửa */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {currentItem?._id ? (
                            <>
                                <FontAwesomeIcon icon={faPencil} className="me-2" /> Sửa Trường
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Thêm Trường Mới
                            </>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Trường Key */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Key</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Nhập key"
                                value={currentItem?.key || ''}
                                onChange={(e) =>
                                    setCurrentItem({ ...currentItem, key: e.target.value })
                                }
                                ref={(el) => (inputRefs.current[0] = el)} // Gắn ref cho input
                                onKeyDown={(e) => handleKeyDown(e, 0)} // Chuyển trường khi nhấn Enter
                                autoFocus // Tự động focus khi mở modal
                            />
                        </InputGroup>

                        {/* Trường Label */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Nhãn</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Nhập label"
                                value={currentItem?.label || ''}
                                onChange={(e) =>
                                    setCurrentItem({ ...currentItem, label: e.target.value })
                                }
                                ref={(el) => (inputRefs.current[1] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                            />
                        </InputGroup>

                        {/* Trường Icon */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Biểu tượng</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Nhập icon (ví dụ: faTasks)"
                                value={currentItem?.icon || ''}
                                onChange={(e) =>
                                    setCurrentItem({ ...currentItem, icon: e.target.value })
                                }
                                ref={(el) => (inputRefs.current[2] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 2)}
                            />
                        </InputGroup>

                        {/* Trường Href */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Đường dẫn</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Nhập đường dẫn (href)"
                                value={currentItem?.href || ''}
                                onChange={(e) =>
                                    setCurrentItem({ ...currentItem, href: e.target.value })
                                }
                                ref={(el) => (inputRefs.current[3] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 3)}
                            />
                        </InputGroup>

                        {/* Trường Visible */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Hiển thị</InputGroup.Text>
                            <InputGroup.Checkbox
                                checked={currentItem?.visible || false}
                                onChange={(e) =>
                                    setCurrentItem({ ...currentItem, visible: e.target.checked })
                                }
                                ref={(el) => (inputRefs.current[4] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 4)}
                            />
                        </InputGroup>

                        {/* Trường Order */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Thứ tự</InputGroup.Text>
                            <Form.Control
                                type="number"
                                placeholder="Nhập thứ tự (order)"
                                value={currentItem?.order || 0}
                                onChange={(e) =>
                                    setCurrentItem({ ...currentItem, order: parseInt(e.target.value, 10) })
                                }
                                ref={(el) => (inputRefs.current[5] = el)}
                                onKeyDown={(e) => handleKeyDown(e, 5)}
                            />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Hủy
                    </Button>
                    <Button variant="primary" onClick={handleSaveItem}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default NavbarConfigManager;
