// frontend/src/pages/Supplies.js
import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl } from 'react-bootstrap';
import AddPartner from '../components/AddPartner';
import Loading from '../components/Loading';
import ModalConfirm from '../components/ModalConfirm';
import { RoleContext } from '../components/RoleContext';
import { toast } from 'react-toastify';

const PartnerPage = () => {
    const { currentRole } = useContext(RoleContext);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [partners, setPartners] = useState([]); // Danh sách đối tác
    const [editData, setEditData] = useState(null); // Dữ liệu để sửa
    const [formConfig, setFormConfig] = useState([]); // Cấu hình form tùy chỉnh
    const [showModal, setShowModal] = useState(false); // Modal thêm đối tác
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Trạng thái hiển thị Modal xác nhận xóa
    const [selectedPartnersId, setSelectedPartnersId] = useState(null); // Vật tư cần xóa
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    const userId = jwtDecode(token)?.userId;
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });
    // Hàm lấy permissions
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.get(`${apiUrl}/employees/permissions`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { currentRole, userId },
                });
                setPermissions(response.data);
            } catch (error) {
                console.error("Lỗi khi lấy quyền:", error);
            }
        };

        fetchPermissions();
    }, [currentRole, apiUrl, token, userId]);

    // Lấy cấu hình form
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const response = await axios.get(`${apiUrl}/partner-form-config/${currentRole}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setFormConfig(response.data);
            } catch (error) {
                console.error("Lỗi khi tải cấu hình form:", error);
                toast.error("Không thể tải cấu hình form!");
            }
        };
        fetchFormConfig();
    }, [apiUrl, token, userId, currentRole]);

    // Lấy danh sách đối tác
    const fetchPartners = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/partners`, {
                params: { currentRole, createdBy: userId, search, },
                headers: { Authorization: `Bearer ${token}` },
            });
            setPartners(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi tải danh sách đối tác:", error);
            toast.error("Không thể tải danh sách đối tác!");
        }
    }, [apiUrl, token, userId, currentRole, search]);
    useEffect(() => {
        if (currentRole) {
            fetchPartners();
        }
    }, [fetchPartners, currentRole]);

    // Hiển thị Modal thêm mới hoặc sửa
    const handleShowModal = (data = null) => {
        setEditData(data); // Nếu `data` có giá trị, chuyển sang chế độ sửa
        setShowModal(true);
    };
    // Đóng Modal
    const handleCloseModal = () => {
        setEditData(null); // Reset dữ liệu sửa
        setShowModal(false);
        fetchPartners(); // Làm mới danh sách sau khi thêm/sửa
    };
    const handleDelete = async () => {
        try {
            await axios.delete(`${apiUrl}/partners/${selectedPartnersId}`, {
                data: {
                    createdBy: userId,
                    currentRole: currentRole,
                },
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success("Xóa đối tác thành công!");
            setShowConfirmModal(false);
            // Gọi lại API để cập nhật danh sách
            fetchPartners();
        } catch (error) {
            console.error("Lỗi khi xóa đối tác:", error);
            toast.error("Không thể xóa đối tác!");
        }
    };

    //Mở modal xóa
    const confirmDelete = (id) => {
        setSelectedPartnersId(id);
        setShowConfirmModal(true);
    };
    const handleSearch = (e) => {
        setSearch(e.target.value); // Cập nhật giá trị tìm kiếm
    };

    return (
        <div className='m-2'>
            <h2><FontAwesomeIcon icon={faUser} /> Danh Sách Đối Tác</h2>
            <div className='d-flex mb-3'>
                <Button variant='primary' className='m-1' title='Thêm Đối tác mới' onClick={() => handleShowModal()}>
                    <FontAwesomeIcon icon={faPlus} /> Thêm Đối Tác
                </Button>
                <FormControl
                    type="text"
                    placeholder="Tìm kiếm dịch vụ..."
                    value={search}
                    onChange={handleSearch} // Gọi hàm handleSearch khi nhập liệu
                    className="ms-3"
                    style={{ flexGrow: 1 }}
                />
            </div>
            {loading ? (<Loading />) : (
                <Table striped bordered hover responsive>
                    <thead className='text-center'>
                        <tr>
                            <th>{partners.length}</th>
                            <th>Mã Đối Tác</th>
                            {formConfig.map((field) => (
                                <th key={field.fieldName}>{field.fieldLabel}</th>
                            ))}
                            <th>Hành Động</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partners.length > 0 && partners.map((partner, index) => (
                            <tr key={partner._id}>
                                <td>{index + 1}</td>
                                <td>{partner.partnerCode ? partner.partnerCode.slice(0, 6) : "Không rõ"}</td>
                                {formConfig.map((field) => (
                                    <td key={field.fieldName}
                                        style={
                                            field.fieldName === "tenDoiTac"
                                                ? { fontWeight: "bold" } // In đậm trường họ tên
                                                : {}
                                        }>
                                        {field.fieldName === "giaBan" || field.fieldName === "giaNhap"
                                            ? new Intl.NumberFormat("vi-VN", {
                                                style: "currency",
                                                currency: "VND",
                                            }).format(partner.customFields?.[field.fieldName] || 0)
                                            : partner.customFields?.[field.fieldName] || ""}
                                    </td>
                                ))}
                                <td className='text-center'>
                                    <Button variant="warning" size="sm" className="me-2" onClick={() => handleShowModal(partner)}>
                                        Sửa
                                    </Button>
                                    <Button variant="danger" size="sm" onClick={() => confirmDelete(partner._id)}>
                                        Xóa
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>)}
            <AddPartner
                show={showModal}
                currentRole={currentRole}
                permissions={permissions}
                handleClose={handleCloseModal}
                fetchPartners={fetchPartners}
                editData={editData} // Gửi dữ liệu để sửa (nếu có)
            />
            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDelete}
            />
        </div>
    );
};

export default PartnerPage;
