import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalConfirm = ({ show, handleClose, handleConfirm }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Ngăn chặn hành động mặc định
                if (show) {
                    handleConfirm(); // Gọi hàm xác nhận khi nhấn Enter
                }
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown); // Gỡ bỏ sự kiện khi modal đóng
        };
    }, [show, handleConfirm]); // Thêm các phụ thuộc cần thiết
    const handleConfirmAction = async () => {
        setIsProcessing(true); // Disable nút khi xử lý bắt đầu
        try {
            await handleConfirm(); // Gọi hàm xác nhận từ props
        } catch (error) {
            console.error("Lỗi khi xác nhận:", error);
        } finally {
            setIsProcessing(false); // Mở lại nút sau khi xử lý xong
            handleClose(); // Đóng modal
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faTrashAlt} />&nbsp;Xác Nhận Xóa</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-center'>Bạn có chắc chắn muốn xóa không?</Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Hủy
                </Button>
                <Button variant="danger" onClick={handleConfirmAction} disabled={isProcessing}>
                    <FontAwesomeIcon icon={faTrashAlt} /> {isProcessing ? "Đang xử lý..." : "Xác Nhận"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirm;
