// frontend/src/pages/Supplies.js
import React, { useEffect, useState, useCallback, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInbox, faBoxOpen, faFileInvoice, faPrescription } from '@fortawesome/free-solid-svg-icons';
import { Table, Button, FormControl } from 'react-bootstrap';
import Loading from '../components/Loading';
import ModalConfirm from '../components/ModalConfirm';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RoleContext } from '../components/RoleContext';
import AddMedicine from '../components/AddMedicine';
import CreateMedicineReceipt from '../components/CreateMedicineReceipt';

const Medicines = () => {
    const { currentRole } = useContext(RoleContext);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [medicines, setMedicines] = useState([]); // Danh sách thuốc
    const [editData, setEditData] = useState(null); // Dữ liệu để sửa
    const [formConfig, setFormConfig] = useState([]); // Cấu hình form tùy chỉnh
    const [showModal, setShowModal] = useState(false); // Modal thêm thuốc
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Trạng thái hiển thị Modal xác nhận xóa
    const [selectedMedicineId, setSelectedMedicineId] = useState(null); // Vật tư cần xóa
    const [showModalCreateMedicineReceipt, setShowModalCreateMedicineReceipt] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("token");
    const userId = jwtDecode(token)?.userId;
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });
    // Hàm lấy permissions
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.get(`${apiUrl}/employees/permissions`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { currentRole, userId },
                });
                setPermissions(response.data);
            } catch (error) {
                console.error("Lỗi khi lấy quyền:", error);
            }
        };

        fetchPermissions();
    }, [currentRole, apiUrl, token, userId]);
    // Lấy cấu hình form
    useEffect(() => {
        const fetchFormConfig = async () => {
            try {
                const response = await axios.get(`${apiUrl}/medicine-form-config/${currentRole}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setFormConfig(response.data);
            } catch (error) {
                console.error("Lỗi khi tải cấu hình form:", error);
                toast.error("Không thể tải cấu hình form!");
            }
        };

        fetchFormConfig();
    }, [apiUrl, token, userId, currentRole]);

    // Lấy danh sách thuốc
    const fetchMedicines = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/medicines`, {
                params: { currentRole, createdBy: userId },
                headers: { Authorization: `Bearer ${token}` },
            });
            setMedicines(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi tải danh sách thuốc:", error);
            toast.error("Không thể tải danh sách thuốc!");
        }
    }, [apiUrl, token, userId]);
    useEffect(() => {
        if (currentRole) {
            fetchMedicines();
        }
    }, [fetchMedicines, currentRole]);

    // Hiển thị Modal thêm mới hoặc sửa
    const handleShowModal = (data = null) => {
        setEditData(data); // Nếu `data` có giá trị, chuyển sang chế độ sửa
        setShowModal(true);
    };
    const openCreateMedicineReceipt = () => {
        setShowModalCreateMedicineReceipt(true);
    }
    const closeCreateMedicineReceipt = () => {
        setShowModalCreateMedicineReceipt(false);
    }
    // Đóng Modal
    const handleCloseModal = () => {
        setEditData(null); // Reset dữ liệu sửa
        setShowModal(false);
        fetchMedicines(); // Làm mới danh sách sau khi thêm/sửa
    };
    const handleDelete = async () => {
        try {
            await axios.delete(`${apiUrl}/medicines/${selectedMedicineId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success("Xóa thuốc thành công!");
            setShowConfirmModal(false);
            // Gọi lại API để cập nhật danh sách
            fetchMedicines();
        } catch (error) {
            console.error("Lỗi khi xóa thuốc:", error);
            toast.error("Không thể xóa thuốc!");
        }
    };

    //Mở modal xóa
    const confirmDelete = (id) => {
        setSelectedMedicineId(id);
        setShowConfirmModal(true);
    };

    return (
        <div className='m-2'>
            <h2><FontAwesomeIcon icon={faBoxOpen} /> Danh Sách Thuốc</h2>
            <div className='d-flex mb-3'>
                <Button variant='primary' className='m-1' title='Thêm Thuốc mới' onClick={() => handleShowModal()}>
                    <FontAwesomeIcon icon={faPlus} /> Thêm Thuốc
                </Button>
                <Button variant="primary" title='Nhập thuốc' className="m-1" onClick={() => openCreateMedicineReceipt()}>
                    <FontAwesomeIcon icon={faInbox} /> Nhập Thuốc
                </Button>
                <Button variant="primary" title='Danh sách phiếu nhập Thuốc' className='m-1' as={Link} to='/medicinereceipts'>
                    <FontAwesomeIcon icon={faFileInvoice} /> Phiếu Nhập
                </Button>
                <Button variant="success" title='Sử dụng Thuốc' className='m-1' as={Link} to='/prescription'>
                    <FontAwesomeIcon icon={faPrescription} /> Kê toa
                </Button>
                <FormControl
                    type='text'
                    placeholder='Tìm kiếm thuốc...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='ms-3'
                    style={{ flexGrow: 1 }}
                />
            </div>
            {loading ? (<Loading />) : (
                medicines.length > 0 ? (
                    <Table striped bordered hover responsive>
                        <thead className='text-center'>
                            <tr>
                                <th>{medicines.length}</th>
                                <th>Mã Thuốc</th>
                                {formConfig.map((field) => (
                                    <th key={field.fieldName}>{field.fieldLabel}</th>
                                ))}
                                <th>Hành Động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {medicines.map((medicine, index) => (
                                <tr key={medicine._id}>
                                    <td>{index + 1}</td>
                                    <td>{medicine.medicineCode.slice(0, 6)}</td>
                                    {formConfig.map((field) => (
                                        <td key={field.fieldName}
                                            style={
                                                field.fieldName === "tenThuoc"
                                                    ? { fontWeight: "bold" } // In đậm trường họ tên
                                                    : {}
                                            }>
                                            {field.fieldName === "giaBan" || field.fieldName === "giaNhap"
                                                ? new Intl.NumberFormat("vi-VN", {
                                                    style: "currency",
                                                    currency: "VND",
                                                }).format(medicine.customFields?.[field.fieldName] || 0)
                                                : medicine.customFields?.[field.fieldName] || ""}
                                        </td>
                                    ))}
                                    <td className='text-center'>
                                        <Button variant="warning" size="sm" className="me-2" onClick={() => handleShowModal(medicine)}>
                                            Sửa
                                        </Button>
                                        <Button variant="danger" size="sm" onClick={() => confirmDelete(medicine._id)}>
                                            Xóa
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>) : (
                    <p className='text-center'>
                        Chưa có thuốc để hiển thị!
                    </p>
                )
            )}
            <CreateMedicineReceipt
                show={showModalCreateMedicineReceipt}
                currentRole={currentRole}
                permissions={permissions}
                handleClose={closeCreateMedicineReceipt}
                fetchMedicines={fetchMedicines}
            />
            <AddMedicine
                show={showModal}
                currentRole={currentRole}
                permissions={permissions}
                handleClose={handleCloseModal}
                fetchMedicines={fetchMedicines}
                editData={editData} // Gửi dữ liệu để sửa (nếu có)
            />
            <ModalConfirm
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleDelete}
            />
        </div>
    );
};

export default Medicines;
