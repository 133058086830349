import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../src/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagnoses } from '@fortawesome/free-solid-svg-icons';

const categories = {
    "Sinh khí": ["67", "76", "14", "41", "39", "93", "28", "82"],
    "Ngũ quỷ": ["79", "97", "18", "81", "36", "63", "24", "42"],
    "Họa hại": ["23", "32", "46", "64", "17", "71", "89", "98"],
    "Diên niên": ["34", "43", "78", "87", "19", "91", "26", "62"],
    "Tuyệt mệnh": ["37", "73", "12", "21", "69", "96", "48", "84"],
    "Lục sát": ["38", "83", "47", "74", "16", "61", "29", "92"],
    "Thiên y": ["13", "31", "49", "94", "68", "86", "27", "72"],
    "Phục vị": ["11", "22", "33", "44", "55", "66", "77", "88", "99"]
};

const BCLSAnalyzer = () => {
    const [inputNumber, setInputNumber] = useState('');
    const [result, setResult] = useState('');
    const [analysis, setAnalysis] = useState('');
    const [loading, setLoading] = useState(false);
    const canvasRef = useRef(null);
    const yposRef = useRef([]);
    const matchedRef = useRef([]);

    useEffect(() => {
        if (loading) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const w = canvas.width = canvas.offsetWidth;
            const h = canvas.height = canvas.offsetHeight;

            const columns = Math.floor(w / 20);
            yposRef.current = Array(columns).fill(0);
            matchedRef.current = Array(columns).fill(false);

            const resultChars = result.replace(/<[^>]*>/g, '').split('');
            let matchedIndex = 0;

            const matrix = () => {
                ctx.fillStyle = '#0001';
                ctx.fillRect(0, 0, w, h);

                ctx.fillStyle = '#0f0';
                ctx.font = '1.5em monospace';

                yposRef.current.forEach((y, ind) => {
                    if (matchedRef.current[ind]) return;

                    const text = String.fromCharCode(33 + Math.random() * 94);
                    const x = ind * 20;
                    ctx.fillText(text, x, y);

                    if (resultChars[matchedIndex] && text === resultChars[matchedIndex]) {
                        ctx.fillStyle = '#fff';
                        ctx.fillText(text, x, y);
                        ctx.fillStyle = '#0f0';
                        matchedRef.current[ind] = true;
                        matchedIndex++;
                    }

                    if (y > h && Math.random() > 0.975) yposRef.current[ind] = 0;
                    else yposRef.current[ind] = y + 20;
                });
            };

            const interval = setInterval(matrix, 50);

            setTimeout(() => {
                clearInterval(interval);
                ctx.clearRect(0, 0, w, h);
                setLoading(false);
            }, 7000);

            return () => {
                clearInterval(interval);
                ctx.clearRect(0, 0, w, h);
            };
        }
    }, [loading, result]);

    const splitBCLSNumber = (input) => {
        const initial = input.replace(/^0/, '');

        let cleanNumber = '';
        let markers = [];

        for (let i = 0; i < initial.length; i++) {
            if (initial[i] === '0' || initial[i] === '5') {
                markers.push({ index: cleanNumber.length, value: initial[i] });
            } else {
                cleanNumber += initial[i];
            }
        }

        let result = '';
        let pairs = [];

        for (let i = 0; i < cleanNumber.length - 1; i++) {
            const pair = cleanNumber[i] + cleanNumber[i + 1];
            pairs.push(pair);

            const markerBefore = markers.find((m) => m.index === i);
            const markerAfter = markers.find((m) => m.index === i + 1);

            if ((markerAfter && markerAfter.value === '0') || (markerBefore && markerBefore.value === '0')) {
                result += `<span class="red">${pair}</span> `;
            } else if (markerAfter && markerAfter.value === '5') {
                result += `<span class="green">${pair}</span> `;
            } else {
                result += `${pair} `;
            }
        }

        return { result: result.trim(), pairs };
    };

    const analyzePairs = (pairs) => {
        const analysis = {};
        for (const category in categories) {
            analysis[category] = pairs.filter((pair) => categories[category].includes(pair));
        }
        return analysis;
    };

    const renderAnalysis = (analysis) => {
        return Object.entries(analysis)
            .filter(([, pairs]) => pairs.length > 0)
            .map(([category, pairs]) => (
                <div key={category}>
                    <strong>{category}:</strong> {pairs.join(', ')}
                </div>
            ));
    };

    const handleAnalyze = () => {
        if (!/^[0-9]+$/.test(inputNumber)) {
            setResult(<span className="text-danger">Vui lòng nhập số hợp lệ!</span>);
            setAnalysis('');
            return;
        }

        setLoading(true);

        setTimeout(() => {
            const { result, pairs } = splitBCLSNumber(inputNumber);
            const analysis = analyzePairs(pairs);

            setResult(result);
            setAnalysis(renderAnalysis(analysis));
        }, 7000);
    };

    return (
        <div className="bg-dark text-light min-vh-100 d-flex flex-column align-items-center">
            <div className="container py-5">
                <div className="card shadow-sm p-4 bg-secondary text-light">
                    <div className="mb-3">
                        <label htmlFor="inputNumber" className="form-label">
                            Nhập số:
                        </label>
                        <input
                            type="text"
                            id="inputNumber"
                            className="form-control bg-dark text-light border-secondary"
                            placeholder="Nhập số điện thoại..."
                            value={inputNumber}
                            onChange={(e) => setInputNumber(e.target.value)}
                        />
                    </div>
                    <div className="text-center">
                        <button onClick={handleAnalyze} className="btn btn-primary" disabled={loading && inputNumber.length === 0}>
                            <FontAwesomeIcon icon={faDiagnoses} /> {loading ? 'Đang Phân Tích...' : 'Phân Tích'}
                        </button>
                    </div>
                    <div className="mt-4">
                        <h4>Chuỗi số:</h4>
                        <div className="border p-3 bg-dark text-light text-center" style={{ height: '200px', position: 'relative', fontSize: '2em' }}><br />
                            {loading && <canvas ref={canvasRef} className="w-100 h-100" style={{ position: 'absolute', top: 0, left: 0 }}></canvas>}
                            {!loading && <div dangerouslySetInnerHTML={{ __html: result }} />}
                        </div>
                    </div>
                    <div className="mt-4">
                        <h4>Phân tích:</h4>
                        <div className="border p-3 bg-dark text-light">{analysis}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BCLSAnalyzer;
