import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faLightbulb, faNotesMedical, faSave, faUser } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

const AddImageModal = ({ show, handleClose, fetchAlbums, currentRole }) => {
    const [albumName, setAlbumName] = useState('');
    const [customerId, setCustomerId] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [notes, setNotes] = useState('');
    const [file, setFile] = useState(null);
    const [showAlert, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageData, setImageData] = useState([]); // Danh sách ban đầu rỗng
    const initialImageData = [];
    const initialNotes = "";
    const initialCustomerId = null;
    const initialAlbumName = "";

    const apiUrl = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem('token');
    let userId;
    if (token && typeof token === 'string') {
        try {
            const decoded = jwtDecode(token);
            userId = decoded.userId;
        } catch (error) {
            console.error('Token không hợp lệ:', error);
        }
    } else {
        console.warn('Token không hợp lệ hoặc không tồn tại.');
    }
    const resetForm = () => {
        setAlbumName(initialAlbumName);
        setCustomerId(initialCustomerId);
        setImageData(initialImageData);
        setNotes(initialNotes);
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/customers`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        currentRole,    // Chủ sở hữu dữ liệu
                        createdBy: userId,
                        format: "simple", // Người thực hiện hành động
                    },
                });
                const customerOptions = response.data.map((item) => ({
                    value: item.value,
                    label: item.label,
                }));
                setCustomers(customerOptions);
            } catch (error) {
                console.error("Lỗi khi lấy danh sách khách hàng:", error);
            }
        };
        fetchCustomers();
    }, [show, apiUrl, userId, currentRole]);

    const handleMultipleImageChange = async (files) => {
        if (!files || files.length === 0) return;
        if (files.length > 0) {
            setFile(files);
        }
        try {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 800,
                useWebWorker: true,
                fileType: "image/webp",
            };

            const fileArray = Array.from(files); // Chuyển FileList thành mảng

            const processedImages = await Promise.all(
                fileArray.map(async (file) => {
                    const compressedFile = await imageCompression(file, options);
                    return {
                        data: compressedFile,
                        contentType: "image/webp",
                        name: file.name,
                        uploadedAt: new Date(),
                        preview: URL.createObjectURL(compressedFile),
                    };
                })
            );

            // Thêm hình ảnh mới vào trạng thái
            setImageData((prev) => [...prev, ...processedImages]);
        } catch (error) {
            console.error("Lỗi khi xử lý hình ảnh:", error);
        }
    };

    const handleRemoveImage = (index) => {
        // Kiểm tra nếu `imageData` là một mảng hợp lệ
        if (!Array.isArray(imageData)) {
            console.error("imageData is not an array");
            return;
        }

        // Kiểm tra nếu `index` nằm trong giới hạn của mảng
        if (index < 0 || index >= imageData.length) {
            console.error("Index out of bounds:", index);
            return;
        }

        // Tạo một bản sao của `imageData` và xóa phần tử tại chỉ số `index`
        const updatedImageData = [...imageData];
        updatedImageData.splice(index, 1);

        setImageData(updatedImageData);
        setFile(updatedImageData);
    };



    const handleAddImages = async () => {
        const formData = new FormData();
        formData.append("albumName", albumName);
        formData.append("customerId", customerId);
        formData.append("createdBy", userId);
        formData.append("notes", notes);

        imageData.forEach((image) => {
            if (image.data) {
                formData.append("images", image.data);
            }
        });

        try {
            setLoading(true);
            const response = await axios.post(`${apiUrl}/albums`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Response from server:", response.data);
            handleClose();
            toast.success("Tạo Album thành công!");
            fetchAlbums();
            resetForm();
            setLoading(false);
        } catch (error) {
            console.error("Lỗi khi thêm album:", error);
            toast.error("Không thể tạo album.");
            setLoading(false);
        }
    };


    const handleCustomerChange = (option) => {
        setCustomerId(option ? option.value : null);
        setAlbumName(option ? `${option.label}` : '');
    };

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title> <FontAwesomeIcon icon={faImages} /> Thêm Album Mới {!showAlert && <FontAwesomeIcon className="text-warning text-right" style={{ cursor: 'pointer' }} icon={faLightbulb} onClick={() => setShow(true)} />}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    {showAlert && (
                        <Alert variant="warning" onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>Lưu ý:</Alert.Heading>
                            <i>
                                Bạn nên cắt chỉnh hình ảnh trước khi tải lên. Hình ảnh cùng kích thước,
                                cùng tư thế trước-sau, sẽ thuận tiện cho bạn so sánh và theo dõi về sau.
                            </i>
                        </Alert>
                    )}
                </>
                <Form>
                    <Form.Group controlId="formSelectCustomer" className="mb-3">
                        <Form.Label> <FontAwesomeIcon icon={faUser} /> Chọn Khách Hàng</Form.Label>
                        <Select
                            options={customers}
                            value={customers.find(customer => customer.value === customerId)}
                            onChange={handleCustomerChange}
                            placeholder="Tìm kiếm khách hàng..."
                            isClearable
                        />
                    </Form.Group>

                    <Form.Group controlId="formNotes">
                        <Form.Label><FontAwesomeIcon icon={faNotesMedical} /> Mô tả</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Mô tả về album ảnh..."
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            disabled={!customerId}
                        />
                    </Form.Group>
                    <div className='text-center'>
                        <Button
                            className='m-1'
                            variant="primary"
                            onClick={() => document.getElementById("select-images").click()}
                            disabled={!customerId}
                        >
                            Chọn hình ảnh
                        </Button>
                    </div>
                    <input
                        type="file"
                        id="select-images"
                        style={{ display: "none" }}
                        multiple
                        onChange={(e) => handleMultipleImageChange(e.target.files)}
                    />
                    <div className="row" style={{ display: file?.length === 0 || file?.length === undefined ? "none" : "" }}>
                        <div className="col-md-6 text-center">
                            <h5>Trước</h5>
                            {imageData.filter((_, i) => i % 2 === 0).map((img, index) => (
                                img.preview && ( // Kiểm tra img.preview trước khi hiển thị
                                    <div key={uuidv4()} className="mb-3">
                                        <img
                                            src={img.preview}
                                            alt={`Hình ảnh trước ${index}`}
                                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                                        />
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="mt-2"
                                            onClick={() => handleRemoveImage(index * 2)}
                                        >
                                            Xóa
                                        </Button>
                                    </div>
                                )
                            ))}
                        </div>
                        <div className="col-md-6 text-center">
                            <h5>Sau</h5>
                            {imageData.filter((_, i) => i % 2 !== 0).map((img, index) => (
                                img.preview && ( // Kiểm tra img.preview trước khi hiển thị
                                    <div key={uuidv4()} className="mb-3">
                                        <img
                                            src={img.preview}
                                            alt={`Hình ảnh sau ${index}`}
                                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                                        />
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="mt-2"
                                            onClick={() => handleRemoveImage(index * 2 + 1)}
                                        >
                                            Xóa
                                        </Button>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>

                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={handleClose}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={handleAddImages} disabled={loading || !customerId || !file?.length}>
                    <FontAwesomeIcon icon={faSave} /> {loading ? 'Đang lưu...' : 'Thêm Album'}
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default AddImageModal;
