import {
    faHome,
    faComments,
    faFileInvoiceDollar,
    faCalendarDay,
    faTasks,
    faImage,
    faHandHoldingHeart,
    faBoxOpen,
    faPills,
    faMoneyBill,
    faUser,
    faHandshake
} from '@fortawesome/free-solid-svg-icons';

const iconMap = {
    faHome: faHome,
    faComments: faComments,
    faFileInvoiceDollar: faFileInvoiceDollar,
    faCalendarDay: faCalendarDay,
    faTasks: faTasks,
    faImage: faImage,
    faHandHoldingHeart: faHandHoldingHeart,
    faBoxOpen: faBoxOpen,
    faPills: faPills,
    faMoneyBill: faMoneyBill,
    faUser: faUser,
    faHandshake: faHandshake
};

// Hàm lấy biểu tượng từ `iconMap`
export const getFontAwesomeIcon = (iconKey) => iconMap[iconKey] || null;
