// frontend/src/pages/VersionsPage.js
import React from 'react';
import { versionUpdates } from '../data/versionsData';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const VersionsPage = () => {
    return (
        <div className="m-4">
            <h2><FontAwesomeIcon icon={faCircleInfo} /> Các bản cập nhật</h2>
            <div className="timeline">
                {versionUpdates.map((version, index) => (
                    <div key={index} className="timeline-item">
                        <Card className="mb-4">
                            <Card.Header>
                                <strong>Phiên bản {version.version}</strong> - {new Date(version.date).toLocaleDateString('vi-VN')}
                            </Card.Header>
                            <Card.Body>
                                <ul>
                                    {version.changes.map((change, i) => (
                                        <li key={i}>{change}</li>
                                    ))}
                                </ul>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VersionsPage;
